import React from "react";
import "./Popup.scss";
import { postRefundCancellationReqApprv, postRefundCancellationReqRej } from "../../../../../service/grrs/accounting.service";

function CheckRefund({ selectedData, selectedBtnType, checkResponseCode, handleApproveResponse = () => { } }) {

    const handleClose = () => {
        document.getElementById("check-refund-modal-close-btn").click();
        if (document.getElementById("grrs-confirm-refund")) {
            document.getElementById("grrs-confirm-refund").disabled = false;
        }
    }

    const handleConfirm = () => {
        document.getElementById("grrs-confirm-refund").disabled = true;
        if (selectedBtnType === "approve") {
            postRefundCancellationReqApprv(selectedData).then(res => {
                checkResponseCode(res.response_code);
                handleApproveResponse(res);
                handleClose();
            })
        } else {
            postRefundCancellationReqRej(selectedData).then(res => {
                checkResponseCode(res.response_code);
                handleApproveResponse(res);
                handleClose();
            })
        }
    }

    return (
        <>
            <button type="button" id="check-refund-btn" className="btn btn-primary check-refund-modal-button" data-bs-toggle="modal" data-bs-target="#checkRefund" hidden>
                ตกลง
            </button>
            <div className="checkRefundModal">
                <div className="modal fade" id="checkRefund" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="checkRefundLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="check-refund-modal-close-btn" hidden></button>
                            </div>
                            <div className="modal-body">
                                {
                                    selectedData.length === 0 ? (
                                        <>
                                            <div className="row space-bottom">
                                                <div className="col-12 text-center">
                                                    <div className="text-warn">กรุณาเลือกข้อมูลที่ต้องการก่อนกดปุ่ม{selectedBtnType === "approve" ? "ยืนยันการคืนเงิน" : "แก้ไขเลขที่บัญชี"}</div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row space-bottom">
                                                <div className="col-12 text-center">
                                                    <div className="text-normal">ยืนยันการ{selectedBtnType === "approve" ? "คืนเงิน" : "แก้ไขเลขที่บัญชี"}ทั้ง {selectedData.length} รายการ</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <div className="row justify-content-center btn-section">
                                    <div className="col-12 text-center">
                                        {
                                            selectedData.length === 0 ? (
                                                <>
                                                    <button type="button" className="btn grrs-popup-close-btn grrs-primary-button" onClick={handleClose}>ปิด</button>
                                                </>
                                            ) : (
                                                <>
                                                    <button type="button" className="btn grrs-white-button check-refund-cancel-btn" onClick={handleClose}>ยกเลิก</button>
                                                    <button type="button" className="btn grrs-primary-button check-refund-confirm-btn" id="grrs-confirm-refund" onClick={handleConfirm}>ยืนยัน</button>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CheckRefund;