import React, { useState, useContext, useEffect } from 'react'
import Modal from '../components/UserManagement/Modal';
import "../../../scss/userMaintenance/_userManagementForm.scss";
import MenuContext from "../../../context/MenuContext";
import { useHistory, Link } from "react-router-dom";
import useInput from "../../etax/components/TaxInvoiceForm/useInput";
import { getRole_id } from "../../../service/userMaintenance/userManagement.service";

const UserManagementForm = () => {
    const { backSearch, profileUser, permission } = useContext(MenuContext);
    const [enterUsername, setEnterUsername] = useState("");
    const [operationName, setOperationName] = useState("");
    const [checked, setChecked] = useState([]);
    const [active, setActive] = useState("Y");
    const [isUsernameIsValid, setIsUsernameIsValid] = useState(true);
    const [isDisabledCheck, setIsDisabledCheck] = useState(false);
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        getRoleId();
    }, []);

    const getRoleId = async () => {
        try {
            const res = await getRole_id();
            setRoleList(res?.roles);
            setOperationName(profileUser?.email);
        } catch (err) {
            console.log(err);
        }
    }

    const pattern = /^[\w-\.]+@([\w-]+\.)+([\w-]{3}|([\w-]{2}\.[\w-]{2}))$/;

    const handleInput = (username) => {
        setEnterUsername(username)
        if (!pattern.test(username)) {
            setIsUsernameIsValid(false);
        } else {
            setIsUsernameIsValid(true);
        }
    }

    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        if (updatedList.length !== 0) {
            setIsDisabledCheck(true);
        } else setIsDisabledCheck(false);
        setChecked(updatedList);
    };

    const handleStatus = (e) => {
        setActive(e.target.value)
    }

    let history = useHistory();
    const backMainPage = (backPath) => {
        history.push(backPath);
    };

    return (
        <>
            <div className="container-md user-management">
                <div className="title-name">
                    <button onClick={() => backMainPage(backSearch)}>
                        <span>&#8592;</span>
                    </button>
                    <label>
                        Add New User
                    </label>
                </div>
                <div className='form-bg'>
                    <div className='username-input'>
                        <label>Email</label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => handleInput(e.target.value)}
                            value={enterUsername}
                            maxLength={50}
                        />
                        <br />
                        {!isUsernameIsValid && <div className="error-usermanagement">
                            กรุณากรอก Email ให้ถูกต้องตามรูปแบบ
                        </div>}
                    </div>
                    <br />
                    <div className='menu-usermanagement mt-2'>
                        <div>
                            <label>Menu</label>
                        </div>
                        <div className='role-label'>
                            <label className='black'>Role</label>
                        </div>
                        <div className='role-scroll-bar'>
                            {roleList.map((item, index) => (
                                item.active == "Y" ?
                                    <div className="form-check-user-management">
                                        <div key={index}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item.role_id}
                                                onChange={handleCheck}
                                            />
                                            <label className="form-check-label" >
                                                {item.role_name}
                                            </label>
                                        </div>
                                    </div> : ""
                            ))}
                        </div>
                    </div>
                    <br />
                    <div className='user-status'>
                        <div>
                            <label>Status</label>
                        </div>
                        <div>
                            <select
                                className="role-select"
                                onChange={(e) => handleStatus(e)}
                                value={active}
                            >
                                <option value="Y">Active</option>
                                <option value="N">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <div className="button-save-cancle">
                        <Link to="/user-maintenance/user-management">
                            <button className="button-cancle">ยกเลิก</button>
                        </Link>
                        <button
                            className={!enterUsername || !isUsernameIsValid || !isDisabledCheck || !permission.some((res) => res === "create") ? "button-save disable-button" : "button-save"}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop2"
                            disabled={!enterUsername || !isUsernameIsValid || !isDisabledCheck || !permission.some((res) => res === "create")}
                        >
                            บันทึก
                        </button>
                    </div>
                    <Modal
                        operationName={operationName}
                        enterUsername={enterUsername}
                        checked={checked}
                        active={active}
                    />
                </div>
            </div>
        </>
    )
}

export default UserManagementForm;