import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./PopupWaitingForCustomerEdit.scss";

function PopupWaitingForCustomerEdit({ waitingForCustomerEdit }) {
    let history = useHistory()

    const handleClose = () => {
        document.getElementById("waiting-for-customer-edit-modal-close-btn").click();
        history.push({ pathname: waitingForCustomerEdit?.backUrlPath })
    }

    useEffect(() => {
        if (waitingForCustomerEdit?.openModal === true) {
            document.getElementById("waiting-for-customer-edit-btn").click();
        }
    }, [waitingForCustomerEdit]);

    return (
        <>
            <button type="button" id="waiting-for-customer-edit-btn" className="btn btn-primary waiting-for-customer-edit-modal-button" data-bs-toggle="modal" data-bs-target="#waitingForCustomerEdit" hidden>
                ตกลง
            </button>
            <div className="waitingForCustomerEditPopupModal">
                <div className="modal fade" id="waitingForCustomerEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="waitingForCustomerEditLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="waiting-for-customer-edit-modal-close-btn" hidden></button>
                            </div>
                            <div className="modal-body">
                                {
                                    waitingForCustomerEdit?.result === true ? (
                                        <div className="row g-0">
                                            <div className="col-12 text-center">
                                                <div className="fw-bold waiting-text-body">ส่งเรื่องขอการแก้ไขข้อมูลจากลูกค้าสำเร็จ</div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="row g-0">
                                                <div className="col-12 text-center">
                                                    <div className="fw-bold waiting-text-body">ส่งเรื่องขอการแก้ไขข้อมูลจากลูกค้าไม่สำเร็จ</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <div className="row justify-content-center btn-section g-0">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn grrs-popup-close-btn grrs-primary-button" onClick={handleClose}>ปิด</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default PopupWaitingForCustomerEdit;