import { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import EcreditNoteDetailTable from '../common/ECreditNoteDetailTable';
import { getCreditNoteDetails } from '../../../../service/ecn/e-creditnote.service';
import { isEmpty } from "lodash";
import AlertComponent from "../../../common/components/Alert";
import MenuContext from "../../../../context/MenuContext";

const DetailEcreditNote = () => {
  const [data, setData] = useState([]);
  const [dataShow, setDataShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isAlertName, setIsAlertName] = useState("");
  const [responseCode, setResponseCode] = useState();
  const [isHandleAlertClick, setIsHandleAlertClick] = useState(false);
  const [isHandleAlertSaveClick, setIsHandleAlertSaveClick] = useState(false);
  const { permission } = useContext(MenuContext);
  const [isSearchPermission, setIsSearchPermission] = useState(true);
  const permisionCheck = () => {
    if (!permission.some((res) => res === "search")) {
      setIsSearchPermission(false);
      let modal = document.getElementById("modal_button");
      modal.click();
    };
  }

  const { ecnId } = useParams();
  const history = useHistory();

  const getResponseData = (res) => {
    if (res === undefined) {
      setResponseCode(res);
      setAlertHandle();
    } else {
      if (res.response_code === 100) {
        setIsAlertName("success-save");
        setResponseCode(res.response_code);
        setAlertHandle("success-save");
      } else {
        setIsAlertName("error-save");
        setResponseCode(res.response_code);
        setAlertHandle();
      }
    }
  }

  const redirectToEcn = () => {
    let path = `/ecn/คำขอคืนสินค้า`;
    history.push(path);
  }

  const checkEtaxPdf = () => {
    if (data.ref_etax_pdf === null) {
      setIsAlertName("etax-pdf-null");
      setResponseCode("");
      setAlertHandle();
    }
  }

  const checkEcnPdf = () => {
    if (data.ecn_pdf === null) {
      setIsAlertName("ecn-pdf-null");
      setResponseCode("");
      setAlertHandle();
    }
  }

  const setAlertHandle = (name) => {
    if (name === "success-save") {
      setTimeout(() => {
        setIsHandleAlertSaveClick(true);
        redirectToEcn();
      }, 5000);
      setIsHandleAlertSaveClick(false);
    } else {
      setTimeout(() => {
        setIsHandleAlertClick(true);
      }, 5000);
      setIsHandleAlertClick(false);
    }
  }

  const handleAlertClick = () => {
    setIsHandleAlertClick(true);
  }

  useEffect(() => {
    permisionCheck();
    getCreditNoteDetails(ecnId).then(res => {
      if (!isEmpty(res)) {
        setData(res);
        setDataShow(true)
        setModalShow(false)
      } else {
        if (modalShow == false) {
          setModalShow(true)
          setDataShow(false)
          let modal = document.getElementById("modal_button");
          modal.click();
        }
      }
    });
  }, []);

  const back = () => {
    let backdrop = document.getElementsByClassName("modal-backdrop fade show");
    Array.from(backdrop).forEach(function (element) {
      element.remove();
    });
    history.push("/ecn/คำขอคืนสินค้า");
  }
  const ecn_status = data.status;
  const ref_etax_no = data.ref_etax_no;

  return (
    <>
      <div>
        {dataShow &&
          (
            <div className="container">
              <AlertComponent
                alertName={isAlertName}
                responseCode={responseCode}
                isHandleAlertClick={isHandleAlertClick}
                handleAlertClick={handleAlertClick}
                isHandleAlertSaveClick={isHandleAlertSaveClick}
              />
              <div className="ecn-detail">
                <div className="row">
                  <div className="row detail-header">
                    <div className="col-12">
                      <h1 className="detail-header-text">รายละเอียดคำขอคืนสินค้า
                        <span className={data.status === "อยู่ระหว่างดำเนินการ" || data.status === "อนุมัติแล้ว" ? "" : "text-success"}> ({ecn_status})</span>
                      </h1>
                    </div>
                    <div className="col-12 col-md-6">
                      <h5 className="detail-eCreditNote">ใบกำกับภาษี
                        <a href={data.ref_etax_pdf} className="ecn-detail-link" onClick={checkEtaxPdf} target="_blank">{ref_etax_no}
                          <img src={process.env.PUBLIC_URL + "/assets/icons/icon-external-window.svg"} />
                        </a>
                      </h5>
                    </div>
                    <div className="col-12 col-md-6" hidden={data.status === "อยู่ระหว่างดำเนินการ" || data.status === "อนุมัติแล้ว" ? true : false}>
                      <h5 className="detail-eCreditNote">เลขที่ใบลดหนี้
                        <a href={data.ecn_pdf} className="ecn-detail-link" onClick={checkEcnPdf} target="_blank">{data.ecn_no}
                          <img src={process.env.PUBLIC_URL + "/assets/icons/icon-external-window.svg"} />
                        </a>
                      </h5>
                    </div>
                  </div>
                  <div className="row detail-body-text">
                    <h2>รายละเอียด</h2>
                  </div>
                  <div className="row detail-body">
                    <EcreditNoteDetailTable getResponseData={getResponseData} permission={permission} />
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
      <button
        id="modal_button"
        className="disable"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      <div className={!isSearchPermission || !dataShow ? "" : "disable"}>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="header">
                <h4 className="title" id="staticBackdropLabel">
                  เกิดข้อผิดพลาด
                </h4>
              </div>
              <div className="body">
                <h5>
                  {!isSearchPermission ? "คุณไม่มีสิทธิ์เข้าดูรายละเอียด" : (!dataShow ? "ไม่พบข้อมูลในระบบ" : "เกิดข้อผิดพลาด")}
                </h5>
              </div>
              <div className="footer">
                <button
                  type="button"
                  className="backbutton"
                  data-bs-dismiss="modal"
                  onClick={back}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailEcreditNote;