import React from "react";
import "./FormRequestCancel.scss";
import { useState } from "react";
import { useEffect } from "react";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
function Reason({
  isCanEdit = false,
  cancellationRefundMaster = { reason_list: [] },
  reason,
  setReason,
  setIsReasonValid,
}) {
  const [isDisplay, setIsDisplay] = useState(true);
  const validateReason = (reason) => {
    if (reason.reason_code == -1) return false;
    if (reason.reason_remark === "") return false;
    if (reason.cc_remark === "") return false;
    return true;
  };
  const {
    reason_code,
    reason_remark,
    is_all_member_point_compensation,
    cc_remark,
  } = reason;

  // useEffect(() => {
  //   setIsReasonValid(validateReason(reason));
  // }, [reason]);

  return (
    <div className="form-request-cancel">
      <div
        className={`header-request-cancel ${isCanEdit
            ? `header-request-cancel-edit ${isDisplay}`
            : "header-request-cancel-view"
          }`}
      >
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">สาเหตุ</span>
      </div>
      {isDisplay && (
        <div
          className={`body-request-cancel ${isCanEdit ? "body-request-cancel-edit" : ""
            }`}
        >
          <div className="row">
            <div
              className={`${isCanEdit ? "sub-header-edit" : "sub-header-view"}`}
            >
              สาเหตุการยกเลิกจากลูกค้า
            </div>
            <div className="col-md-6">
              <label
                className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
              >
                สาเหตุ
              </label>{" "}
              <select
                className="form-select"
                disabled={!isCanEdit}
                value={reason_code}
                onChange={(e) =>
                  setReason({ ...reason, reason_code: e.target.value })
                }
              >
                <option value={-1}>กรุณาเลือก</option>
                {cancellationRefundMaster.reason_list.map((reason) => (
                  <option value={reason.reason_code}>
                    {reason.reason_text}
                  </option>
                ))}
              </select>
              <div
                className={`mt-2 ${isCanEdit ? "sub-header-edit" : "sub-header-view"
                  }`}
              >
                หมายเหตุทีม Contact Center
              </div>
              <input
                className="form-check-input form-check-input-check"
                type="checkbox"
                disabled={!isCanEdit}
                value={reason.is_all_member_point_compensation}
                checked={reason.is_all_member_point_compensation === 1 ? true : false}
                onChange={(e) => {
                  setReason({
                    ...reason,
                    is_all_member_point_compensation:
                      +!reason.is_all_member_point_compensation,
                  });
                }}
              />
              <label className={`form-check-label ${isCanEdit ? "white" : ""}`}>
                ชดเชย ALL Point
              </label>
            </div>
            <div className="col-md-6">
              <label
                className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
              >
                รายละเอียดจากลูกค้า
              </label>
              <input
                className="form-control"
                disabled={!isCanEdit}
                value={reason_remark}
                onChange={(e) => {
                  setReason({ ...reason, reason_remark: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12/">
              <label
                className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
              >
                รายละเอียดเพิ่มเติม Contact Center
              </label>
              <textarea
                className="form-control"
                rows={2}
                disabled={!isCanEdit}
                value={cc_remark}
                onChange={(e) => {
                  setReason({ ...reason, cc_remark: e.target.value });
                }}
                placeholder="สามารถใส่ Note เพิ่มเติมเพื่อให้ทีม CS ทราบได้ว่าลูกค้าโวยวาย หรือให้มีการชดเชยพิเศษ"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Reason;
