import React, { useState, useEffect } from 'react';
import { paginate } from '../../../utils/paginate';
import Pagination from '../../common/components/Pagination';
import TableDataPreview from './common/TableDataPreview';

const PreviewData = () => {
    const [hasError, setError] = useState(false);
    const [users, setUsers] = useState([])
    const [startDate, setStartDate] = useState(null);
    const [customerNum, setCustomerNum] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    async function fetchData() {
        const res = await fetch('https://6182523f84c2020017d89dcf.mockapi.io/customercare/users');
        res
            .json()
            .then(res => setUsers(res))
            .catch(err => setError(err));
    }

    useEffect(() => {
        fetchData();
    }, [])

    function searchPhoneNum(nums) {
        return nums.filter((num) => num.billNum.includes(customerNum) || num.phoneNum_receive.includes(customerNum));
    }

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    const paginatedUsers = paginate(users, currentPage, pageSize);

    return (
        <>
            <TableDataPreview users={paginatedUsers, searchPhoneNum(paginatedUsers)} />
            <Pagination
                itemsCount={users.length}
                pageSize={pageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onPageChange={handlePageChange}
            />
        </>
    );
}

export default PreviewData;









