import React, { useEffect } from "react";
import "./RetryVoidModal.scss";

function RetryVoidModal({
  showRetryVoidModal,
  retryTimesState,
  handleRetryClick = () => {},
}) {

  const showRetryModal = () => {
    const modalElement = document.getElementById("retryVoidModal");
    if (modalElement) {
      const modal = new window.bootstrap.Modal(modalElement);
      modal.show();
    }
  }

  useEffect(() => {
    if (showRetryVoidModal === true) {
      showRetryModal();
    }
  }, [showRetryVoidModal]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary retry-void-modal-button"
        id="retry-void-modal-button"
        data-bs-toggle="modal"
        data-bs-target="#retryVoidMethod"
        hidden
      >
        ตกลง
      </button>
      <div className="retryVoidModal">
        <div
          className="modal fade"
          id="retryVoidModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="retryVoidMethodLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
              <div className="row">
              <h1 className="modal-title centered-text" id="retryVoidMethodLabel" >
                <div className="centered-text-content text-bold">
                  ระบบไม่สามารถทำการ Void ได้
                </div>
              </h1>
              <h3 className="modal-title-little">
                <div className="centered-text-content">
                 กรุณาลองใหม่อีกครั้ง  
                </div>
              </h3>
              <h3 className="modal-title-little">
              <br />
                <div className="centered-text-content text-second">
                  {retryTimesState ? `${retryTimesState}/3 ครั้ง` : '0/3 ครั้ง'}
                </div>
              </h3>
               
              <div className="column">
                <div className="icon">
                { retryTimesState >= 1 ?
                  <img src="/assets/icons/icons-checklist.svg" alt="back" /> :  
                  <img src="/assets/icons/icons-deselect.svg" alt="back" /> }
                </div>

                <div className="icon">
                { retryTimesState >= 2 ?
                  <img src="/assets/icons/icons-checklist.svg" alt="back" /> :  
                  <img src="/assets/icons/icons-deselect.svg" alt="back" /> }
                </div>

                <div className="icon">
                { retryTimesState >= 3 ?
                  <img src="/assets/icons/icons-checklist.svg" alt="back" /> :  
                  <img src="/assets/icons/icons-deselect.svg" alt="back" /> }
                </div>
              </div>
                
                <div className="column btn-section">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn grrs-white-button retry-void-try-btn text-bold"
                      data-bs-dismiss="modal"
                      onClick={handleRetryClick}
                    >
                      ลองอีกครั้ง
                    </button>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RetryVoidModal;
