import React from "react";

const CancalModal = ({ reset }) => {
  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="header">
              <h4 className="title" id="staticBackdropLabel">
                ยกเลิกการแก้ไข
              </h4>
            </div>
            <div className="body">
              <h5>คุณต้องการยกเลิกการแก้ไขข้อมูลทั้งหมด หรือไม่</h5>
            </div>
            <div className="footer">
              <button
                type="reset"
                className="resetbutton"
                data-bs-dismiss="modal"
                onClick={reset}
              >
                ยืนยัน
              </button>
              <button
                type="button"
                className="backbutton"
                data-bs-dismiss="modal"
              >
                ย้อนกลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancalModal;
