import { createContext, useState } from "react";

const DataContext = createContext({});
export const DataContextProvider = ({ children }) => {
  const [dataCustomer, setDataCustomer] = useState({});
  const [orderSourceById, setOrderSourceById] = useState("");
  const [external_ordernoById, setExternal_ordernoById] = useState("");
  const [orderHistory, setOrderHistory] = useState({});
  const [ordersource, setOrdersource] = useState("");
  const [displayOtp, setDisplayOtp] = useState(false);

  const storeData = {
    dataCustomer,
    setDataCustomer,
    orderSourceById,
    setOrderSourceById,
    external_ordernoById,
    setExternal_ordernoById,
    orderHistory,
    setOrderHistory,
    displayOtp,
    setDisplayOtp,
    ordersource,
    setOrdersource
  };

  return (
    <DataContext.Provider value={storeData}>{children}</DataContext.Provider>
  );
};
export default DataContext;
