import React, { useState, useEffect, useContext } from "react";
import { postMenuListData } from "../../../service/customer-care.service";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { getAccessIdData, logout } from "../../../service/auth.service";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import MenuContext from "../../../context/MenuContext";
import packageJson from "../../../../package.json";
import menuImg from "../../../utils/menuImages.json";

const Menu = () => {
  const [menulists, setMenulists] = useState([]);
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const { profileUser } = useContext(MenuContext);

  useEffect(() => {
    const accessIdData = getAccessIdData();
    setUserName(accessIdData.name);
    if (profileUser) {
      postMenuListData(profileUser?.name, profileUser?.email).then(function (result) {
        const menuLists = result?.response_data?.role?.map(role => role.menu_list);
        const flattenedMenuList = [].concat(...menuLists);
        const uniqueMenuList = flattenedMenuList.reduce((acc, current) => {
          const existing = acc.find(menu => menu.menu_id === current.menu_id);
          if (!existing) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        insertMenuImages(uniqueMenuList);
        const role = result?.response_data?.role.map(res => `${res.role_name}`).join(", ");
        setUserRole(role);
      });
    }
    
  }, [profileUser]);

  const redirectToExternalUrl = (externalUrl) => {
    window.location.replace(externalUrl);
  }

  const insertMenuImages = (uniqueMenuList) => {
    const imglist=[];
    menuImg.menuImg.forEach(function(key) {
      imglist[key.menu_id] = key.menu_img;
    });
    const updatedMenuList = [];
    uniqueMenuList.forEach(function(menu) {
      const menuWithImg = {
        ...menu, 
        menu_img: imglist[menu.menu_id] 
      };
      updatedMenuList.push(menuWithImg);
    });
    setMenulists(updatedMenuList)
  }
  return (
    <>
      <section id="menu-content">
        <div className="container">
          <div className="row" id="menu-navbar">
            <Image id="menu-header"
              src={
                process.env.PUBLIC_URL +
                "/assets/pictures/header-menu.png"
              }
              rounded
            />
            <div id="row-tag-version">
              <div id="tag-version">{"v" + packageJson.version}</div>
            </div>
            <Image id="user-icon"
              src={
                process.env.PUBLIC_URL +
                "/assets/icons/user-icon.svg"
              }
              rounded
            />

            <div id="menu-user-detail">
              <div>
                <div className="d-flex">
                  <h4 id="user-text">{userName}</h4>
                  <Dropdown as={ButtonGroup} id="menu-dropdown">
                    <Dropdown.Toggle split variant="default" id="menu-dropdown-tab" />
                    <Dropdown.Menu>
                      <Dropdown.Item id="dropdown-option" onClick={logout}>Log out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <h4 id="menu-small-text">{userRole}</h4>
              </div>

            </div>
          </div>
          <div className="row align-items-center" id="menu-body">

            <div className="row justify-content-start align-items-center my-auto mx-auto height-menu d-flex">
              {
                menulists?.map((list, index) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-4">
                      <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-5 mx-auto" id="card-shadow">
                        {
                          list.menu_link_type === "path" && (
                            <Link to={list.menu_link} className="link-to-module">
                              <div className="card text-center menu-card">
                                <div className="card-body">
                                  <Image className="card-img-top card-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      list.menu_img
                                    } />
                                  <h5 className="card-title text-truncate">{list.menu_name}</h5>
                                  <p className="card-text text-truncate">{list.menu_description}</p>
                                </div>
                              </div>
                            </Link>
                          )
                        }
                        {
                          list.menu_link_type === "url" && (
                            <div className="link-to-module" onClick={() => redirectToExternalUrl(list.menu_link)}>
                              <div className="card text-center menu-card">
                                <div className="card-body">
                                  <Image className="card-img-top card-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      list.menu_img
                                    } />
                                  <h5 className="card-title text-truncate">{list.menu_name}</h5>
                                  <p className="card-text text-truncate">{list.menu_description}</p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  )
                })}
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
export default Menu;