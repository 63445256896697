import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import CustomerInfo from "../../components/Share/FormRequestCancel/customerInfo";
import DetailCancel from "../../components/Share/FormRequestCancel/detailCancel";
import Reason from "../../components/Share/FormRequestCancel/reason";
import ChannelRefund from "../../components/Share/FormRequestCancel/channelRefund";
import SummaryRefundCancel from "../../components/Share/FormRequestCancel/summaryRefundCancel";
import TrackStatus from "../../components/Share/FormRequestCancel/trackStatus";
import TransactionHistory from "../../components/Share/FormRequestCancel/transactionHistory";
import ApproveOrderCancel from "../../components/CustomerService/FormRequestCancel/approveOrderCancel";
import "./Accounting.scss";
import { getOrderCancellationReqDetApprv, getCancellationRefundMaster } from "../../../../service/grrs/customer.service";
import { getOrderCancellationRequestTimeline } from "../../../../service/grrs/contactCenter.service";
import CheckRefund from "../../components/Accounting/FormRequestCancel/checkRefund.jsx";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import { getSummaryRefund } from "../../../../helper/grrs/redeemAndEarnPointHelper";
import LoadingSpinner from "../../components/Share/LoadingSpinner";

const initCancellationRefundMaster = {
  reason_list: [],

  refund_channel: [],

  bank_master: {
    bank_account: [],

    bank_account_type: [],
  },
  cancel_policy_link: "",
};

const initCancellationInitial = {
  tracking_timeline: [],
  orders: {
    is_can_update_refund_method: 1,
    cs_approve: null,
    total_price: 0,
    redeemedSummary: [],
    earnPoints: [],
    order_detail_list: [],
    invoice_detail_list: [],
    request_activity_log: [],
  },
};

const initReason = {
  reason_code: "",
  reason_remark: "",
  is_all_member_point_compensation: 0,
  cc_remark: "",
};

const initChannelRefund = {
  channel_code: "",
  bank_account_type_code: "",
  promtpay_no: "",
  bank_code: "",
  account_no: "",
  account_name: "",
};

const initApproveOrderCancel = {
  is_approve: 1,
  cs_reason_code: "",
  cs_remark: "",
  all_member_point_compensation: 0,
  is_status_urgent: 0,
};
const initTrackingData = {
  orders: {
    order_tracking_status: [],
    tracking_timeline: [],
  },
};

const initCheckRefund = {
  customer_request_no: "",
  is_approve: 2,
  remark: "",
};

function RequestCancel() {
  let { customerRequestNo, orderNo, ordersource } = useParams();
  customerRequestNo = parseInt(customerRequestNo);
  //orderNo = parseInt(orderNo); Comment because the new order has no characters.ex : A000005905
  const [isCashMode, setIsCashMode] = useState(false);
  const [isHavePoint, setIsHavePoint] = useState(false);
  const [trackingData, setTrackingData] = useState(initTrackingData);
  const [isLoading, setIsLoading] = useState(false);
  const [isHaveRefundApproved, setIsHaveRefundApproved] = useState();

  const [checkRefund, setCheckRefund] = useState(initCheckRefund);

  const [isCanEditChannelRefund, setIsCanEditChanngelRefund] =
    useState(false);
  const [channelRefundForm, setChannelRefundForm] = useState(initChannelRefund);

  const [cancellationRefundMaster, setCancellationRefundMaster] = useState(
    initCancellationRefundMaster
  );

  const [cancellationInitial, setCancellationInitial] = useState(
    initCancellationInitial
  );

  const [approveOrderCancel, setApproveOrderCancel] = useState(
    initApproveOrderCancel
  );

  const [reason, setReason] = useState(initReason);

  const [requestStatus, setRequestStatus] = useState("");

  const getOrderCancellationReqDetApprvFromApi = () => {
    getOrderCancellationReqDetApprv(customerRequestNo).then((response) => {
      if (response?.response_data?.orders !== undefined) {
        setCancellationInitial(response?.response_data);
        setReason(response?.response_data?.orders?.reason);
        setIsHavePoint(response?.response_data?.orders?.reason?.is_all_member_point_compensation === 1 ? true : false);
        setIsHaveRefundApproved(response?.response_data?.orders?.refund_approve);
        setChannelRefundForm(response?.response_data?.orders?.refund_method);
        setRequestStatus(response?.response_data?.orders?.request_refund_status_code);
        if (response?.response_data?.orders?.cs_approve !== null) {
          setApproveOrderCancel(response?.response_data?.orders?.cs_approve);
        }
        if (response?.response_data?.orders?.refund_approve !== null) {
          setCheckRefund({
            is_approve: response?.response_data?.orders?.refund_approve?.is_approve,
            remark: response?.response_data?.orders?.refund_approve?.remark,
          })
        }
      }
      setIsLoading(false);
    });
  };

  const getOrderCancellationRequestTimelineFromApi = () => {
    getOrderCancellationRequestTimeline(customerRequestNo).then((response) => {
      setTrackingData(response?.response_data);
    });
  };

  const getCancellationRefundMasterFromApi = () => {
    getCancellationRefundMaster(orderNo, ordersource).then((res) => {
      if (res?.response_data !== undefined) {
        setCancellationRefundMaster(res?.response_data);
        if (res?.response_data?.refund_channel.length === 1) {
          setIsCashMode(false);
        } else {
          setIsCashMode(true);
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderCancellationReqDetApprvFromApi();
    getCancellationRefundMasterFromApi();
    getOrderCancellationRequestTimelineFromApi();
  }, []);

  let history = useHistory();
  const goBack = () => {
    history.push("/grrs/accounting-return/create-file");
  };

  const {
    refundAllMemberPoint,
    refundNonAllMemberPoint,
    cancelAllMemberPoint,
    cancelNonAllMemberPoint,
  } = getSummaryRefund(cancellationInitial.orders);

  return (
    <Container>
      <div className="layout">
        <div className="row vh-100">
          <Row>
            <ResponsiveSideBar />
            <Col md={9} className="content pb-5">
              <LoadingSpinner isLoading={isLoading} />
              <div className="container accounting-request-cancel-container">
                <span>
                  <img
                    src="/assets/icons/backicon.svg"
                    alt="back"
                    className="back-icon"
                    onClick={goBack}
                  />
                </span>
                <span className="h1-header">
                  ทำการยกเลิกสินค้า #{orderNo}
                </span>
                {!isLoading &&
                  (
                    <>
                      <TrackStatus cancellationInitial={cancellationInitial} />
                      <CustomerInfo
                        cancellationInitial={cancellationInitial}
                        isHaveTrackingModal={true}
                        trackingData={trackingData}
                      />
                      <DetailCancel
                        cancellationInitial={cancellationInitial}
                      />
                      <Reason
                        reason={reason}
                        setReason={setReason}
                        isCanEdit={false}
                        cancellationRefundMaster={cancellationRefundMaster}
                      />
                      <ChannelRefund
                        cancellationInitial={cancellationInitial}
                        isCashMode={isCashMode}
                        cancellationRefundMaster={cancellationRefundMaster}
                        isCanEdit={isCanEditChannelRefund}
                        setIsCanEdit={setIsCanEditChanngelRefund}
                        channelRefundForm={channelRefundForm}
                        setChannelRefundForm={setChannelRefundForm}
                        IsCanChangeEditMode={false}
                      />
                      {
                        isCashMode &&
                        (requestStatus === "2" || requestStatus === "3" || requestStatus === "4") &&
                        isHaveRefundApproved &&
                        (
                          <CheckRefund
                            isCanEdit={false}
                            checkRefund={checkRefund}
                            setCheckRefund={setCheckRefund}
                            isHasApproveOption={false}
                            isHasWaitingOption={true}
                          />
                        )}
                      <SummaryRefundCancel
                        cancellationInitial={cancellationInitial}
                        refundAllMemberPoint={refundAllMemberPoint}
                        refundNonAllMemberPoint={refundNonAllMemberPoint}
                        cancelAllMemberPoint={cancelAllMemberPoint}
                        cancelNonAllMemberPoint={cancelNonAllMemberPoint}
                      />
                      {
                        requestStatus === "3" &&
                        isCashMode === true &&
                        isHaveRefundApproved === null && (
                          <ApproveOrderCancel
                            isCanEdit={false}
                            isFullForm={true}
                            isHasApproveOption={false}
                            isHasWaitingOption={true}
                            cancellationRefundMaster={cancellationRefundMaster}
                            approveOrderCancel={approveOrderCancel}
                            isCash={isCashMode}
                          />
                        )}
                      {
                        requestStatus === "3" &&
                        isCashMode === true &&
                        isHaveRefundApproved !== null && (
                          <ApproveOrderCancel
                            isCanEdit={false}
                            isFullForm={true}
                            isHasApproveOption={true}
                            isHasWaitingOption={false}
                            cancellationRefundMaster={cancellationRefundMaster}
                            approveOrderCancel={approveOrderCancel}
                            isCash={isCashMode}
                          />
                        )}
                      {
                        requestStatus !== "3" &&
                        requestStatus !== "5" &&
                        isCashMode === true && (
                          <ApproveOrderCancel
                            isCanEdit={false}
                            isFullForm={true}
                            isHasApproveOption={true}
                            isHasWaitingOption={false}
                            cancellationRefundMaster={cancellationRefundMaster}
                            approveOrderCancel={approveOrderCancel}
                            isCashMode={isCashMode}
                          />
                        )}
                      {
                        isCashMode === false && isHavePoint && (
                          <ApproveOrderCancel
                            isCanEdit={false}
                            isFullForm={true}
                            isHasApproveOption={true}
                            isHasWaitingOption={false}
                            cancellationRefundMaster={cancellationRefundMaster}
                            approveOrderCancel={approveOrderCancel}
                            isCashMode={isCashMode}
                          />
                        )}
                      <TransactionHistory
                        cancellationInitial={cancellationInitial}
                      />
                      <div className="text-center footer-section">
                        <button
                          className="btn accounting request-cancel grrs-primary-button"
                          onClick={goBack}
                        >
                          ย้อนกลับ
                        </button>
                      </div>
                    </>
                  )
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default RequestCancel;
