import React, { useState, useEffect } from "react";
import "./FormRequestCancel.scss";
import { numberWithCommas } from "../../../../../helper/commonHelper";

const removeNonNumericOnString = (str) => str.replaceAll(/[^\d]/g, "");
const SAVING_CODE = "BSA";
const CURRENT_CODE = "BCA";
const PROMPT_PAY_CODE = "PP";

function ChannelRefund({
  setHasBeenClick = () => {},
  cancellationInitial = {},
  isCashMode = true,
  isCanEdit,
  setIsCanEdit,
  channelRefundForm,
  setChannelRefundForm = () => {},
  cancellationRefundMaster,
  IsCanChangeEditMode = true,
}) {
  const { refund_channel } = cancellationRefundMaster;
  const { orders } = cancellationInitial;

  const {
    channel_code,
    bank_account_type_code,
    promtpay_no,
    bank_code,
    account_no,
    account_name,
  } = channelRefundForm;

  const [isDisplay, setIsDisplay] = useState(true);
  return (
    <div className="form-request-cancel">
      <div
        className={`header-request-cancel ${
          isCanEdit
            ? `header-request-cancel-edit ${isDisplay}`
            : "header-request-cancel-view"
        }`}
      >
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">ช่องทางคืนเงิน</span>
        {isCashMode === true && IsCanChangeEditMode && (
          <span
            className="float-right right-text cur"
            onClick={() => {
              setIsCanEdit(!isCanEdit);
              setHasBeenClick(true);
            }}
            role="button"
          >
            {!isCanEdit ? "แก้ไขเลขที่บัญชี" : "ยกเลิกแก้ไขเลขที่บัญชี"}
          </span>
        )}
      </div>
      {isDisplay && (
        <div
          className={`body-request-cancel ${
            isCanEdit ? "body-request-cancel-edit" : ""
          }`}
        >
          <div className="row">
            <p className={`fw-bold ${isCanEdit ? "form-label-edit" : ""}`}>
              ยอดโอนคืนราคาสินค้า {numberWithCommas(orders?.invoice_value)} บาท
            </p>
          </div>

          {isCashMode === true && channel_code === "" && (
            <div className="row">
              <div className="col-md-6">
                <label
                  className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
                >
                  ประเภทบัญชี
                </label>
                <select
                  id="channel-text"
                  className="form-select"
                  disabled={!isCanEdit}
                  value={channel_code}
                  onChange={(e) => {
                    setChannelRefundForm({
                      ...channelRefundForm,
                      channel_code: e.target.value,
                    });
                  }}
                >
                  <option value=""> กรุณาเลือก</option>
                  {refund_channel.map((channel) => (
                    <option value={channel.channel_code}>
                      {channel.channel_text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {isCashMode === true &&
            (channel_code === SAVING_CODE || channel_code === CURRENT_CODE) && (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <label
                      className={`form-label ${
                        isCanEdit ? "form-label-edit" : ""
                      }`}
                    >
                      ประเภทบัญชี
                    </label>
                    <select
                      id="channel-text"
                      className="form-select"
                      disabled={!isCanEdit}
                      value={channel_code}
                      onChange={(e) => {
                        setChannelRefundForm({
                          ...channelRefundForm,
                          channel_code: e.target.value,
                        });
                      }}
                    >
                      <option value={""}> กรุณาเลือก</option>
                      {refund_channel.map((channel) => (
                        <option value={channel.channel_code}>
                          {channel.channel_text}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-6">
                    <label
                      className={`form-label ${
                        isCanEdit ? "form-label-edit" : ""
                      }`}
                    >
                      ธนาคาร
                    </label>
                    <select
                      id="bank-text"
                      className="form-select"
                      disabled={!isCanEdit}
                      value={bank_code}
                      onChange={(e) =>
                        setChannelRefundForm({
                          ...channelRefundForm,
                          bank_code: e.target.value,
                        })
                      }
                    >
                      <option value={""}> กรุณาเลือก</option>
                      {cancellationRefundMaster?.bank_master?.bank_account.map(
                        (bank, index) => (
                          <option
                            id={`bank-text-${index}`}
                            value={bank.bank_code}
                          >
                            {bank.bank_text}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label
                      className={`form-label ${
                        isCanEdit ? "form-label-edit" : ""
                      }`}
                    >
                      เลขบัญชี
                    </label>
                    <input
                      className="form-control"
                      disabled={!isCanEdit}
                      value={account_no}
                      onChange={(e) =>
                        setChannelRefundForm({
                          ...channelRefundForm,
                          account_no: removeNonNumericOnString(e.target.value),
                        })
                      }
                    />
                  </div>

                  <div className="col-md-6">
                    <label
                      className={`form-label ${
                        isCanEdit ? "form-label-edit" : ""
                      }`}
                    >
                      ชื่อบัญชี
                    </label>
                    <input
                      className="form-control"
                      disabled={!isCanEdit}
                      value={account_name}
                      onChange={(e) =>
                        setChannelRefundForm({
                          ...channelRefundForm,
                          account_name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}

          {isCashMode === true && channel_code == PROMPT_PAY_CODE && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <label
                    className={`form-label ${
                      isCanEdit ? "form-label-edit" : ""
                    }`}
                  >
                    ประเภทบัญชี
                  </label>
                  <select
                    id="channel-text"
                    className="form-select"
                    disabled={!isCanEdit}
                    value={channel_code}
                    onChange={(e) => {
                      setChannelRefundForm({
                        ...channelRefundForm,
                        channel_code: e.target.value,
                      });
                    }}
                  >
                    <option value={""}> กรุณาเลือก</option>
                    {refund_channel.map((channel) => (
                      <option value={channel.channel_code}>
                        {channel.channel_text}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <label
                    className={`form-label ${
                      isCanEdit ? "form-label-edit" : ""
                    }`}
                  >
                    บัญชี
                  </label>
                  <select
                    className="form-select"
                    disabled={!isCanEdit}
                    value={bank_account_type_code}
                    onChange={(e) =>
                      setChannelRefundForm({
                        ...channelRefundForm,
                        bank_account_type_code: e.target.value,
                      })
                    }
                  >
                    <option value={""}> กรุณาเลือก</option>
                    {cancellationRefundMaster?.bank_master?.bank_account_type.map(
                      (bank) => (
                        <option value={bank.account_type_code}>
                          {bank.account_type_text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label
                    className={`form-label ${
                      isCanEdit ? "form-label-edit" : ""
                    }`}
                  >
                    {bank_account_type_code == 1
                      ? "เบอร์โทรศัพท์"
                      : "เลขบัตรประชาชน"}
                  </label>
                  <input
                    className="form-control"
                    disabled={!isCanEdit}
                    value={promtpay_no}
                    onChange={(e) =>
                      setChannelRefundForm({
                        ...channelRefundForm,
                        promtpay_no: removeNonNumericOnString(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}

          {isCashMode === false && (
            <div className="row">
              <div className="col-md-6">
                <label
                  className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
                >
                  ประเภทบัญชี
                </label>
                <select
                  id="channel-text"
                  className="form-select"
                  disabled={!isCanEdit}
                  value={channel_code}
                  onChange={(e) => {
                    setChannelRefundForm({
                      ...channelRefundForm,
                      channel_code: e.target.value,
                    });
                  }}
                >
                  {refund_channel.map((channel) => (
                    <option value={channel.channel_code}>
                      {channel.channel_text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ChannelRefund;
