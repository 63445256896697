import axios from "axios";
import { checkAccessToken } from "../../helper/grrs/grrsHelper";

const GRRS_URL = process.env.REACT_APP_GRRS_API_URL;
const GRRS_AUTH = () =>
  `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
const X_API_KEY = process.env.REACT_APP_GRRS_X_API_KEY;

const postVoidSuccessReport = async (searchData) => {
  const body = searchData;
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/report/auto_void/success`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data.response_data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const postVoidFailReport = async (searchData) => {
    const body = searchData;
    try {
      const response = await axios.post(
        `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/report/auto_void/fail`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: GRRS_AUTH(),
            "x-api-key": X_API_KEY,
          },
        }
      );
      if (response.data.response_code === 100) return response.data.response_data;
      else return [];
    } catch (error) {
      console.log(error);
      checkAccessToken(error?.response?.status);
      return [];
    }
};

const putUpdateVoidStatus = async (data) => {
    const body = data;
    try {
      const response = await axios.put(
        `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/report/auto_void/fail`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: GRRS_AUTH(),
            "x-api-key": X_API_KEY,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      checkAccessToken(error?.response?.status);
      return [];
    }
};

const exportVoidStatusReport = async (exportData) => {
  const body = exportData;
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/report/auto_void/export`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
        responseType: "blob",
      }
    );
    if (response.headers !== undefined) {
      return response;
    } else return null;
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return null;
  }
};

export {
    postVoidSuccessReport,
    postVoidFailReport,
    putUpdateVoidStatus,
    exportVoidStatusReport
}