import React, { useState, useContext, useEffect, useRef  } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import {
  postVerifyAndGetOrderHistory,
  getOrdersource,
  postSendSmsOtp,
} from "../../../../../service/grrs/contactCenter.service";
import "./OtpModal.scss";
import useInput from "../../../../etax/components/TaxInvoiceForm/useInput";
import DataContext from "../../../../../context/DataContext";
import CountdownOtp from "./CountdownOtp";
import "../../../../../scss/grrs/_general.scss";

function OtpModal(props) {
  const { displayOtp, setDisplayOtp } = useContext(DataContext);
  const [ordersourceList, setOrdersourceList] = useState([]);
  const [otpTxId, setOtpTxId] = useState("");
  const [refNo, setRefNo] = useState("");
  const [show, setShow] = useState(false);
  const [enableOtpInput, setEnableOtpInput] = useState(true);
  const [click, setClick] = useState(true);
  const [initialSeconds, setInitialSeconds] = useState();
  const [otpAlert, setOtpAlert] = useState(false);
  const [phoneAlert, setPhoneAlert] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const isMounted = useRef(true);

  let history = useHistory();
  let tel =
    /^0(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/g;
  let otpCheck = /(\d{6})/g;

  const {
    enterValue: enterOrdersource,
    setEnterValue: setEnterOrdersource,
    enterValueIsValid: enterOrdersourceIsValid,
    setEnterValueBlurIsValid: setEnterOrdersourceBlurIsValid,
    inputValueError: inputOrdersourceError,
    onValueBlurHandle: onOrdersourceBlurHandle,
    resetValue: resetOrdersource,
  } = useInput((value) => value.trim() !== "");

  const {
    enterValue: enterPhoneNo,
    enterValueIsValid: enterPhoneNoIsValid,
    inputValueError: inputPhoneNoError,
    onValueChangeHandle: onPhoneNoChangeHandle,
    onValueBlurHandle: onPhoneNoBlurHandle,
    resetValue: resetPhoneNo,
  } = useInput(
    (value) => value.trim() !== "" && tel.test(value) && value.length === 10
  );

  const {
    enterValue: enterOtp,
    enterValueIsValid: enterOtpIsValid,
    inputValueError: inputOtpError,
    onValueChangeHandle: onOtpChangeHandle,
    onValueBlurHandle: onOtpBlurHandle,
    resetValue: resetOtp,
  } = useInput(
    (value) => value.trim() !== "" && otpCheck.test(value) && value.length === 6
  );

  const otpIsValid = enterOrdersourceIsValid && enterPhoneNoIsValid;
  const formIsValid = enterOtpIsValid;
  const errorOrdersourceClassName = inputOrdersourceError
    ? "form-select alert-red"
    : "form-select";
  const errorPhoneNoClassName = inputPhoneNoError
    ? "form-control alert-red tel-input"
    : "form-control tel-input";
  const errorOtpClassName = inputOtpError
    ? "form-control alert-red otp-input"
    : "form-control otp-input";
  const alertOtpClassName = "form-control alert-red otp-input";
  const alertPhoneNoClassName = "form-control alert-red tel-input";

  const handleClose = () => {
    setShow(false);
    setOtpAlert(false);
    setEnableOtpInput(true);
    setClick(true);
    resetOrdersource();
    resetPhoneNo();
    resetOtp();
    setRefNo("");
  };

  const handleShow = () => setShow(true);

  const getOrdersourcefromapi = () => {
    getOrdersource().then((res) => {
      setOrdersourceList(res);
    });
  };

  const onChangeOrdersource = (e) => {
    setEnterOrdersource(e.target.value);
    localStorage.setItem("grrs-ordersource", JSON.stringify(e.target.value));
    setEnterOrdersourceBlurIsValid(true);
  };

  const handleClickOtp = (enterPhoneNo) => {
    setClick(false);
    setInitialSeconds(90);
    setEnableOtpInput(false);
    postSendSmsOtp(enterPhoneNo, JSON.parse(localStorage.getItem("grrs-ordersource"))).then((res) => {
      setOtpTxId(res.otp_tx_id);
      setRefNo(res.ref_no);
    });
  };

  const handleClickConfirm = (
    otpTxId,
    refNo,
    enterOtp,
    enterPhoneNo,
    enterOrdersource
  ) => {
    setIsButtonLoading(true);
    let request = {
      otp_tx_id: otpTxId,
      ref_no: refNo,
      otp_code: enterOtp,
      phone_no: enterPhoneNo,
      ordersource: enterOrdersource,
    };
    postVerifyAndGetOrderHistory(request).then((res) => {
      if (!isMounted.current) return; 
      if (res.response_code === 100) {
        localStorage.setItem("grrs-order", JSON.stringify(res.response_data));
        history.push("/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-list", "index");
      }
      if (res.response_code === 301) {
        setOtpAlert(true);
      }
      if (res.response_code === 401) {
        setPhoneAlert(true);
      }
      if (isMounted.current) {
        setIsButtonLoading(false);
      }
    });
  };

  function handleOtpModal() {
    const button = document.getElementById("otp-modal");
    button.click();
  }

  useEffect(() => {
    getOrdersourcefromapi();
    if (displayOtp === true) {
      handleOtpModal();
      setDisplayOtp(false);
    }
  }, [displayOtp]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (enterPhoneNo === "") {
      setPhoneAlert(false);
    }
  }, [enterPhoneNo]);

  useEffect(() => {
    if (enterOtp === "") {
      setOtpAlert(false);
    }
  }, [enterOtp]);

  return (
    <>
      <Button
        bsPrefix="otp-modal grrs-primary-button"
        id="otp-modal"
        onClick={handleShow}
      >
        สร้างคำขอแทนลูกค้า
      </Button>
      <Modal
        id="otp"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            ทำเรื่องขอยกเลิก เปลี่ยนสินค้า หรือ คืนเงิน{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="order-chanel-tel">
            <div className="col-2">
              <label>ช่องทางการสั่งซื้อ : *</label>
            </div>
            <div className="col-10">
            <select
              className={errorOrdersourceClassName}
              value={enterOrdersource} // ใช้ value จาก state
              onChange={(e) => onChangeOrdersource(e)}
              onBlur={onOrdersourceBlurHandle}
            >
            <option value="" disabled hidden>
              เลือก
            </option>
              {ordersourceList?.map((item, index) => {
                return (
            <option key={index} value={item.ordersource}>
                {item.ordersource_name}
            </option>
                );
              })}
            </select>
              {/* <select
                className={errorOrdersourceClassName}
                value={enterOrdersource}
                onChange={(e) => onChangeOrdersource(e)}
                onBlur={onOrdersourceBlurHandle}
              >
                <option key="0" value="" disabled selected hidden>
                  เลือก
                </option>
                {ordersourceList?.map((item, index) => {
                  return (
                    <option key={index} value={item.ordersource}>
                      {item.ordersource_name}
                    </option>
                    
                  );
                })}
              </select> */}
              {inputOrdersourceError && (
                <div className="error-order-chanel">
                  <img
                    className="alert-icon"
                    src="/assets/icons/exclamation-free-icon-font.svg"
                    alt="alert"
                  />
                  <span>กรุณาเลือกช่องทางการซื้อ</span>
                </div>
              )}
            </div>
          </div>
          <div className="order-chanel-tel">
            <div className="col-3">
              <label>
                เบอร์มือถือที่ใช้<div>สั่งซื้อ : *</div>
              </label>
            </div>
            <div className="col-9">
              <div className="d-flex">
                <input
                  type="text"
                  className={
                    phoneAlert ? alertPhoneNoClassName : errorPhoneNoClassName
                  }
                  value={enterPhoneNo}
                  onChange={(e) => onPhoneNoChangeHandle(e)}
                  onBlur={onPhoneNoBlurHandle}
                  maxLength={10}
                />
                {click === true ? (
                  <button
                    className={
                      !otpIsValid ? "disable-otp" : "grrs-primary-button"
                    }
                    disabled={!otpIsValid}
                    onClick={() => handleClickOtp(enterPhoneNo)}
                  >
                    <div>
                      ขอรหัส
                      <div>OTP</div>
                    </div>
                  </button>
                ) : (
                  <button className="disable-otp" disabled>
                    <div>
                      ขออีกครั้ง
                      <div>
                        <CountdownOtp
                          initialSeconds={initialSeconds}
                          setClick={setClick}
                        />
                      </div>
                    </div>
                  </button>
                )}
              </div>
              {inputPhoneNoError && (
                <div className="error-order-chanel">
                  <img
                    className="alert-icon"
                    src="/assets/icons/exclamation-free-icon-font.svg"
                    alt="alert"
                  />
                  <span>กรุณากรอกเบอร์มือถือให้ถูกต้อง</span>
                </div>
              )}
              {phoneAlert && (
                <div className="error-order-chanel">
                  <img
                    className="alert-icon"
                    src="/assets/icons/exclamation-free-icon-font.svg"
                    alt="alert"
                  />
                  <span>เบอร์มือถือไม่ตรงกับเลขสั่งซื้อ</span>
                </div>
              )}
            </div>
          </div>
          <div className="otp">
            <div className="col-2">
              <label>รหัส OTP : </label>
              <label className="ref-otp">(Ref: {refNo})</label>
            </div>
            <div className="col-10">
              <input
                type="text"
                className={otpAlert ? alertOtpClassName : errorOtpClassName}
                placeholder="ระบุรหัส OTP"
                value={enterOtp}
                onChange={(e) => onOtpChangeHandle(e)}
                onBlur={onOtpBlurHandle}
                disabled={enableOtpInput}
                maxLength={6}
              />
              {inputOtpError && (
                <div className="error-order-chanel">
                  <img
                    className="alert-icon"
                    src="/assets/icons/exclamation-free-icon-font.svg"
                    alt="alert"
                  />
                  <span>กรุณากรอกตัวเลข OTP ให้ถูกต้อง</span>
                </div>
              )}
              {otpAlert && (
                <div className="error-order-chanel">
                  <img
                    className="alert-icon"
                    src="/assets/icons/exclamation-free-icon-font.svg"
                    alt="alert"
                  />
                  <span>เลข OTP ไม่ถูกต้อง</span>
                </div>
              )}
            </div>
          </div>
          <div className="note">
            <p>หมายเหตุ</p>
            <p>1. เบอร์มือถือและหมายเลขสั่งซื้อต้องตรงกันกับการสั่งซื้อ</p>
            <p>2. ระบบจะส่งรหัส OTP เป็น sms ให้เบอร์มือถือที่ระบุ</p>
          </div>
        </Modal.Body>
        <Modal.Footer bsPrefix="grrs-modal-footer">
          <Button bsPrefix="cancle grrs-white-button" onClick={handleClose}>
            ยกเลิก
          </Button>
          <Button
            bsPrefix={
              !formIsValid || isButtonLoading ? "disable-confirm" : "confirm grrs-primary-button"
            }
            disabled={!formIsValid || isButtonLoading}
            onClick={() =>
              handleClickConfirm(
                otpTxId,
                refNo,
                enterOtp,
                enterPhoneNo,
                enterOrdersource
              )
            }
          >
            <span>ยืนยัน <span className="spinner-border spinner-border-xl button-spinner" role="status" aria-hidden="true" hidden={!isButtonLoading}></span></span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OtpModal;
