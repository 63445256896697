import React, { useEffect, useState } from "react";
import { useTable, useFilters, usePagination, useSortBy, useRowSelect } from "react-table";
import '../../../../../scss/common/utils.scss';
import Checkbox from "../CreateFile/ColumnFilter/Checkbox";
import Pagination from "../../Share/Pagination";

function Table({
  data,
  columns,
  defaultColumn,
  setIsNoData,
  isNoData,
  redirectFunction = () => { },
  parentPage,
  handleRefundBtn = () => { },
  enabledStatus = "",
  permission = [],
  isCanCreateOrUpdate = true,
  isCashMode = null,
  isCanSearch = true
}) {
  const [currentSort, setCurrentSort] = useState("");
  const [currentColumnSort, setCurrentColumnSort] = useState("");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    rows,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    defaultColumn,
    disableSortRemove: true,
    initialState:
    {
      pageIndex: 0,
      pageSize: 20,
      hiddenColumns: columns
        .filter(col => col.show === false)
        .map(col => col.accessor)
    },
  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (parentPage === "createFile" || parentPage === "approveRefund") {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            disableSortBy: true,
            Header: ({ toggleRowSelected, rows }) => {

              const modifiedOnChange = (event) => {
                rows.map((row) => {
                  row.original?.request_refund_status_text === enabledStatus && toggleRowSelected(row.id, event.currentTarget.checked);
                });
              };

              return (
                <div>
                  <Checkbox onChange={modifiedOnChange}
                    checked={
                      rows.filter(row => row.original?.request_refund_status_text === enabledStatus).every(row => row.isSelected === true) &&
                      rows.length !== 0 &&
                      rows.some(row => row.original?.request_refund_status_text === enabledStatus) &&
                      rows.filter(row => row.original?.is_can_export === 1).every(row => row.isSelected === true) &&
                      rows.some(row => row.original?.is_can_export === 1)
                    }
                    disabled={
                      rows.some(row => row.original?.request_refund_status_text === enabledStatus) &&
                        rows.some(row => row.original?.is_can_export === 1) &&
                        isCanCreateOrUpdate ? false : true
                    }
                  />
                </div>
              )
            },
            Cell: ({ row }) => (
              <div>
                <Checkbox {...row.getToggleRowSelectedProps()}
                  disabled={row.original?.request_refund_status_text === enabledStatus && row.original?.is_can_export === 1 && isCanCreateOrUpdate ? false : true}
                />
              </div>
            ),
          },
          ...columns,
        ])
      }
    }
  )

  const handleSorting = (column, sortingBy) => {
    const initialColumn = column;
    sortingBy === "desc" ? column.toggleSortBy(true, false) : column.toggleSortBy(false, false);
    setCurrentColumnSort(initialColumn.Header);
    setCurrentSort(sortingBy);
  }

  useEffect(() => {
    if (rows.length === 0) {
      setIsNoData(true);
    } else setIsNoData(false);
  }, [rows])

  return (
    <>
      {
        parentPage !== "downloadFile" ?
          (
            <div className={isNoData ? "no-data create-file" : "d-none"}>{isCanSearch ? "ไม่พบข้อมูล": "คุณไม่มีสิทธิ์เข้าถึงข้อมูล"}</div>
          )
          : (
            <div className={isNoData ? "no-data" : "d-none"}>ไม่พบข้อมูล</div>
          )
      }
      <div className={isNoData ? "table-common empty-table" : "table-common"}>
        <table className="table-primary" {...getTableProps()}>
          <thead className="table-header">
            {headerGroups.map((headerGroup) => (
              <>
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map(column => (
                    <th className="grrs"
                      {...column.getHeaderProps()}
                      key={column.id}
                    >
                      {column.render("Header")}
                      {
                        parentPage !== "downloadFile" && (
                          <span>
                            {
                              currentSort === "asc" && column.Header === currentColumnSort ? (
                                <img className="arrow-up post" onClick={() => handleSorting(column, "desc")} src={process.env.PUBLIC_URL + "/assets/grrs/icons/pre-sorting-desc.svg"} alt="post-sort" hidden={column.disableSortBy} />
                              ) : currentSort === "desc" && column.Header === currentColumnSort ? (
                                <img className="arrow-down post" onClick={() => handleSorting(column, "asc")} src={process.env.PUBLIC_URL + "/assets/grrs/icons/pre-sorting-asc.svg"} alt="post-sort" hidden={column.disableSortBy} />
                              ) : (
                                <>
                                  <img className="arrow-down pre" onClick={() => handleSorting(column, "asc")} src={process.env.PUBLIC_URL + "/assets/grrs/icons/pre-sorting-asc.svg"} alt="pre-sort" hidden={column.disableSortBy} />
                                  <img className="arrow-up pre" onClick={() => handleSorting(column, "desc")} src={process.env.PUBLIC_URL + "/assets/grrs/icons/pre-sorting-desc.svg"} alt="pre-sort" hidden={column.disableSortBy} />
                                </>
                              )
                            }
                          </span>
                        )
                      }
                    </th>
                  ))}
                </tr>
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map(column => (
                    <th className="grrs-filter" {...column.getHeaderProps()} key={column.id}>{column.canFilter ? column.render("Filter") : null}</th>
                  ))}
                </tr>
              </>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}
                  className={index % 2 !== 0 ? "bg-white" : ""}
                >
                  {row.cells.map((cell) => {
                    return parentPage !== "downloadFile" && parentPage !== "reportVoidAutoSuccess" ?
                      (<td {...cell.getCellProps()} onClick={() => redirectFunction(row, cell.getCellProps())}>{cell.render("Cell")}</td>)
                      : (<td className="download-file-cells" {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        isNoData={isNoData}
        parentPage={parentPage}
        permission={permission}
        setPageSize={setPageSize}
        pageSize={pageSize}
        rows={rows}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        selectedFlatRows={selectedFlatRows}
        handleRefundBtn={handleRefundBtn}
        isCashMode={isCashMode}
      />
      <input id="selectedRows" type="hidden" value={selectedFlatRows.map(row => row.original?.customer_request_no)} />
    </>
  );
}

export default Table;