import React, { useState, useEffect } from "react";
import CustomerInfo from "../../components/Share/FormRequestCancel/customerInfo";
import DetailCancel from "../../components/Share/FormRequestCancel/detailCancel";
import Reason from "../../components/Share/FormRequestCancel/reason";
import ChannelRefund from "../../components/Share/FormRequestCancel/channelRefund";
import SummaryRefundCancel from "../../components/Share/FormRequestCancel/summaryRefundCancel";
import TransferMoneyModal from "../../components/Share/TransferMoneyModal";
import InsufficientBalanceModal from "../../components/ContactCenter/InsufficientBalanceModal";
import RetryVoidModal from "../../components/ContactCenter/RetryVoidModal";
import VoidFailedModal from "../../components/ContactCenter/VoidFailedModal";
import SuccessModal from "../../components/ContactCenter/SuccessModal";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import { useHistory, useLocation } from "react-router-dom";
import "./ContactCenter.scss";
import {
  getOrderCancellationInitial,
  getCancellationRefundMaster,
  postCancellationRequest,
  postCancellationRequestRetry,
} from "../../../../service/grrs/contactCenter.service";
import { getSummaryRefund } from "../../../../helper/grrs/redeemAndEarnPointHelper";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import PopupError from "../../components/Share/Popup/PopupError";

const initCancellationRefundMaster = {
  reason_list: [],

  refund_channel: [],

  bank_master: {
    bank_account: [],

    bank_account_type: [],
  },
  cancel_policy_link: "",
};

const initCancellationInitial = {
  orders: {
    total_price: 0,
    redeemedSummary: [],
    earnPoints: [],
    order_detail_list: [],
    invoice_detail_list: [],
    invoice_value: 0,
    refund_channel_name: "",
  },

  request_activity_log: [],
};

const initReason = {
  reason_code: "",
  reason_remark: "",
  is_all_member_point_compensation: 0,
  cc_remark: "",
};

const initChannelRefund = {
  channel_code: "",
  bank_account_type_code: "",
  promtpay_no: "",
  bank_code: "",
  account_no: "",
  account_name: "",
};

function RequestCancel(props) {
  let [orderHistory, setOrderHistory] = useState(JSON.parse(localStorage.getItem("grrs-order")));

  const urlPath = "/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-list";

  const history = useHistory();

  const { mainOrderNo, ordersource } = useLocation();

  const isContactCenter = true;

  const [isCashMode, setIsCashMode] = useState(true);

  const [isCanEditChannelRefund, setIsCanEditChanngelRefund] =
    useState(isContactCenter);

  const [channelRefundForm, setChannelRefundForm] = useState(initChannelRefund);

  const [cancellationRefundMaster, setCancellationRefundMaster] = useState(
    initCancellationRefundMaster
  );

  const [cancellationInitial, setCancellationInitial] = useState(
    initCancellationInitial
  );

  const [reason, setReason] = useState(initReason);

  const [transferMoneyModalState, setTransferMoneyModalState] = useState(false);
  const [insufficientBalanceModalState, setInsufficientBalanceModalState] = useState(false);
  const [showRetryVoidModal, setShowRetryVoidModal] = useState(false);
  const [voidFailedModalState , setVoidFailedModalState] = useState(false);
  const [retryTimesLimitState , setRetryTimesLimitState] = useState(0);;
  const [retryTimesState , setRetryTimesState] = useState();
  const [postCancellationData, setPostCancellationData] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDataRetryFailModal, setShowDataRetryFailModal] = useState({});
 

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    body: [""]
  });

  const getIsCashMode = (cancellationRefundMaster) => {
    return cancellationRefundMaster.refund_channel.length >= 3 ? true : false;
  };

  const backpage = (mainOrderNo, ordersource) => {
    history.push({
      pathname: "/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-detail",
      mainOrderNo,
      ordersource,
    });
  };

  const {
    refundAllMemberPoint,
    refundNonAllMemberPoint,
    cancelAllMemberPoint,
    cancelNonAllMemberPoint,
  } = getSummaryRefund(cancellationInitial.orders);

  const getBankText = () => {
    if (document.getElementById("bank-text") !== null) {
      const bankIndex = document.getElementById("bank-text").selectedIndex;
      const bankText =
        document.getElementById("bank-text").options[bankIndex].text;
      return bankText;
    }
    return "";
  };

  const getChannelText = () => {
    if (document.getElementById("channel-text") !== null) {
      const channelIndex =
        document.getElementById("channel-text").selectedIndex;
      const channelText =
        document.getElementById("channel-text").options[channelIndex].text;
      return channelText;
    }
    return "";
  };

  const data = {
    mainorder_no: mainOrderNo,
    ordersource: ordersource,
    action_type: "cancel",
    i_account: null,
    all_member_id: orderHistory?.member_id,
    operation_id: "CS01",
    excution: "create",
    isCashMode: isCashMode,
    payment_channel_name: cancellationInitial.orders.payment_channel_name,
    invoice_value: cancellationInitial.orders.invoice_value,
    refund_channel_name: cancellationInitial.orders.refund_channel_name,
    refund_method: {
      bank_text: getBankText(),
      channel_text: getChannelText(),
      channel_code: channelRefundForm.channel_code,
      bank_account_type_code: channelRefundForm.bank_account_type_code,
      promtpay_no: channelRefundForm.promtpay_no,
      bank_code: channelRefundForm.bank_code,
      account_no: channelRefundForm.account_no,
      account_name: channelRefundForm.account_name,
    },
    reason: {
      reason_code: reason.reason_code,
      reason_remark: reason.reason_remark,
      is_all_member_point_compensation: reason.is_all_member_point_compensation,
      cc_remark: reason.cc_remark,
    },
    total_refund: {
      total_refund: cancellationInitial.orders.total_price,
      return_amp: refundAllMemberPoint,
      return_m_stamp: refundNonAllMemberPoint,
      cancel_amp: cancelAllMemberPoint,
      cancel_m_stamp: cancelNonAllMemberPoint,
    },
  };

  function handleModal() {
    setTransferMoneyModalState(true);
  }

  const getOrderCancellationInitialFromApi = () => {
    if (mainOrderNo === undefined && ordersource === undefined) {
      history.push("/grrs/contact-center/สร้างคำขอแทนลูกค้า");
    } else {
      getOrderCancellationInitial(mainOrderNo, ordersource).then((res) => {
        if (res.length !== 0) setCancellationInitial(res.response_data);
        setIsLoading(false);
      });
    }
  };

  const getCancellationRefundMasterFromApi = () => {
    if (mainOrderNo === undefined && ordersource === undefined) {
      history.push("/grrs/contact-center/สร้างคำขอแทนลูกค้า");
    } else {
      getCancellationRefundMaster(mainOrderNo, ordersource).then((res) => {
        if (res.length !== 0) {
          setCancellationRefundMaster(res.response_data);
          setIsCashMode(getIsCashMode(res.response_data));
          setIsCanEditChanngelRefund(getIsCashMode(res.response_data));
          if (res.response_data.refund_channel.length == 1) {
            setChannelRefundForm({ ...channelRefundForm, channel_code: res.response_data.refund_channel[0].channel_code })
          }
        }
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderCancellationInitialFromApi();
    getCancellationRefundMasterFromApi();
  }, []);

    const handleRetryClick = () => {
      postCancellationRequestRetryFromApi(mainOrderNo, ordersource)
  };

  const postCancellationRequestRetryFromApi = (mainOrderNo, ordersource) => {
    setIsLoading(true);
    const request = {  
      mainorder_no: mainOrderNo,
      ordersource: ordersource,
    };
    postCancellationRequestRetry(request).then((res) => {
      localStorage.setItem("grrs-isOrderCancelCompleted", "1");
      const retryData = res?.response_data
      setRetryTimesLimitState(retryData?.limit_times);
      setRetryTimesState(retryData?.retry_times);
      setShowDataRetryFailModal(res);
      if(res?.response_code == 100){
        setTransferMoneyModalState(false);
          setShowRetryVoidModal(false);
          setShowSuccessModal(true);
      }else{
        if (retryData?.is_retryable) {
          setTransferMoneyModalState(false);
          setShowRetryVoidModal(true);
        }else{
          setShowRetryVoidModal(false);
          setVoidFailedModalState(true);
        }
      }
      setIsLoading(false);
    });
    return {};
  };


  function handleSubmit() {
    let refund_method = {}
    if (isCashMode) {
      if (channelRefundForm.channel_code === "PP") {
        refund_method = {
          channel_code: channelRefundForm.channel_code,
          bank_account_type_code: channelRefundForm.bank_account_type_code,
          promtpay_no: channelRefundForm.promtpay_no,
          bank_code: null,
          account_no: null,
          account_name: null
        }
      } else {
        refund_method = {
          channel_code: channelRefundForm.channel_code,
          bank_account_type_code: null,
          promtpay_no: null,
          bank_code: channelRefundForm.bank_code,
          account_no: channelRefundForm.account_no,
          account_name: channelRefundForm.account_name,
        }
      }
    } else {
      refund_method = {
        channel_code: channelRefundForm.channel_code,
        bank_account_type_code: null,
        promtpay_no: null,
        bank_code: null,
        account_no: null,
        account_name: null,
      }
    }

    const postData = {
      mainorder_no: mainOrderNo,
      ordersource: ordersource,
      action_type: "cancel",
      i_account: null,
      all_member_id: orderHistory?.member_id,
      operation_id: "CS01",
      excution: "create",
      refund_method,
      reason: {
        reason_code: reason.reason_code,
        reason_remark: reason.reason_remark,
        is_all_member_point_compensation:
          reason.is_all_member_point_compensation,
        cc_remark: reason.cc_remark,
      },
    };

    if (urlPath === "/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-list") {
      document.getElementById("transfer-refund-submit").disabled = true;
      const closeButton = document.getElementById("transfer-money-close-btn");
      closeButton.click();
      setIsLoading(true);
      postCancellationRequest(postData).then((res) => {
        setIsLoading(false);
        localStorage.setItem("grrs-isOrderCancelCompleted", "1");
        setPostCancellationData(res?.response_data);
        if (res?.response_code === 100) {
          setTransferMoneyModalState(false);
          setShowSuccessModal(true);
        } else {
          if(res?.response_data?.is_retryable){
            setTransferMoneyModalState(false);
            setShowRetryVoidModal(true);
          }else{
            setTransferMoneyModalState(false);
            setInsufficientBalanceModalState(true);
          }

          // setTransferMoneyModalState(false);
          // openInsufficientBalanceModal();

          // const errorMessages = res?.response_data?.message.split("$h");
          // if (errorMessages[1]) {
          //   const errorMessagesBody = errorMessages[1].split("$n");
          //   setErrorMessage({
          //     header: errorMessages[0],
          //     body: errorMessagesBody[1] ?
          //       errorMessagesBody : errorMessages[1]
          //   });
          //   } else {
          //     const errorMessagesBody = res?.response_data?.message.split("$n");
          //     setErrorMessage({
          //       body: errorMessagesBody[1] ?
          //         errorMessagesBody : ["", res?.response_data?.message],
          //     });
          //     document.getElementById("request-cancel-result-btn").click();
          //     document.getElementById("transfer-refund-submit").disabled = false;
          //   }

        }}
      );
    }
  }

  const validateReason = (reason) => {
    if (reason.reason_code === "") {
      return false;
    }
    if (reason.reason_remark === "") {
      return false;
    }
    return true;
  };

  const validateChannelRefundForm = (channelRefundForm, isCashMode) => {
    if (isCashMode == false) return true;
    if (channelRefundForm.channel_code === "") {
      return false;
    }
    if (
      channelRefundForm.channel_code === "BSA" ||
      channelRefundForm.channel_code === "BCA"
    ) {
      if (channelRefundForm.bank_code === "") return false;
      if (channelRefundForm.account_no === "") return false;
      if (channelRefundForm.account_name === "") return false;
      return true;
    }
    if (channelRefundForm.channel_code === "PP") {
      if (channelRefundForm.bank_account_type_code === "") return false;
      if (channelRefundForm.promtpay_no === "") return false;
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="container">
        <div className="layout">
          <div className="row vh-100">
            <div className="row">
              <ResponsiveSideBar />
              <div className="col-md-9 content">
                <LoadingSpinner isLoading={isLoading} />
                <div className="container">
                  <div className="grrs-row d-flex order-detail margin-left">
                    <button
                      className="back-button"
                      onClick={() => backpage(mainOrderNo, ordersource)}
                    >
                      <img src="/assets/icons/backicon.svg" alt="back" />
                    </button>
                    <span className="mt-3">
                      <h1>ทำการยกเลิกสินค้า #{mainOrderNo}</h1>
                    </span>
                  </div>
                  {!isLoading &&
                    (
                      <>
                        <CustomerInfo
                          cancellationInitial={cancellationInitial}
                          isHaveTrackingModal={false}
                        />
                        <DetailCancel cancellationInitial={cancellationInitial} />
                        <Reason
                          reason={reason}
                          setReason={setReason}
                          isCanEdit={true}
                          cancellationRefundMaster={cancellationRefundMaster}
                        />
                        <ChannelRefund
                          cancellationInitial={cancellationInitial}
                          isCashMode={isCashMode}
                          cancellationRefundMaster={cancellationRefundMaster}
                          isCanEdit={isCanEditChannelRefund}
                          setIsCanEdit={setIsCanEditChanngelRefund}
                          channelRefundForm={channelRefundForm}
                          setChannelRefundForm={setChannelRefundForm}
                          isContactCenter={isContactCenter}
                          IsCanChangeEditMode={false}
                          channalRefundValue={"Line"}
                        />
                        <SummaryRefundCancel
                          cancellationInitial={cancellationInitial}
                          refundAllMemberPoint={refundAllMemberPoint}
                          refundNonAllMemberPoint={refundNonAllMemberPoint}
                          cancelAllMemberPoint={cancelAllMemberPoint}
                          cancelNonAllMemberPoint={cancelNonAllMemberPoint}
                        />
                        <div className="pt-5 pb-5">
                          <button
                            className="request-cancel-cancelButton grrs-white-button"
                            onClick={() => backpage(mainOrderNo, ordersource)}
                          >
                            ยกเลิก
                          </button>
                          <button
                            className={
                              validateReason(reason) &&
                                validateChannelRefundForm(channelRefundForm, isCashMode)
                                ? "request-cancel-confirmButton grrs-primary-button"
                                : "request-cancel-confirmButton-disabled"
                            }
                            onClick={() => handleModal()}
                            disabled={
                              !validateReason(reason) ||
                              !validateChannelRefundForm(
                                channelRefundForm,
                                isCashMode
                              )
                            }
                          >
                            ยืนยัน
                          </button>
                        </div>
                        <TransferMoneyModal
                          data={data}
                          transferMoneyModalState={transferMoneyModalState}
                          setTransferMoneyModalState={setTransferMoneyModalState}
                          urlPath={urlPath}
                          handleSubmit={handleSubmit}
                        />
                        <PopupError
                          errorMessage={errorMessage}
                        />
                        <InsufficientBalanceModal
                        postCancellationData={postCancellationData}
                        insufficientBalanceModalState={insufficientBalanceModalState}
                        setInsufficientBalanceModalState={setInsufficientBalanceModalState}
                        />
                         <RetryVoidModal
                        showRetryVoidModal={showRetryVoidModal}
                        retryTimesLimitState={retryTimesLimitState} 
                        retryTimesState={retryTimesState}
                        handleRetryClick={handleRetryClick}
                        />
                        <VoidFailedModal
                        voidFailedModalState={voidFailedModalState}
                        setVoidFailedModalState={setVoidFailedModalState}
                        showDataRetryFailModal={showDataRetryFailModal}
                        />
                       <SuccessModal
                       showSuccessModal={showSuccessModal}
                       isCashMode={isCashMode}
                       />
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestCancel;
