import React from "react";
import "./TrackingModal.scss";

function TrackingModal({
  trackingData,
  request_refund_status_text = "ส่งคำขอยกเลิกรายการสินค้า",
}) {
  const { order_no, create_date, order_tracking_status, tracking_timeline } =
    trackingData?.orders;
  const trackingReturnProduct = request_refund_status_text;

  function handleIconTracking() {
    return order_tracking_status.length === 3
      ? "row justify-content-center align-items-center status-3"
      : order_tracking_status.length === 2
      ? "row justify-content-center align-items-center status-2"
      : "row justify-content-center align-items-center";
  }

  function autoDisplayModal() {
    if (trackingReturnProduct === request_refund_status_text) {
      const displayModal = document.getElementById("trackModalDisplayButton");
      displayModal.click();
    }
  }

  return (
    <>
      <p
        className={
          trackingReturnProduct === request_refund_status_text
            ? "grrs-tracking-return-product"
            : "grrs-tracking-return-product disabled"
        }
        onClick={() => autoDisplayModal()}
      >
        {request_refund_status_text}
      </p>
      <div className="trackingModal">
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#statusTrackingMethod"
          id="trackModalDisplayButton"
          hidden
        >
          ตกลง
        </button>

        <div
          className="modal fade"
          id="statusTrackingMethod"
          tabIndex="-1"
          aria-labelledby="statusTrackingMethodLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-fullscreen-lg-down">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="statusTrackingMethodLabel">
                  รายการคำขอยกเลิก #{order_no}
                  <span className="modal-title-date">
                    {" "}
                    วันที่ {create_date}
                  </span>
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="border-top"></div>
                <ol className={handleIconTracking()}>
                  <li className="col-3 icon-status-tracking">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/grrs/statusTracking/icon-status-tracking-1.svg"
                      }
                      className="rounded mx-auto d-block"
                      alt="tracking"
                    />
                    <label className="text-center text-status-completed">
                      ตรวจสอบคำขอยกเลิก
                    </label>
                  </li>
                  <li className="col-3 icon-status-tracking">
                    {order_tracking_status.length >= 2 ? (
                      <>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/grrs/statusTracking/icon-status-tracking-2.1.svg"
                          }
                          className="rounded mx-auto d-block"
                          alt="tracking"
                        />
                        <label className="text-center text-status-completed">
                          กำลังดำเนินการคืนเงิน
                        </label>
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/grrs/statusTracking/icon-status-tracking-2.svg"
                          }
                          className="rounded mx-auto d-block"
                          alt="tracking"
                        />
                        <label className="text-center text-status-waiting">
                          กำลังดำเนินการคืนเงิน
                        </label>
                      </>
                    )}
                  </li>
                  <li className="col-3 icon-status-tracking">
                    {order_tracking_status.length === 3 ? (
                      <>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/grrs/statusTracking/icon-status-tracking-3.1.svg"
                          }
                          className="rounded mx-auto d-block"
                          alt="tracking"
                        />
                        <label className="text-center text-status-completed">
                          ดำเนินการคืนเงินแล้ว
                        </label>
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/grrs/statusTracking/icon-status-tracking-3.svg"
                          }
                          className="rounded mx-auto d-block"
                          alt="tracking"
                        />
                        <label className="text-center text-status-waiting">
                          ดำเนินการคืนเงินแล้ว
                        </label>
                      </>
                    )}
                  </li>
                </ol>
                <div className="border-top"></div>
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="row align-items-center no-space">
                      <div className="col-12">
                        <h2 className="fw-bold">สถานะการขอยกเลิก</h2>
                      </div>
                    </div>
                  </div>
                  {tracking_timeline.map((list, index) => (
                    <div
                      className="row align-items-center no-space-between-row"
                      key={index}
                    >
                      <div className="col-3 text-detials">
                        {list.timeline_date}
                      </div>
                      <div className="col-9">
                        <div className="row align-items-center">
                          <div className="col-1 text-center">
                            <div
                              className={
                                tracking_timeline.length - 1 === index
                                  ? "vertical-line d-none"
                                  : "vertical-line"
                              }
                            ></div>
                            <div
                              className={
                                index === 0 ? "dot current-status" : "dot"
                              }
                            ></div>
                          </div>
                          <div className="col-11 text-detials">
                            {list.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrackingModal;
