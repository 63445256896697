const AlertComponent = ({ alertName, responseCode, isHandleAlertClick, handleAlertClick, isHandleAlertSaveClick }) => {

    const alertHandle = (name) => {
        let error_message = "";
        if (name === "error-nodata") {
            if (responseCode === undefined) {
                error_message = "ระบบขัดข้อง"
                return (
                    <div className="fixed-top user-alert d-block">
                        <div className="alert alert-danger alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                            <strong>เกิดข้อผิดพลาด</strong> {error_message}
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                        </div>
                    </div>
                )
            }
            else {
                switch (responseCode) {
                    case 201:
                        error_message = "ไม่พบข้อมูลที่ค้นหาในระบบ";
                        break;
                    case 301:
                        error_message = "ไม่พบข้อมูลที่ค้นหาในระบบ";
                        break;
                    case 401:
                        error_message = "ไม่พบข้อมูลที่ค้นหาในระบบ";
                        break;
                    case 999:
                        error_message = "ระบบขัดข้อง";
                        break;
                    default: error_message = "ไม่พบข้อมูลที่ค้นหาในระบบ";
                }
                return (
                    <div className="fixed-top user-alert d-block">
                        <div className="alert alert-danger alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                            <strong>เกิดข้อผิดพลาด</strong> {error_message}
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                        </div>
                    </div>
                )
            }
        }
        if (name === "warning-input-user_eturn_qty") {
            return (
                <div className="fixed-top user-alert d-block">
                    <div className="alert alert-warning alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                        <strong>คำเตือน</strong> กรุณาเพิ่มจำนวนที่จะคืน
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                    </div>
                </div>
            )
        }
        if (name === "success-calculated") {
            if (responseCode !== undefined) {
                error_message = "ระบบขัดข้อง"
                return (
                    <div className="fixed-top user-alert d-block">
                        <div className="alert alert-success alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                            <strong>สำเร็จ</strong> ระบบทำการคำนวณเสร็จสิ้น
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                        </div>
                    </div>
                )
            } else if (responseCode === undefined) {
                error_message = "ระบบขัดข้อง"
                return (
                    <div className="fixed-top user-alert d-block">
                        <div className="alert alert-danger alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                            <strong>เกิดข้อผิดพลาด</strong> {error_message}
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                        </div>
                    </div>
                )
            }
        }
        if (name === "success-save") {
            if (responseCode === undefined) {
                error_message = "ระบบขัดข้อง"
                return (
                    <div className="fixed-top user-alert d-block">
                        <div className="alert alert-danger alert-dismissible fade show" role="alert" hidden={isHandleAlertSaveClick === true ? true : false}>
                            <strong>เกิดข้อผิดพลาด</strong> {error_message}
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="fixed-top user-alert d-block">
                        <div className="alert alert-success alert-dismissible fade show" role="alert" hidden={isHandleAlertSaveClick === true ? true : false}>
                        <div className="spinner-border text-success" role="status">
                        </div>
                            <strong> สำเร็จ</strong> ระบบทำการบันทึกข้อมูลเสร็จสิ้น
                            {/* <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button> */}
                        </div>
                    </div>
                )
            }
        }
        if (name === "error-save") {
            if (responseCode === undefined) {
                error_message = "ระบบขัดข้อง"
                return (
                    <div className="fixed-top user-alert d-block">
                        <div className="alert alert-danger alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                            <strong>เกิดข้อผิดพลาด</strong> {error_message}
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                        </div>
                    </div>
                )
            }
            return (
                <div className="fixed-top user-alert d-block">
                    <div className="alert alert-danger alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                        <strong>เกิดข้อผิดพลาด</strong> ไม่พบข้อมูลในระบบ
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                    </div>
                </div>
            )
        }
        if (name === "warning-export") {
            return (
                <div className="fixed-top user-alert d-block">
                    <div className="alert alert-warning alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                        <strong>คำเตือน</strong> กรุณาค้นหาข้อมูลก่อนดาวน์โหลดไฟล์ข้อมูล
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                    </div>
                </div>
            )
        }
        if (name === "etax-pdf-null") {
            return (
                <div className="fixed-top user-alert d-block">
                    <div className="alert alert-danger alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                        <strong>เกิดข้อผิดพลาด</strong> ไม่พบไฟล์ข้อมูลใบกำกับภาษี
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                    </div>
                </div>
            )
        }
        if (name === "ecn-pdf-null") {
            return (
                <div className="fixed-top user-alert d-block">
                    <div className="alert alert-danger alert-dismissible fade show" role="alert" hidden={isHandleAlertClick === true ? true : false}>
                        <strong>เกิดข้อผิดพลาด</strong> ไม่พบไฟล์ข้อมูลใบลดหนี้
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClick}></button>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            {alertHandle(alertName)}
        </>
    )
};
export default AlertComponent;