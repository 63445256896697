import React, { useState, useContext } from "react";
import EcreditNoteTable from "../common/ECreditNoteTable";
import SearchECN from "../common/SearchECN";
import { Link } from "react-router-dom";
import { ExportExcel } from "../../utils/exportExcel";
import { paginate } from "../../../../utils/paginate";
import Pagination from "../../../common/components/Pagination";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import AlertComponent from "../../../common/components/Alert";
import MenuContext from "../../../../context/MenuContext";
import { getImportFileApprove, getExportCreditNoteRequest } from "../../../../service/ecn/e-creditnote.service";
import { isEmpty } from "lodash";
import PermissionModal from "../../../common/components/PermissionModal";

const Ecreditnote = () => {
  const fileExcelName = "export_data";
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isShowData, setIsShowData] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [isAlertName, setIsAlertName] = useState("");
  const [responseCode, setResponseCode] = useState();
  const [isHandleAlertClick, setIsHandleAlertClick] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const { permission } = useContext(MenuContext);
  const [modalImport, setModalImport] = useState(true);
  const [importResponseCode, setImportResponseCode] = useState("");
  const [importResponseData, setImportResponseData] = useState("");

  const FileDownload = require('js-file-download');

  const handleFilterData = (data) => {
    setFilterData(data);
  };

  const handleSearchData = (responseData) => {
    if (responseData.response_code === 100) {
      setData(responseData.response_data);
      setIsShowData(true);
      setIsDataEmpty(false);
    } else {
      setIsShowData(false);
      setIsDataEmpty(true);
      setIsAlertName("error-nodata");
      setResponseCode(responseData.response_code);
      setAlertHandle();
    }
  };

  const setAlertHandle = () => {
    setTimeout(() => {
      setIsHandleAlertClick(true);
    }, 5000);
    setIsHandleAlertClick(false);
  };

  const handleAlertClick = () => {
    setIsHandleAlertClick(true);
  };

  const setModalAlertHandle = () => {
    let modalBtn = document.querySelector(".btn-modal");
    setModalConfirm(true);
    modalBtn.click();
  };

  const confirmHandle = (e) => {
    if (e.target.id === "confirm") {
      return ExportExcel(
        data,
        fileExcelName,
        setIsAlertName,
        setAlertHandle,
        true,
        setModalAlertHandle
      );
    } else
      return ExportExcel(
        data,
        fileExcelName,
        setIsAlertName,
        setAlertHandle,
        false,
        setModalAlertHandle
      );
  };

  function handlePageChange(page) {
    setCurrentPage(page);
  }
  const ExportExcel2 = () => {
    getExportCreditNoteRequest().then((response) => {

      var contentDisposition = response.headers["content-disposition"];
      var filename = contentDisposition
        .split(";")[1]
        .split("filename")[1]
        .split("=")[1]
        .trim();
      const url = window.URL.createObjectURL(
        new Blob([response.body], { type: "application/vnd.ms-excel" })
      );
      let hiddenElement = document.createElement("a");
      hiddenElement.href = url;
      hiddenElement.target = "_blank";
      hiddenElement.download = filename;
      hiddenElement.click();
    });
  };
  const handleChangePageSize = (e) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const paginatedData = paginate(data, currentPage, pageSize);
  async function handleFileChange(e) {
    setIsLoading(true);
    setModalImport(true);
    let modal = document.getElementById("modal_button");
    getImportFileApprove(e.target.files[0]).then((res) => {
      if (!isEmpty(res)) {
        if (res.response_code === 100) {
          setImportResponseCode(res.response_code);
          setImportResponseData(res.response_data);
          modal.click();
        } else {
          setImportResponseCode(res.response_code);
          setImportResponseData(res.response_data.message);
          modal.click();
        }
        setIsLoading(false);
      } else {
        setImportResponseCode("");
        setImportResponseData("");
        setIsLoading(false);
      }
    });
  }

  const ExportFile = () => {
    if (permission.some((res) => res === "export")) {
      getExportCreditNoteRequest().then(res => {
        const contentDisposition = res.headers["content-disposition"];
        const filename = contentDisposition
          .split(";")[1]
          .split("filename")[1]
          .split("=")[1]
          .trim();
        FileDownload(res.data, filename)
      });
    }
  }

  return (
    <>
      <div className="container">
        <AlertComponent
          alertName={isAlertName}
          responseCode={responseCode}
          isHandleAlertClick={isHandleAlertClick}
          handleAlertClick={handleAlertClick}
        />
        <div className="ecn-request">
          <h1 className="ecn-request-header">คำขอคืนสินค้า</h1>
          <SearchECN setData={handleSearchData} setIsLoading={setIsLoading} />
          <div className="ecn-request-header-button">
            <div className="row">
              <div className="col-12 col-sm-4 text-center text-sm-start col-md-3 col-lg-3 col-xxl-2 mb-3">
                <Link to="/ecn/add-ecreditnote">
                  <button className="btn ecn-request-link-to-add" disabled={!permission.some((res) => res === "create")}>
                    <img
                      className="plus-icon"
                      src="/assets/ecn/plus-button.svg"
                      alt="plus"
                    />
                    เพิ่มคำขอคืนสินค้า
                  </button>
                </Link>
              </div>
              <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xxl-3 ecn-export">
                <a
                  className="ecn-request-export"
                  onClick={() => ExportFile()}
                  disabled={!permission.some((res) => res === "export")}
                >
                  <img
                    className="export-icon"
                    src="/assets/ecn/export-icon.svg"
                    alt="export"
                  />
                  Export file ข้อมูล
                </a>
                <button className="btn ecn-request-export-mobile"
                  onClick={() => ExportFile()}
                  disabled={!permission.some((res) => res === "export")}
                >
                  <img
                    className="export-icon"
                    src="/assets/ecn/export-icon.svg"
                    alt="export"
                  />
                  Export file ข้อมูล
                </button>
              </div>
              <div className="col-6 col-sm-4 col-md-5 col-lg-5 col-xxl-4">
                <input
                  type="file"
                  id="import-button"
                  onChange={(e) => handleFileChange(e)}
                  disabled={!permission.some((res) => res === "approve")}
                />
                <label className="import-logo" htmlFor="import-button" disabled={!permission.some((res) => res === "approve")}>
                  <img src="/assets/ecn/import-icon.svg" />
                  <span>อนุมัติ ECN</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col mt-4">
              <LoadingSpinner isLoading={isLoading} />
              {isDataEmpty && !isLoading && (
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/pictures/data-not-found.png"
                  }
                  className="rounded mx-auto d-block"
                  id="noOrderImg"
                  alt="..."
                ></img>
              )}

              {isShowData && !isLoading && (
                <>
                  <EcreditNoteTable data={paginatedData} />
                  <Pagination
                    itemsCount={data.length}
                    pageSize={pageSize}
                    onPageSizeChange={handleChangePageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <PermissionModal
          permission={permission}
          backPage={"/ecn"}
        />
      </div>
      <button
        type="button"
        className="btn-modal"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        hidden
      ></button>
      <div className={modalConfirm === true ? "" : "disable"}>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="header">
                <h4 className="title" id="staticBackdropLabel">
                  ยืนยันการดาวน์โหลด
                </h4>
              </div>
              <div className="body">
                <h5>โปรดตรวจสอบข้อมูล ก่อนกดยืนยัน</h5>
              </div>
              <div className="footer">
                <button
                  type="submit"
                  className="resetbutton"
                  id="confirm"
                  data-bs-dismiss="modal"
                  onClick={(e) => confirmHandle(e)}
                >
                  ดาวน์โหลด
                </button>
                <button
                  type="button"
                  className="backbutton"
                  id="cancel"
                  data-bs-dismiss="modal"
                  onClick={(e) => confirmHandle(e)}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        id="modal_button"
        className="disable"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop2"
      ></button>
      <div className={modalImport ? "" : "disable"}>
        <div
          className="modal fade"
          id="staticBackdrop2"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="header">
                <h4 className="title" id="staticBackdropLabel">
                  อัพโหลด{importResponseCode === 100 ? "สำเร็จ" : "ล้มเหลว"}
                </h4>
              </div>
              <div className="body">
                <h5>{importResponseData}</h5>
              </div>
              <div className="footer">
                <button
                  type="button"
                  className="backbutton"
                  data-bs-dismiss="modal"
                >
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecreditnote;
