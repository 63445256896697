import React, { useState, useEffect } from "react";
import "./FormRequestCancel.scss";
import "../../Share/FormRequestCancel/FormRequestCancel.scss";
function ApproveOrderCancel({
  isCanEdit = false,
  isFullForm = true,
  cancellationRefundMaster,
  approveOrderCancel,
  setApproveOrderCancel,
  isHasApproveOption = true,
  isHasWaitingOption = true,
}) {
  const [isDisplay, setIsDisplay] = useState(true);

  const {
    is_approve,
    cs_reason_code,
    cs_remark,
    all_member_point_compensation,
    is_status_urgent,
  } = approveOrderCancel;

  return (
    <div className="form-request-cancel">
      <div
        className={`header-request-cancel ${
          isCanEdit
            ? `header-request-cancel-edit ${isDisplay}`
            : "header-request-cancel-view"
        }`}
      >
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">
          อนุมัติการเปลี่ยนสินค้า Customer service
        </span>
      </div>
      {isDisplay && (
        <div
          className={`body-request-cancel ${
            isCanEdit ? "body-request-cancel-edit" : ""
          }`}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="header-checkbox">
                <span className="fw-bold">สาเหตุจาก Customer service</span>
              </div>
              <div
                className={`body-checkbox ${
                  isCanEdit ? "body-checkbox-edit" : ""
                }`}
              >
                {isHasApproveOption === true && (
                  <div>
                    <input
                      className="form-check-input form-check-input-radio"
                      type="radio"
                      disabled={!isCanEdit}
                      checked={is_approve === 1}
                      onClick={(e) =>
                        setApproveOrderCancel({
                          ...approveOrderCancel,
                          is_approve: 1,
                        })
                      }
                    />
                    <label
                      className={`form-check-label mb-2 ${
                        isCanEdit ? "form-check-label-edit" : ""
                      }`}
                    >
                      อนุมัติการยกเลิกการสั่งซื้อ
                    </label>
                  </div>
                )}
                {isHasWaitingOption && (
                  <div>
                    <input
                      className="form-check-input form-check-input-radio"
                      type="radio"
                      disabled={!isCanEdit}
                      checked={is_approve === 0}
                      onClick={(e) =>
                        setApproveOrderCancel({
                          ...approveOrderCancel,
                          is_approve: 0,
                        })
                      }
                    />
                    <label
                      className={`form-check-label mb-2 ${
                        isCanEdit ? "form-check-label-edit" : ""
                      }`}
                    >
                      รอการแก้ไขข้อมูลจากลูกค้า
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            {isFullForm && (
              <div className="col-md-6">
                <label
                  className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
                >
                  สาเหตุปิดงาน
                </label>
                <select
                  className="form-select"
                  disabled={!isCanEdit}
                  value={cs_reason_code}
                  onChange={(e) =>
                    setApproveOrderCancel({
                      ...approveOrderCancel,
                      cs_reason_code: e.target.value,
                    })
                  }
                >
                  <option value={""}>เลือกรายละเอียด</option>
                  {cancellationRefundMaster.reason_list.map((reason) => (
                    <option value={reason.reason_code}>
                      {reason.reason_text}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="row">
            {isFullForm && (
              <div className="col-md-12">
                <label
                  className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
                >
                  รายละเอียดจาก Customer Care
                </label>
                <textarea
                  className="form-control"
                  rows={2}
                  disabled={!isCanEdit}
                  value={cs_remark}
                  onChange={(e) =>
                    setApproveOrderCancel({
                      ...approveOrderCancel,
                      cs_remark: e.target.value,
                    })
                  }
                />
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-5">
              <label
                className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
              >
                ระบบจะทำการชดเชยเงินให้ลูกค้า
              </label>

              <input
                className="form-control"
                type="number"
                disabled={!isCanEdit}
                value={all_member_point_compensation}
                onChange={(e) => {
                  setApproveOrderCancel({
                    ...approveOrderCancel,
                    all_member_point_compensation: +e.target.value + "",
                  });
                }}
              />
              <div className="sub-text">
                ระบุจำนวน ALL Point ที่ชดเชย
              </div>
            </div>
            <div
              className={`col-md-1 align-self-center ${
                isCanEdit ? "white" : ""
              }`}
            >
              แต้ม
            </div>
            <div>
              <input
                className="form-check-input form-check-input-check"
                type="checkbox"
                disabled={!isCanEdit}
                checked={is_status_urgent}
                onChange={(e) =>
                  setApproveOrderCancel({
                    ...approveOrderCancel,
                    is_status_urgent: !is_status_urgent,
                  })
                }
              />
              <label className={`form-check-label ${isCanEdit ? "white" : ""}`}>
                ขอคืนเงินด่วน
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ApproveOrderCancel;
