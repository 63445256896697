const checkNullValue = (value) => {
  return value === null ? "-" : value;
};

export const COLUMNS_DISCOUNT = [
  {
    Header: "เลขที่ใบสั่งซื้อ",
    accessor: "order_no",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.order_no),
  },
  {
    Header: "วันที่สั่งซื้อ",
    accessor: "date",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.date),
  }, 
  {
    Header: "วันที่คืนแต้มลูกค้า",
    accessor: "void_alm_point_date",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.void_alm_point_date),
  }, 
  {
    Header: "เวลาคืนแต้มลูกค้า",
    accessor: "void_alm_point_time",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.void_alm_point_time),
  },
  {
    Header: "วันที่คืน Coins/M-Stamp ลูกค้า",
    accessor: "void_coin_mstamp_date",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.void_coin_mstamp_date),
  },
  {
    Header: "เวลาคืน Coin/M-Stamp ลูกค้า",
    accessor: "void_coin_mstamp_time",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.void_coin_mstamp_time),
  },
  {
    Header: "แต้มที่ลูกค้าใช้ Redeem มาในระบบ",
    accessor: "alm_point_qty",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.alm_point_qty),
  },
  {
    Header: "Coins/M-Stamp ที่ลูกค้าใช้ Redeem มาในระบบ",
    accessor: "coin_mstamp_qty",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.coin_mstamp_qty),
  },
  {
    Header: "แต้มที่บริษัทคืนลูกค้า",
    accessor: "alm_point_qty2",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.alm_point_qty2),
  },
  {
    Header: "สถานะแต้มที่บริษัทคืนลูกค้า",
    accessor: "void_alm_point_status",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.void_alm_point_status),
  },
  {
    Header: "Coins/M-Stamp ที่บริษัทคืนลูกค้า",
    accessor: "coin_mstamp_qty2",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.coin_mstamp_qty2),
  },
  {
    Header: "สถานะ Coins/M-Stamp ที่บริษัทคืนลูกค้า",
    accessor: "void_coin_mstamp_status",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.void_coin_mstamp_status),
  },
  {
    Header: "ORD_SOURCE",
    accessor: "ordersource",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.ordersource),
  },
  {
    Header: "ประเภทการชำระ",
    accessor: "refund_channel",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.refund_channel),
  },
  {
    Header: "PAYMODE (ประเภทชำระย่อย เช่น COD,CPO)",
    accessor: "payment_channel",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.payment_channel),
  },
  {
    Header: "ผู้สั่งซื้อ(ชื่อ-นามสกุล)",
    accessor: "customer_name",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.customer_name),
  },
  {
    Header: "เบอร์โทรศัพท์",
    accessor: "customer_phone",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.customer_phone),
  },
  {
    Header: "รหัส All Member",
    accessor: "member_id",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.member_id),
  },
  {
    Header: "ชื่อสินค้า",
    accessor: "item_name",
    disableSortBy: true,
    Cell: ({ row }) => (
      <p className="grrs-report-item-name">{checkNullValue(row.original?.item_name)}</p>
    )
  },
  {
    Header: "ปริมาณการสั่งซื้อ",
    accessor: "order_qty",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.order_qty),
  },
  {
    Header: "มูลค่าสินค้า",
    accessor: "price_unit",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.price_unit),
  },
  {
    Header: "รวมเป็นเงินทั้งสิ้น",
    accessor: "total_price",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.total_price),
  },
  {
    Header: "ส่วนลด",
    accessor: "general_discount",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.general_discount),
  },
  {
    Header: "สาเหตุ",
    accessor: "reason",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.reason),
  },
  {
    Header: "รายละเอียดสาเหตุ",
    accessor: "reason_remark",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.reason_remark),
  },
  {
    Header: "ชื่อบัญชีลูกค้า",
    accessor: "member_name",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.member_name),
  },
  {
    Header: "วันที่สั่งซื้อ Order",
    accessor: "order_date",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.order_date),
  },
  {
    Header: "เวลาสั่งซื้อ",
    accessor: "order_time",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.order_time),
  },
  {
    Header: "ALL MEMBER TYPE",
    accessor: "member_type",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.member_type),
  },
  {
    Header: "Status คืน",
    accessor: "void_status",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.void_status),
  },
  {
    Header: "Status",
    accessor: "void_result",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.void_result),
  }
];
