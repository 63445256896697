import React from "react";

const SaveModal = ({hendeleAddOperation,invoiceNo}) => {
  return (
    <>
      <div
        className="modal fade"
        id={"save"+invoiceNo}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="header">
              <h4 className="title" id="staticBackdropLabel">
                ยืนยันข้อมูล
              </h4>
            </div>
            <div className="body">
              <h5>โปรดตรวจสอบข้อมูล ก่อนกดยืนยัน</h5>
            </div>
            <div className="footer">
              <button
                type="submit"
                className="resetbutton"
                data-bs-dismiss="modal"
                onClick={hendeleAddOperation}
              >
                บันทึก
              </button>
              <button
                type="button"
                className="backbutton"
                data-bs-dismiss="modal"
              >
                แก้ไข
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveModal;
