import "../../../pages/accounting/Accounting.scss";

const splitDateTime = (date) => {
  if (date !== undefined) {
    return (
      <>
        <p className="no-space">{date.split(" ")[0]}</p>
        <p className="no-space">{date.split(" ")[1]}</p>
      </>
    );
  }
  return <></>;
};

const checkNullValue = (value) => {
  return value === null ? "-" : value;
};

const checkNullDate = (date) => {
  return date === null ? "-" : splitDateTime(date);
};

export const COLUMNS = [
  {
    Header: <p className="grrs-order-no">ลำดับ</p>,
    accessor: "order_no",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => (
      <p className="grrs-order-no">{row.original?.order_no}</p>
    ),
  },
  {
    Header: "ชื่อลูกค้า",
    accessor: "customer_name",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.customer_name),
  },
  {
    Header: "เบอร์ลูกค้า",
    accessor: "customer_phone",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.customer_phone),
  }, {
    Header: "ALL member ID",
    accessor: "member_id",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.member_id),
  },
  {
    Header: "ผู้ดำเนินการ",
    accessor: "create_by",
    disableSortBy: true,
    Cell: ({ row }) => checkNullValue(row.original?.create_by),
  },
  {
    Header: "Created",
    accessor: "create_datetime",
    disableSortBy: true,
    Cell: ({ row }) => checkNullDate(row.original?.create_datetime),
  }
];
