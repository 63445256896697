import React, { useState, useEffect } from "react";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getOrderDetails } from "../../../../service/grrs/contactCenter.service";
import { numberWithCommas } from "../../../../helper/commonHelper";
import "./ContactCenter.scss";
import { CustomerInfoContactCenter } from "../../components/ContactCenter/CustomerInfo";
import LoadingSpinner from "../../components/Share/LoadingSpinner";

function OrderDetail() {
  const [isDisplay, setIsDisplay] = useState(true);
  const [customerDetail, setcustomerDetail] = useState({});
  let history = useHistory();
  const { mainOrderNo, ordersource } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const getOrderDetailFromApi = () => {
    if (mainOrderNo === undefined && ordersource === undefined) {
      history.push("/grrs/contact-center/สร้างคำขอแทนลูกค้า");
      setIsLoading(false);
    } else {
      getOrderDetails(mainOrderNo, ordersource).then((res) => {
        setcustomerDetail(res?.response_data);
        setIsLoading(false);
      });
    }
  };

  const backpage = () => {
    const location = {
      pathname: "/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-list",
    };
    history.push(location, { previous: "index" });
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderDetailFromApi();
  }, []);
  const requestCancel = {
    pathname: `/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-detail/initial`,
    mainOrderNo,
    ordersource,
  };
  const filterValue = (list, type, unit) => {
    if (list !== undefined)
      return numberWithCommas(
        list
          .filter((c) => c.type === type)
          .filter((c) => c.unit === unit)
          .reduce((sum, c) => sum + Number(c.points), 0)
      );
    return 0;
  };
  return (
    <>
      <div className="container">
        <div className="layout">
          <div className="row vh-100">
            <div className="row">
              <ResponsiveSideBar />
              <div className="col-md-9 content">
                <LoadingSpinner isLoading={isLoading} />
                <div className="grrs-row d-flex order-detail">
                  <button className="back-button" onClick={() => backpage()}>
                    <img src="/assets/icons/backicon.svg" alt="back" />
                  </button>
                  <span className="mt-3">
                    <h1>หมายเลขสั่งซื้อ #{mainOrderNo}</h1>
                  </span>
                </div>
                {!isLoading && (
                  <>
                    <CustomerInfoContactCenter
                      customerDetail={customerDetail}
                      isDisplay={isDisplay}
                      setIsDisplay={setIsDisplay}
                    />
                    <div className="body-form-contact-center mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card grrs-contact-center-orderlist orderdetail">
                            <div className="detail-total-order">
                              <span className="fw-bold">
                                {customerDetail?.orders?.ordersource_name}
                              </span>
                              <span className="margin-left">
                                หมายเลขสั่งซื้อ #{mainOrderNo}
                              </span>
                              <span className="margin-left">
                                {customerDetail?.orders?.order_date}
                              </span>
                            </div>
                            <div className="margin-right">
                              <span>รายละเอียดการสั่งซื้อ</span>
                            </div>
                          </div>
                        </div>
                        {customerDetail?.orders?.order_detail_list?.map(
                          (list) => (
                            <div className="col-md-12"  key={list?.item_no}>
                              <div className="card total-order orderdetail">
                                <div className="body-total-order orderdetail">
                                  <div className="col-md-6">
                                    <h3>{list?.item_name}</h3>
                                  </div>
                                  <div className="col-md-1 flex-center">
                                    <h3>{list?.qty}</h3>
                                  </div>
                                  <div className="col-md-2 flex-center">
                                    <h3>
                                      ฿ {numberWithCommas(list?.total_price)}
                                    </h3>
                                  </div>
                                </div>
                                <div className="amount-total-order">
                                  <h3>รหัสสินค้า {list?.item_no} </h3>
                                  <h3 className="color-gray">
                                    รับสินค้าภายใน 3-5 วันทำการ หลังชำระเงิน
                                  </h3>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        <div className="col-md-12">
                          <div className="card net-amount-orderdetail">
                            <div className="row-orderdetail">
                              <div className="height">
                                <div className="padding">
                                  <h3>ราคาขาย</h3>
                                  <h3>
                                    ฿{" "}
                                    {numberWithCommas(
                                      customerDetail?.orders?.total_price
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>ค่าจัดส่ง</h3>
                                  <h3>
                                    ฿{" "}
                                    {numberWithCommas(
                                      customerDetail?.orders?.delivery_fee
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <div className="d-flex">
                                    <h3>ส่วนลด (คูปอง / โปรโมชั่น)</h3>
                                  </div>
                                  <h3>
                                    ฿{" "}
                                    {numberWithCommas(
                                      customerDetail?.orders?.edcm_credit
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3 className="font-size">
                                    ส่วนลด (Normal rate)
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>แลก ALL Point แทนเงินสด</h3>
                                  <h3>
                                    ฿{" "}
                                    {filterValue(
                                      customerDetail?.orders?.redeemedSummary,
                                      "allmemberPoint",
                                      "บาท"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3 className="color-grey">
                                    (คำนวณเป็นแต้ม)
                                  </h3>
                                  <h3 className="color-grey">{filterValue(
                                      customerDetail?.orders?.redeemedSummary,
                                      "allmemberPoint",
                                      "แต้ม"
                                    )} แต้ม</h3>
                                </div>
                                <div className="padding">
                                  <h3>แลก M-Stamp / เหรียญ แทนเงินสด</h3>
                                  <h3>
                                    ฿{" "}
                                    {filterValue(
                                      customerDetail?.orders?.redeemedSummary,
                                      "mstamp",
                                      "บาท"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3 className="color-grey">(คำนวณเป็นแต้ม)</h3>
                                  <h3 className="color-grey">{filterValue(
                                      customerDetail?.orders?.redeemedSummary,
                                      "mstamp",
                                      "แต้ม"
                                    )} แต้ม</h3>
                                </div>
                                <div className="padding">
                                  <h3 className="font-size">
                                    ส่วนลดพิเศษ (Special rate)
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>ส่วนลดพิเศษ (ALL Point แลกโปรโมชั่น)</h3>
                                  <h3>
                                    ฿{" "}
                                    {filterValue(
                                      customerDetail?.orders
                                        ?.specialRedeemedSummary,
                                      "allmemberPoint",
                                      "บาท"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding ">
                                  <h3 className="color-grey">
                                    (คำนวณเป็นแต้ม)
                                  </h3>
                                  <h3 className="color-grey">{filterValue(
                                      customerDetail?.orders
                                        ?.specialRedeemedSummary,
                                      "allmemberPoint",
                                      "แต้ม"
                                    )} แต้ม</h3>
                                </div>
                                <div className="padding">
                                  <h3>
                                    ส่วนลดพิเศษ (M-Stamp / เหรียญ แลกโปรโมชั่น)
                                  </h3>
                                  <h3>
                                    ฿{" "}
                                    {filterValue(
                                      customerDetail?.orders
                                        ?.specialRedeemedSummary,
                                      "mstamp",
                                      "บาท"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3 className="color-grey">(คำนวณเป็นแต้ม)</h3>
                                  <h3 className="color-grey">{filterValue(
                                      customerDetail?.orders
                                        ?.specialRedeemedSummary,
                                      "mstamp",
                                      "แต้ม"
                                    )} แต้ม</h3>
                                </div>
                              </div>
                            </div>
                            <div className="row-orderdetail border-left">
                              <div className="height">
                                <div className="padding">
                                  <h3 className="font-size">
                                    ยอดสุทธิ (รวมภาษีมูลค่าเพิ่ม)
                                  </h3>
                                  <h3>
                                    ฿{" "}
                                    {numberWithCommas(
                                      customerDetail?.orders?.invoice_value
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>รับ ALL Point (แต้ม)</h3>
                                  <h3>
                                    {filterValue(
                                      customerDetail?.orders?.earnPoints,
                                      "allmemberPoint",
                                      "แต้ม"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding ">
                                  <h3 className="color-grey">
                                    (ได้รับแต้มเมื่อชำระเงินสำเร็จ)
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>รับ M-Stamp / เหรียญ (บาท)</h3>
                                  <h3>
                                  ฿ {filterValue(
                                      customerDetail?.orders?.earnPoints,
                                      "mstamp",
                                      "บาท"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3 className="color-grey">
                                    (ได้รับแต้มเมื่อชำระเงินสำเร็จ)
                                  </h3>
                                </div>

                                <div className="padding">
                                  <h3 className="font-size">
                                    ยอดสะสม ณ ปัจจุบัน
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>All Point (แต้ม)</h3>
                                  <h3>
                                  {filterValue(
                                      customerDetail?.orders?.rewardPointsBalance,
                                      "allmemberPoint",
                                      "แต้ม"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>M-Stamp / เหรียญ (บาท)</h3>
                                  <h3>
                                  ฿ {filterValue(
                                      customerDetail?.orders?.rewardPointsBalance,
                                      "mstamp",
                                      "บาท"
                                    )}
                                  </h3>
                                </div>

                                <div className="padding">
                                  <h3 className="font-size">
                                    ยอดที่ต้องเรียกคืน
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>All Point (แต้ม)</h3>
                                  <h3>
                                  {filterValue(
                                      customerDetail?.orders?.voidRewardPoints,
                                      "allmemberPoint",
                                      "แต้ม"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>M-Stamp / เหรียญ (บาท)</h3>
                                  <h3>
                                  ฿ {filterValue(
                                      customerDetail?.orders?.voidRewardPoints,
                                      "mstamp",
                                      "บาท"
                                    )}
                                  </h3>
                                </div>

                                <div className="padding">
                                  <h3 className="font-size">
                                    ยอดที่ขาดไป
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>All Point (แต้ม)</h3>
                                  <h3 className="red">
                                  {filterValue(
                                      customerDetail?.orders?.diffVoidRewardPoints,
                                      "allmemberPoint",
                                      "แต้ม"
                                    )}
                                  </h3>
                                </div>
                                <div className="padding">
                                  <h3>M-Stamp / เหรียญ (บาท)</h3>
                                  <h3 className="red">
                                  ฿ {filterValue(
                                      customerDetail?.orders?.diffVoidRewardPoints,
                                      "mstamp",
                                      "บาท"
                                    )}
                                  </h3>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 section-button mt-5 mb-5">
                        <button
                          className="back-button grrs-white-button"
                          onClick={() => backpage()}
                        >
                          ย้อนกลับ
                        </button>
                        <Link to={requestCancel}>
                          <button
                            className={
                              !customerDetail?.orders?.request_status.includes("NOT_REQUESTABLE")
                                ? "cancel-order-button grrs-red-button"
                                : "d-none"
                            }
                          >
                            ยกเลิกการสั่งซื้อ
                          </button>
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetail;
