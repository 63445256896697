import { createContext, useState } from "react";

const MenuContext = createContext({});
export const MenuContextProvider = ({ children, profileUser, permission }) => {

  const [menu, setMenu] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [activeId, setActiveId] = useState("");
  const [backPage, setBackPage] = useState("");
  const [backSearch, setBackSearch] = useState("");
  const storeMenu = {
    menu,
    setMenu,
    profileUser,
    userRole,
    setUserRole,
    permission,
    activeId,
    setActiveId,
    backPage,
    setBackPage,
    backSearch,
    setBackSearch,
  };

  return (
    <MenuContext.Provider value={storeMenu}>{children}</MenuContext.Provider>
  );
};
export default MenuContext;
