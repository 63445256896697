import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./VoidFailedModal.scss";

function VoidFailedModal({
  voidFailedModalState,
  setVoidFailedModalState,
  showDataRetryFailModal,
}) {
  const history = useHistory(); // ใช้ hook เพื่อเข้าถึง history
  const urlPath = "/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-list";
  const inputDate = showDataRetryFailModal?.response_data?.date;

  const handleModalDisplay = () => {
    const modalElement = document.getElementById("voidFailedModal");
    if (modalElement) {
      const modal = new window.bootstrap.Modal(modalElement);
      modal.show();
    }
  }

  const handleCloseModal = () => {
    setVoidFailedModalState(false); 
    history.push(urlPath); 
  };

  const formatDate = (inputDate) => {
    if (typeof inputDate !== 'string' || inputDate.trim() === '') {
        return 'รูปแบบวันที่ไม่ถูกต้อง';
    }
    const regex = /(\d{2})-(\d{2})-(\d{4})T(\d{2}):(\d{2}):(\d{2}) (หลังเที่ยง|ก่อนเที่ยง)/;
    const matches = inputDate.match(regex);
    if (!matches) {
        return 'รูปแบบวันที่ไม่ถูกต้อง';
    }
    const day = matches[1];
    const month = matches[2];
    const year = matches[3];
    let hour = parseInt(matches[4]);
    const minute = matches[5];
    const second = matches[6];
    const period = matches[7] === 'หลังเที่ยง' ? 'PM' : 'AM';
    if (hour >= 13) {
        hour -= 12; 
    } else if (hour === 0) {
        hour = 12; 
    }
    const formattedDate = `${day}.${month}.${year} เวลา ${String(hour).padStart(2, '0')}:${minute}:${second} ${period}`;
    return formattedDate;
};
const newDate = formatDate(inputDate);

  useEffect(() => {
    if (voidFailedModalState === true) {
      handleModalDisplay();
    }
  }, [voidFailedModalState]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary void-failed-modal-button"
        id="void-failed-modal-button"
        data-bs-toggle="modal"
        data-bs-target="#voidFailedModalMethod"
        hidden
      >
        ตกลง
      </button>
      <div className="voidFailedModal">
        <div
          className="modal fade"
          id="voidFailedModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="voidFailedModalMethodLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
              <div className="row">
              <h1 className="modal-title centered-text" id="voidFailedModalMethodLabel" >
                <div className="centered-text-content">
                  รายละเอียดเพื่อส่งข้อมูลไปรายงาน Void ไม่สำเร็จ
                </div>
              </h1>

              <div className="border-top"></div>
                <div className="row">
                
                  <div className="col-md-6">
                    <div className="padding">
                      <h3>หมายเลขออเดอร์</h3>
                    </div>
                  </div>
                
                  <div className="col-md-6">
                  <div className="padding"></div>
                    <div className="padding">
                      <h3 className="font-size">
                        {showDataRetryFailModal?.response_data?.order_no}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="border-top"></div>
                <div className="row">
                
                  <div className="col-md-6">
                    <div className="padding">
                      <h3>Error Code</h3>
                    </div>
                  </div>
                
                  <div className="col-md-6">
                  <div className="padding"></div>
                    <div className="padding">
                      <h3 className="font-size">
                        {showDataRetryFailModal?.response_code}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="border-top"></div>
                <div className="row">
                
                  <div className="col-md-6">
                    <div className="padding">
                      <h3>วันที่-เวลา</h3>
                    </div>
                  </div>
                
                  <div className="col-md-6">
                  <div className="padding"></div>
                    <div className="padding">
                      <h3 className="font-size">
                        {newDate}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="border-top"></div>
                <div className="row">
                
                  <div className="col-md-6">
                    <div className="padding">
                      <h3>รายละเอียดที่ Void ไม่ได้</h3>
                    </div>
                  </div>
                
                  <div className="col-md-6">
                  <div className="padding"></div>
                    <div className="padding">
                      <h3 className="font-size fail-message">
                        {showDataRetryFailModal?.response_data?.message}
                      </h3>
                    </div>
                  </div>
                </div>
                
                <div className="row btn-section">
                  <div className="col-6 text-center">
                    <button
                      type="button"
                      className="btn grrs-white-button void-failed-edit-btn"
                      data-bs-dismiss="modal"
                      onClick={handleCloseModal}
                    >
                      ปิด
                    </button>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VoidFailedModal;
