import React, { useEffect, useState } from "react";
import { useTable, useFilters, usePagination, useSortBy, useRowSelect } from "react-table";
import { useLocation } from "react-router-dom";
import { putUpdateVoidStatus } from "../../../../../service/grrs/report.service";
import '../../../../../scss/common/utils.scss';
import Pagination from "../../Share/Pagination";
import EditStatus from "../Popup/EditStatus";
import Result from "../../../components/Accounting/Popup/Result";

function TableTab({
  data,
  columns,
  defaultColumn,
  setIsNoData,
  isNoData,
  parentPage,
  handleRefundBtn = () => { },
  permission = [],
  isCanEditStatus = true,
  isCashMode = null,
  isCanSearch = true
}) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    rows,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    defaultColumn,
    disableSortRemove: true,
    initialState:
    {
      pageIndex: 0,
      pageSize: 20,
      hiddenColumns: columns
        .filter(col => col.show === false)
        .map(col => col.accessor)
    },
  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
        hooks.visibleColumns.push(columns => [
          ...columns,
          {
            id: 'edit',
            disableSortBy: true,
            Cell: ({ row }) => {
              const location = useLocation();
              const pathName = location.pathname;

              const [displayResultModal, setDisplayResultModal] = useState(false);

              const isDisableBtn = () => {
                return isCanEditStatus && row.original?.is_header && !row.original?.is_complete
              }

              const handleSubmit = () => {
                let data = {request_id: row.original?.request_id, void_alm_type: parentPage === "reportVoidAutoFailReturn" ? "ISSUE" : "DEDUCT"};
                putUpdateVoidStatus(data).then(res => {
                    if (res.data.response_code === 100) {
                        window.location.reload(); 
                    } else {
                        setDisplayResultModal(true);
                    }
                    document.getElementById(`grrs-edit-status-confirm${row.original?.request_id}`).disabled = false;
                });
              }

              return (
                isDisableBtn() && 
                  <>
                    <img 
                      id="edit-status"
                      className="icon-edit"
                      src="/assets/grrs/edit-icon.svg" 
                      onClick={() => document.getElementById(`edit-status-btn${row.original?.request_id}`).click()}
                      alt="excel"
                    />
                    <EditStatus
                      requestId={row.original?.request_id}
                      submitFunction={handleSubmit}
                    />   

                    <Result
                      resultIsSuccess={false}
                      pathName={pathName}
                      setDisplayResultModal={setDisplayResultModal}
                      displayResultModal={displayResultModal}
                      requestId={row.original?.request_id}
                    />               
                  </>
              )  
            }     
          },
        ])
      }  
  )

  useEffect(() => {
    if (rows.length === 0) {
      setIsNoData(true);
    } else setIsNoData(false);
  })

  return (
    <>
      <div className={isNoData ? "no-data report-fail" : "d-none"}>{isCanSearch ? "ไม่พบข้อมูล": "คุณไม่มีสิทธิ์เข้าถึงข้อมูล"}</div>  
      <div className="space-tab">
        <div className={isNoData ? "table-common table-tab empty-table" : "table-common table-tab"}>
          <table className="table-primary" {...getTableProps()}>
            <thead className="table-header">
              {headerGroups.map((headerGroup) => (
                <>
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                    {headerGroup.headers.map(column => (
                      <th className="grrs"
                        {...column.getHeaderProps()}
                        key={column.id}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                    {headerGroup.headers.map(column => (
                      <th className="grrs-filter" {...column.getHeaderProps()} key={column.id}>{column.canFilter ? column.render("Filter") : null}</th>
                    ))}
                  </tr>
                </>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}
                    className={index % 2 !== 0 ? "bg-white" : ""}
                    key={row.original?.request_id}
                  >
                    {row.cells.map((cell) => {
                      return <td className="download-file-cells" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        isNoData={isNoData}
        parentPage={parentPage}
        permission={permission}
        setPageSize={setPageSize}
        pageSize={pageSize}
        rows={rows}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        selectedFlatRows={selectedFlatRows}
        handleRefundBtn={handleRefundBtn}
        isCashMode={isCashMode}
      />
      <input id="selectedRows" type="hidden" value={selectedFlatRows.map(row => row.original?.customer_request_no)} />
    </>
  );
}

export default TableTab;