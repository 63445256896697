import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import MenuContext from "../../../context/MenuContext";

const UserMobile = ({userRole}) => {
    const { profileUser } = useContext(MenuContext);

    return (
        <div className="auth">
            <Image id="user-icon-menu"
                src={
                    process.env.PUBLIC_URL +
                    "/assets/icons/user-icon.svg"
                }
                rounded
            />
            <div className="user" id="menu-user-data">
                <div>
                    <div className="d-flex">
                        <h4 id="data-user-text">{profileUser?.name}</h4>
                    </div>
                    <h4 id="data-menu-small-text" >{userRole}</h4>
                </div>
            </div>
        </div>
    );
};

export default UserMobile;
