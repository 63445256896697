import React, { useEffect } from "react";
import "./InsufficientBalanceModal.scss";
import { numberWithCommas } from "../../../../../helper/commonHelper";

function InsufficientBalanceModal({
  postCancellationData,
  insufficientBalanceModalState,
}) {
  const filterValue = (list, type, unit) => {
    if (Array.isArray(list)) {
      return numberWithCommas(
        list
          .filter((c) => c.type === type)
          .filter((c) => c.unit === unit)
          .reduce((sum, c) => sum + Number(c.points), 0)
      );
    }
    return 0;
  };
  
   

  function handleModalDisplay() {
    const modalElement = document.getElementById("insufficientBalance");
    if (modalElement) {
      const modal = new window.bootstrap.Modal(modalElement);
      modal.show();
    }
  }

  useEffect(() => {
    if (insufficientBalanceModalState === true) {
      handleModalDisplay();
    }
  }, [insufficientBalanceModalState]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary insufficient-Balance-modal-button"
        id="insufficient-balance-modal-button"
        data-bs-toggle="modal"
        data-bs-target="#insufficientBalance"
        hidden
      >
        ตกลง
      </button>
      <div className="insufficientBalanceModal">
        <div
          className="modal fade"
          id="insufficientBalance"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="insufficientBalanceLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
              <h1 className="modal-title centered-text" id="insufficientBalanceLabel">
                <div className="centered-text-content">
                  คุณมี All Point / M-Stamp / เหรียญ (บาท) <br />
                  ไม่เพียงพอ กรุณาตรวจสอบอีกครั้ง
                </div>
              </h1>
                <button
                  type="button"
                  id="instufficient-balance-close-btn"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  hidden
                ></button>
              </div>
              <div className="modal-body">

              <div className="border-top"></div>
                <div className="row">
                <div className="padding">
                      <h3 className="font-size">ยอดสะสม ณ ปัจจุบัน</h3>
                    </div>
               
                  <div className="col-md-6">
                    <div className="padding">
                      <h3>All Point (แต้ม)</h3>
                    </div>
                    <div className="padding">
                      <h3>M-Stamp / เหรียญ (บาท)</h3>
                    </div>
                  </div>
               
                  <div className="col-md-6">
                  <div className="padding"></div>
                    <div className="padding">
                      <h3 className="font-size font-point-allmember" >
                        {filterValue(
                          postCancellationData?.rewardPointsBalance,
                          "allmemberPoint",
                          "แต้ม"
                          )}
                      </h3>
                    </div>
                    <div className="padding">
                      <h3 className="font-size font-point-allmember" >
                        ฿ {filterValue(
                          postCancellationData?.rewardPointsBalance,
                          "mstamp",
                          "บาท"
                          )}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="border-top"></div>
                <div className="row">
                <div className="padding">
                      <h3 className="font-size">ยอดที่ต้องเรียกคืน</h3>
                    </div>
                
                  <div className="col-md-6">
                    <div className="padding">
                      <h3>All Point (แต้ม)</h3>
                    </div>
                    <div className="padding">
                      <h3>M-Stamp / เหรียญ (บาท)</h3>
                    </div>
                  </div>
                
                  <div className="col-md-6">
                  <div className="padding"></div>
                    <div className="padding">
                      <h3 className="font-size font-point-allmember" >
                      {filterValue(
                          postCancellationData?.voidRewardPoints,
                          "allmemberPoint",
                          "แต้ม"
                          )}
                      </h3>
                    </div>
                    <div className="padding">
                      <h3 className="font-size font-point-allmember" >
                      ฿ {filterValue(
                          postCancellationData?.voidRewardPoints,
                          "mstamp",
                          "บาท"
                          )}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="border-top"></div>
                <div className="row">
                <div className="padding">
                      <h3 className="font-size">ยอดที่ขาดไป</h3>
                    </div>
                
                  <div className="col-md-6">
                    <div className="padding">
                      <h3>All Point (แต้ม)</h3>
                    </div>
                    <div className="padding">
                      <h3>M-Stamp / เหรียญ (บาท)</h3>
                    </div>
                  </div>
                
                  <div className="col-md-6">
                  <div className="padding"></div>
                    <div className="padding">
                    <h3 className="font-size font-point-allmember text-red" >
                      {filterValue(
                          postCancellationData?.diffVoidRewardPoints,
                          "allmemberPoint",
                          "แต้ม"
                          )}
                      </h3>
                    </div>
                    <div className="padding">
                      <h3 className="font-size font-point-allmember text-red" >
                      ฿ {filterValue(
                          postCancellationData?.diffVoidRewardPoints,
                          "mstamp",
                          "บาท"
                          )}
                      </h3>
                    </div>
                  </div>
                </div>

                
                <div className="row justify-content-center btn-section">
                  <div className="col-6 text-center">
                    <button
                      type="button"
                      className="btn grrs-white-button instufficient-balance-edit-btn text-bold"
                      data-bs-dismiss="modal"
                    >
                      ปิด
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InsufficientBalanceModal;
