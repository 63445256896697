import React, { useEffect, useState, useMemo, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import "./Accounting.scss";
import { postCancellationReqCashRefund, postCancellationReqOnlineRefund, exportCancellationReqCashRefund, exportCancellationReqOnlineRefund, downloadCancelReqImpRefundFile } from "../../../../service/grrs/accounting.service";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import FormSearch from "../../components/Share/FormSearch";
import Upload from "../../components/Accounting/Popup/Upload";
import UploadSummary from "../../components/Accounting/Popup/UploadSummary";
import CheckRefund from "../../components/Accounting/Popup/CheckRefund";
import Result from "../../components/Accounting/Popup/Result";
import Table from "../../components/Accounting/Table";
import { COLUMNS_APPROVE_REFUND } from "../../components/Accounting/CreateFile/ColumnFilter/columns";
import { ColumnFilter } from "../../components/Accounting/CreateFile/ColumnFilter/ColumnFilter";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import PermissionModal from "../../../common/components/PermissionModal";

const initialData = {
  customer_request_no: null,
  order_no: null,
  request_refund_status_code: null,
  refund_channel_code: null,
  status_urgent_code: null,
  ecn_status_code: null,
  wh_approve_status_code: null,
  request_date_to: null,
  request_date_from: null,
  refund_date_to: null,
  refund_date_from: null
};

function AccountingApproveRefund() {
  const FileDownload = require("js-file-download");

  const { permission } = useContext(MenuContext);
  const [rawData, setRawData] = useState([]);
  const [currentUploadFile, setCurrentUploadFile] = useState({});
  const [isNoData, setIsNoData] = useState(false);
  const [uploadResult, setUploadResult] = useState({});
  const [selectedBtnType, setSelectedBtnType] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [resultIsSuccess, setResultIsSuccess] = useState(false);
  const [displayResultModal, setDisplayResultModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState(initialData);
  const [isCashMode, setIsCashMode] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [approveResponse, setApproveResponse] = useState({});
  const columns = useMemo(() => COLUMNS_APPROVE_REFUND, []);

  const location = useLocation();
  const pathName = location.pathname;

  const isCanSearch = permission.some((res) => res === "search");
  const isCanUpdate = permission.some((res) => res === "update");

  let history = useHistory();
  const redirectToRequestCancel = (row, cell) => {
    if (cell.key.includes("selection") === false) {
      if (isCashMode) {
        history.push(`/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินสด/request-cancel/${row.original.order_no}&${row.original.ordersource}&${row.original.customer_request_no}`);
      } else {
        history.push(`/grrs/accounting-return/ยืนยันผลการคืนเงิน/ยืนยันการคืนเงินออนไลน์/request-cancel/${row.original.order_no}&${row.original.ordersource}&${row.original.customer_request_no}`);
      }
    }
  };

  const checkPaymentType = () => {
    if (pathName.includes("ยืนยันการคืนเงินสด") === true) {
      setIsCashMode(true);
      return true;
    } else if (pathName.includes("ยืนยันการคืนเงินออนไลน์") === true) {
      setIsCashMode(false);
      return false;
    }
  }

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter
    }
  }, []);

  const handleRefundBtn = (e) => {
    const getSelectedRows = document.getElementById("selectedRows").value;
    const selectedRows = getSelectedRows.split(",");
    if (selectedRows.length > 0 && selectedRows[0] !== '') {
      setSelectedData(selectedRows);
    } else setSelectedData([]);
    setSelectedBtnType(e.target.name);
    document.getElementById("check-refund-btn").click();
  }

  const checkResponseCode = (responseCode) => {
    if (responseCode === 100) {
      setResultIsSuccess(true);
    } else {
      setResultIsSuccess(false);
    }
    setDisplayResultModal(true);
  }

  const handleApproveResponse = (response) => {
    if (response.response_code === 100) {
      setResultIsSuccess(true);
    } else {
      setResultIsSuccess(false);
    }
    const isAlreadySetResponse = handleSetApproveResponse(response);
    if (isAlreadySetResponse === true) {
      setDisplayResultModal(true);
    }
  }

  const handleSetApproveResponse = (response) => {
    setApproveResponse({
      response_code: response?.response_code,
      response_data: response?.response_data
    });
    return true;
  }

  const handleExportFile = () => {
    setIsButtonLoading(true);
    if (isCashMode) {
      exportCancellationReqCashRefund(exportData).then(res => {
        exportFile(res);
      });
    } else if (!isCashMode) {
      exportCancellationReqOnlineRefund(exportData).then(res => {
        exportFile(res);
      });
    }
  }

  const exportFile = (res, type = "") => {
    const contentDisposition = res?.headers["content-disposition"];
    if (type === "downloadErrorFile" && res !== null && contentDisposition !== undefined) {
      const filename = contentDisposition.split("filename*=utf-8''")[1].trim();
      FileDownload(res.data, filename);
    } else if (res !== null && contentDisposition !== undefined) {
      const filename = contentDisposition.split("filename=")[1].trim();
      FileDownload(res.data, filename);
    }
    setIsButtonLoading(false);
  }

  const displayUploadModal = () => {
    setCurrentUploadFile({});
    setSelectedBtnType("upload");
    document.getElementById("upload-file-btn").click();
  }

  const uploadFileResponse = (response) => {
    document.getElementById("upload-modal-close-btn").click();
    if (response.response_code === 100) {
      setUploadResult(response);
      document.getElementById("upload-summary-btn").click();
    } else {
      document.getElementById("upload-summary-modal-close-btn").click();
      setSelectedBtnType("uploadFile");
      setResultIsSuccess(false);
      setDisplayResultModal(true);
    }
  }

  const handleReUpload = () => {
    downloadCancelReqImpRefundFile(parseInt(uploadResult.response_data?.import_fail_file_id)).then(res => {
      exportFile(res, "downloadErrorFile");
      document.getElementById("upload-summary-modal-close-btn").click();
      displayUploadModal();
    });
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      if (checkPaymentType()) {
        postCancellationReqCashRefund(initialData).then(res => {
          if (res?.data === undefined) {
            setRawData([]);
            setIsNoData(true);
          } else {
            setRawData(res.data);
          }
          setIsLoading(false);
        });
      } else if (!checkPaymentType()) {
        postCancellationReqOnlineRefund(initialData).then(res => {
          if (res?.data === undefined) {
            setRawData([]);
            setIsNoData(true);
          } else {
            setRawData(res.data);
          }
          setIsLoading(false);
        });
      }
      if (location?.state?.response !== undefined) {
        handleApproveResponse(location?.state?.response);
        setSelectedBtnType(location?.state?.selectedBtnType);
        window.history.replaceState({}, document.title);
      }
    }, 500);
  }, [window.location.href])

  return (
    <>
      <Container>
        <div className="layout">
          <div className="row vh-100">
            <Row>
              <ResponsiveSideBar />
              <Col md={9} className="grrs-accounting-content">
                <div className="grrs-accounting-container space-bottom">
                  <LoadingSpinner isLoading={isLoading} />
                  <div className="row align-items-center">
                    <h1 className="col-8 grrs-accounting-title">ยืนยันผลการคืนเงิน{isCashMode ? "สด" : "ออนไลน์"}</h1>
                    {
                      !isCashMode && (
                        <div className="row align-items-center space-bottom">
                          <button className="grrs-white-button accounting-export-btn col-6 col-sm-5 col-md-5 col-lg-4 col-xl-3 col-xxl-3" onClick={() => handleExportFile()} hidden={!isCanSearch}>
                            <img className="icon-excel" src="/assets/icons/excel-app.svg"></img>
                            <span className="font-size">Export To Excel</span>
                            <span className="spinner-border spinner-border-xl button-spinner" role="status" aria-hidden="true" hidden={!isButtonLoading}></span>
                          </button>
                          {
                            isCanSearch &&
                            <FormSearch
                              setSearchData={setRawData}
                              setExportData={setExportData}
                              setIsLoading={setIsLoading}
                              parentPage={"approveRefundonline"}
                            />
                          }
                        </div>
                      )
                    }
                  </div>
                  {
                    isCashMode && (
                      <div className="row align-items-center space-bottom">
                        <button className="grrs-white-button accounting-export-btn col-6 col-sm-5 col-md-5 col-lg-4 col-xl-3 col-xxl-3" onClick={() => handleExportFile()} hidden={!isCanSearch}>
                          <img className="icon-excel" src="/assets/icons/excel-app.svg"></img>
                          <span className="font-size">Export To Excel</span>
                          <span className="spinner-border spinner-border-xl button-spinner" role="status" aria-hidden="true" hidden={!isButtonLoading}></span>
                        </button>
                        <button className="grrs-white-button accounting-import-btn col-6 col-sm-5 col-md-5 col-lg-4 col-xl-3 col-xxl-3" onClick={() => displayUploadModal()} hidden={!isCanUpdate}>
                          <img className="icon-excel" src="/assets/icons/import.svg"></img>
                          <span className="font-size">Import Results</span>
                        </button>
                        {
                          isCanSearch &&
                          <FormSearch
                            setSearchData={setRawData}
                            setExportData={setExportData}
                            setIsLoading={setIsLoading}
                            parentPage={"approveRefundcash"}
                          />
                        }
                      </div>
                    )
                  }
                  {
                    !isLoading &&
                    permission?.length !== 0 &&
                    (
                      <div className="row align-items-center">
                        <Table
                          data={isCanSearch ? rawData : []}
                          columns={columns}
                          defaultColumn={defaultColumn}
                          setIsNoData={setIsNoData}
                          isNoData={isNoData}
                          redirectFunction={redirectToRequestCancel}
                          parentPage={"approveRefund"}
                          handleRefundBtn={handleRefundBtn}
                          enabledStatus={"ดึงข้อมูลทำเรื่องคืนเงินแล้ว"}
                          permission={permission}
                          isCanCreateOrUpdate={isCanUpdate}
                          isCashMode={isCashMode}
                          isCanSearch={isCanSearch}
                        />
                      </div>
                    )
                  }
                </div>
                <Upload
                  uploadFileResponse={uploadFileResponse}
                  setCurrentUploadFile={setCurrentUploadFile}
                  currentUploadFile={currentUploadFile}
                  uploadResult={uploadResult}
                  exportFile={exportFile}
                />
                <UploadSummary
                  uploadResult={uploadResult}
                  checkResponseCode={checkResponseCode}
                  currentUploadFile={currentUploadFile}
                  handleReUpload={handleReUpload}
                />
                <CheckRefund
                  selectedData={selectedData}
                  selectedBtnType={selectedBtnType}
                  checkResponseCode={checkResponseCode}
                  handleApproveResponse={handleApproveResponse}
                />
                <Result
                  resultIsSuccess={resultIsSuccess}
                  selectedBtnType={selectedBtnType}
                  pathName={pathName}
                  setDisplayResultModal={setDisplayResultModal}
                  displayResultModal={displayResultModal}
                  approveResponse={approveResponse}
                />
              </Col>
            </Row>
          </div>
        </div>
        <PermissionModal permission={permission} backPage={"/grrs"} />
      </Container>
    </>
  );
}

export default AccountingApproveRefund;