import React, { useEffect, useState, useMemo, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Accounting.scss";
import { postVoidSuccessReport, exportVoidStatusReport } from "../../../../service/grrs/report.service";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import FormSearch from "../../components/Share/FormSearch";
import Table from "../../components/Accounting/Table";
import { ColumnFilter } from "../../components/Accounting/CreateFile/ColumnFilter/ColumnFilter";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import PermissionModal from "../../../common/components/PermissionModal";
import { COLUMNS } from "../../components/Accounting/Report/columnsVoidAutoSuccess";

const initialData = {
  request_type_code: null,
  reason_code: null,
  refund_channel_code: null,
  request_refund_status_code: null,
  account_no: null,
  phone_no: null,
  product_no: null,
  order_no: null,
  order_date_to: null,
  order_date_from: null,
};

function AccountingReportVoidAutoSuccess() {
  const FileDownload = require("js-file-download");

  const { permission } = useContext(MenuContext);
  const [rawData, setRawData] = useState([]);
  const [isNoData, setIsNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState(initialData);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const columns = useMemo(() => COLUMNS, []);

  const isCanSearch = permission.some((res) => res === "search");
  const isCanDownload = permission.some((res) => res === "download");

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter
    }
  }, []);

  const handleExportFile = () => {
    exportData["void_success"] = 1;
    setIsButtonLoading(true);
    exportVoidStatusReport(exportData).then((res) => {
      const contentDisposition = res?.headers["content-disposition"];
      if (res !== null && contentDisposition !== undefined) {
        const filename = contentDisposition.split("filename*=utf-8''")[1].trim();
        FileDownload(res.data, decodeURI(filename));
      }
      setIsButtonLoading(false);
    });
  }

  const handleOrderNo = (rawData) => {
    const tempData = rawData.map((list, index) => {
        return {
            ...list,
            order_no: index += 1
        }
    })
    return tempData;
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      postVoidSuccessReport(initialData).then(res => {
        if (res?.data === undefined) {
          setRawData([]);
          setIsNoData(true);
        } else {
          setRawData(handleOrderNo(res?.data));
          setIsNoData(false);
        }
        setIsLoading(false);
      });
    }, 500);
  }, [])

  return (
    <>
      <Container>
        <div className="layout">
          <div className="row vh-100">
            <Row>
              <ResponsiveSideBar />
              <Col md={9} className="grrs-accounting-content">
                <div className="grrs-accounting-container space-bottom">
                  <LoadingSpinner isLoading={isLoading} />
                  <h1 className="grrs-accounting-title">ข้อมูลยกเลิกภายในวันคืนสิทธิ แต้ม, coin, M-Stamp สำเร็จ</h1>
                  <div className="row align-items-center space-bottom">
                  {
                    isCanDownload &&
                    <button className="grrs-white-button accounting-export-btn" onClick={() => handleExportFile()} hidden={!isCanSearch}>
                      <img className="icon-excel" src="/assets/icons/excel-app.svg" alt="excel"></img>
                      <span className="font-size">Export To Excel</span>
                      <span className="spinner-border spinner-border-xl button-spinner" role="status" aria-hidden="true" hidden={!isButtonLoading}></span>
                    </button>
                  }      
                    {
                      isCanSearch &&
                      <FormSearch
                        setSearchData={setRawData}
                        setExportData={setExportData}
                        setIsLoading={setIsLoading}
                        parentPage="createFileVoidSuccess"
                      />
                    }
                  </div>
                  {
                    !isLoading &&
                    permission?.length !== 0 &&
                    (
                      <div className="row align-items-center">
                        <Table
                          data={isCanSearch ? rawData : []}
                          columns={columns}
                          defaultColumn={defaultColumn}
                          setIsNoData={setIsNoData}
                          isNoData={isNoData}
                          parentPage="reportVoidAutoSuccess"
                          permission={permission}
                          isCanSearch={isCanSearch}
                        />
                      </div>
                    )
                  }
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <PermissionModal permission={permission} backPage={"/grrs"} />
      </Container>
    </>
  );
}

export default AccountingReportVoidAutoSuccess;