import React, { useEffect, useState, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import "./Accounting.scss";
import { postCancellationRequestRefund, postExportCancellationReqRefund, exportCancellationGenRefundFile } from "../../../../service/grrs/accounting.service";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import FormSearch from "../../components/Share/FormSearch";
import PopupGenerate from "../../components/Accounting/Popup/Generate";
import Table from "../../components/Accounting/Table";
import { COLUMNS_CREATE_FILE } from "../../components/Accounting/CreateFile/ColumnFilter/columns";
import { ColumnFilter } from "../../components/Accounting/CreateFile/ColumnFilter/ColumnFilter";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import PermissionModal from "../../../common/components/PermissionModal";

const initialData = {
  customer_request_no: null,
  order_no: null,
  request_refund_status_code: null,
  refund_channel_code: null,
  status_urgent_code: null,
  ecn_status_code: null,
  wh_approve_status_code: null,
  request_date_to: null,
  request_date_from: null,
  refund_date_to: null,
  refund_date_from: null
};

function Accounting() {
  const FileDownload = require("js-file-download");

  const { permission } = useContext(MenuContext);
  const [rawData, setRawData] = useState([]);
  const [isNoData, setIsNoData] = useState(false);
  const [generateFile, setGenerateFile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState(initialData);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const columns = useMemo(() => COLUMNS_CREATE_FILE, []);

  const isCanSearch = permission.some((res) => res === "search");
  const isCanCreate = permission.some((res) => res === "create");

  let history = useHistory();
  const redirectToRequestCancel = (row, cell) => {
    if (cell.key.includes("selection") === false && cell.key.includes("etax_no") === false && cell.key.includes("ecn_no") === false) {
      history.push(`/grrs/accounting-return/create-file/request-cancel-view/${row.original.order_no}&${row.original.ordersource}&${row.original.customer_request_no}`);
    }
  };

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter
    }
  }, []);

  const handleExportFile = () => {
    setIsButtonLoading(true);
    postExportCancellationReqRefund(exportData).then((res) => {
      const contentDisposition = res?.headers["content-disposition"];
      if (res !== null && contentDisposition !== undefined) {
        const filename = contentDisposition.split("filename=")[1].trim();
        FileDownload(res.data, filename);
      }
      setIsButtonLoading(false);
    });
  }

  const handleCreateFile = () => {
    const getSelectedData = document.getElementById("selectedRows").value;
    const selectedData = getSelectedData.split(",");
    const count = allEnabledRows();
    let createFileData = {};
    if (selectedData[0] !== "" && selectedData.length > 0) {
      if (selectedData.length === count) {
        createFileData = {
          customer_request_no: [],
          is_selected_all: 1,
          search: exportData
        }
      } else {
        createFileData = {
          customer_request_no: selectedData,
          is_selected_all: 0,
          search: initialData
        }
      }
      exportCancellationGenRefundFile(createFileData).then(res => {
        setGenerateFile(res);
        document.getElementById("generate-file-btn").click();
      });
    } else {
      setGenerateFile({});
      document.getElementById("generate-file-btn").click();
    }
  }

  const allEnabledRows = () => {
    return rawData.reduce((count, item) => item.request_refund_status_text === "อยู่ระหว่างดำเนินการคืนเงิน" ? count + 1 : count, 0);
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      postCancellationRequestRefund(initialData).then(res => {
        if (res?.data === undefined) {
          setRawData([]);
          setIsNoData(true);
        } else {
          setRawData(res.data);
        }
        setIsLoading(false);
      });
    }, 500);
  }, [])

  return (
    <>
      <Container>
        <div className="layout">
          <div className="row vh-100">
            <Row>
              <ResponsiveSideBar />
              <Col md={9} className="grrs-accounting-content">
                <div className="grrs-accounting-container space-bottom">
                  <LoadingSpinner isLoading={isLoading} />
                  <h1 className="grrs-accounting-title">Create file</h1>
                  <div className="row align-items-center space-bottom">
                    <button className="grrs-white-button accounting-export-btn" onClick={() => handleExportFile()} hidden={!isCanSearch}>
                      <img className="icon-excel" src="/assets/icons/excel-app.svg"></img>
                      <span className="font-size">Export To Excel</span>
                      <span className="spinner-border spinner-border-xl button-spinner" role="status" aria-hidden="true" hidden={!isButtonLoading}></span>
                    </button>
                    <button className="grrs-primary-button create-file-btn" onClick={() => handleCreateFile()} hidden={!isCanCreate}>
                      สร้างไฟล์
                    </button>
                    {
                      isCanSearch &&
                      <FormSearch
                        setSearchData={setRawData}
                        setExportData={setExportData}
                        setIsLoading={setIsLoading}
                        parentPage={"createFile"}
                      />
                    }
                  </div>
                  {
                    !isLoading &&
                    permission?.length !== 0 &&
                    (
                      <div className="row align-items-center">
                        <Table
                          data={isCanSearch ? rawData : []}
                          columns={columns}
                          defaultColumn={defaultColumn}
                          setIsNoData={setIsNoData}
                          isNoData={isNoData}
                          redirectFunction={redirectToRequestCancel}
                          parentPage={"createFile"}
                          enabledStatus={"อยู่ระหว่างดำเนินการคืนเงิน"}
                          permission={permission}
                          isCanCreateOrUpdate={isCanCreate}
                          isCanSearch={isCanSearch}
                        />
                      </div>
                    )
                  }
                </div>
                <PopupGenerate generateFile={generateFile} />
              </Col>
            </Row>
          </div>
        </div>
        <PermissionModal permission={permission} backPage={"/grrs"} />
      </Container>
    </>
  );
}

export default Accounting;