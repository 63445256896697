import React from "react";
import { postImportRefundCancellationReq } from "../../../../../service/grrs/accounting.service";
import "./Popup.scss";

function UploadSummary({ uploadResult, checkResponseCode, handleReUpload, currentUploadFile }) {

    const handleClose = () => {
        document.getElementById("upload-summary-modal-close-btn").click();
    }

    const handleCompleteUpload = () => {
        document.getElementById("grrs-approve-refund-upload-confirm").disabled = true;
        postImportRefundCancellationReq(currentUploadFile).then(res => {
            checkResponseCode(res.response_code);
            document.getElementById("upload-summary-modal-close-btn").click();
        })
    }

    return (
        <>
            <button type="button" id="upload-summary-btn" className="btn btn-primary upload-summary-modal-button" data-bs-toggle="modal" data-bs-target="#uploadSummary" hidden>
                ตกลง
            </button>
            <div className="uploadSummaryModal">
                <div className="modal fade" id="uploadSummary" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="uploadSummaryLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title" id="checkingPaymentMethodLabel">ผลการอัปโหลดข้อมูล</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="upload-summary-modal-close-btn" hidden></button>
                            </div>
                            <div className="modal-body">
                                <div className="border-top"></div>
                                <div className="modal-body-text">
                                    {
                                        uploadResult.response_data?.import_fail === undefined || uploadResult.response_data?.import_fail === 0 ? (
                                            <>
                                                <div className="row space-bottom">
                                                    <div className="col-6 text-start">
                                                        <div className="">อัปเดตข้อมูลสำเร็จ</div>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="">{uploadResult.response_data?.import_success} รายการ</div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center btn-section">
                                                    <div className="col-12 text-center">
                                                        <button type="button" className="btn grrs-white-button upload-cancel-btn" onClick={handleClose}>ยกเลิก</button>
                                                        <button type="button" className="btn grrs-primary-button upload-confirm-btn" id="grrs-approve-refund-upload-confirm" onClick={handleCompleteUpload}>ยืนยัน</button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="row space-bottom">
                                                    <div className="col-6 text-start">
                                                        <div className="">อัปเดตข้อมูลสำเร็จ</div>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="">{uploadResult.response_data?.import_success} รายการ</div>
                                                    </div>
                                                </div>
                                                <div className="row space-bottom">
                                                    <div className="col-6 text-start">
                                                        <div className="">อัปเดตข้อมูลไม่สำเร็จ</div>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="">{uploadResult.response_data?.import_fail} รายการ</div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center btn-section">
                                                    <div className="col-12 text-center">
                                                        <button type="button" className="btn grrs-white-button upload-cancel-btn" onClick={handleReUpload}>อัปโหลดไฟล์ใหม่</button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default UploadSummary;