import React, { useEffect, useState, useMemo, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Accounting.scss";
import { postVoidFailReport, exportVoidStatusReport } from "../../../../service/grrs/report.service";

import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import FormSearch from "../../components/Share/FormSearch";
import TableTab from "../../components/Accounting/Table/tab";
import { ColumnFilter } from "../../components/Accounting/CreateFile/ColumnFilter/ColumnFilter";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import PermissionModal from "../../../common/components/PermissionModal";
import { COLUMNS_RETURN } from "../../components/Accounting/Report/columnsVoidAutoFailReturn";
import { COLUMNS_DISCOUNT } from "../../components/Accounting/Report/columnsVoidAutoFailDiscount";

const initialData = {
  request_type_code: null,
  reason_code: null,
  refund_channel_code: null,
  request_refund_status_code: null,
  account_no: null,
  phone_no: null,
  product_no: null,
  order_no: null,
  order_date_to: null,
  order_date_from: null,
};

function AccountingReportVoidAutoFail() {
  const FileDownload = require("js-file-download");

  const { permission } = useContext(MenuContext);
  const [rawData, setRawData] = useState([]);
  const [isNoData, setIsNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState(initialData);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isTabReturn, setIsTabReturn] = useState(true);
  const columns = useMemo(() => isTabReturn ? COLUMNS_RETURN : COLUMNS_DISCOUNT, [isTabReturn]);

  const isCanSearch = permission.some((res) => res === "search");
  const isCanDownload = permission.some((res) => res === "download");
  const isCanEditStatus = permission.some((res) => res === "update");

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter
    }
  }, []);

  const handleExportFile = () => {
    exportData["void_success"] = 0;
    setIsButtonLoading(true);
    exportVoidStatusReport(exportData).then((res) => {
      const contentDisposition = res?.headers["content-disposition"];
      if (res !== null && contentDisposition !== undefined) {
        const filename = contentDisposition.split("filename*=utf-8''")[1].trim();
        FileDownload(res.data, decodeURI(filename));
      }
      setIsButtonLoading(false);
    });
  }

  const handleChangeTab = (page) => {
    if (page === "return") {
      setIsTabReturn(true);
    } else {
      setIsTabReturn(false);
    }
  }

  const handleColumn = (rawData) => {
    var tempData = rawData.map((list, index) => {
      return {
          ...list,
            date: list["order_date"], // for filter because react-table can not same accessor name
            alm_point_qty2: list["alm_point_qty"],
            coin_mstamp_qty2: list["coin_mstamp_qty"]
     }
   })  
    return tempData;
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      postVoidFailReport(initialData).then(res => {
        if (isTabReturn) {
          if (res?.data.void_issue === undefined) {
            setRawData([]);
            setIsNoData(true);
          } else {
            setRawData(handleColumn(res.data.void_issue));
            setIsNoData(false);
          }
        } else {
          if (res?.data.void_deduct === undefined) {
            setRawData([]);
            setIsNoData(true);
          } else {
            setRawData(handleColumn(res.data.void_deduct));
            setIsNoData(false);
          }
        }
        setIsLoading(false);
      });
    }, 500);
  }, [isTabReturn])

  return (
    <>
      <Container>
        <div className="layout">
          <div className="row vh-100">
            <Row>
              <ResponsiveSideBar />
              <Col md={9} className="grrs-accounting-content">
                <div className="grrs-accounting-container space-bottom">
                  <LoadingSpinner isLoading={isLoading} />
                  <h1 className="grrs-accounting-title">ข้อมูลยกเลิกภายในวันสถานะ Void ไม่สำเร็จ</h1>
                  <div className="row align-items-center space-bottom">
                  {
                    isCanDownload &&
                    <button className="grrs-white-button accounting-export-btn" onClick={() => handleExportFile()} hidden={!isCanSearch}>
                      <img className="icon-excel" src="/assets/icons/excel-app.svg" alt="excel"></img>
                      <span className="font-size">Export To Excel</span>
                      <span className="spinner-border spinner-border-xl button-spinner" role="status" aria-hidden="true" hidden={!isButtonLoading}></span>
                    </button>
                  }
                    {
                      isCanSearch &&
                      <FormSearch
                        setSearchData={setRawData}
                        setExportData={setExportData}
                        setIsLoading={setIsLoading}
                        parentPage={isTabReturn ? "createFileVoidFailReturn" : "createFileVoidFailDiscount"} 
                      />
                    }
                  </div>
                  {
                    !isLoading &&
                    permission?.length !== 0 &&
                    (
                      <>
                        <div className="row align-items-center">
                          <button className={`tab-table ${isTabReturn ? 'tab-table-click': ''}`} onClick={() => handleChangeTab("return")}>
                            ดึงแต้มที่แจกให้ลูกค้าคืนบริษัท
                          </button>  
                          <button className={`tab-table ${!isTabReturn ? 'tab-table-click': ''}`} onClick={() => handleChangeTab("discount")}>
                            คืนแต้มที่ลูกค้าใช้เป็นส่วนลดกลับให้ลูกค้า
                          </button>
                        </div>
                        
                        <div className="row align-items-center">
                          <TableTab
                            data={isCanSearch ? rawData : []}
                            columns={columns}
                            defaultColumn={defaultColumn}
                            setIsNoData={setIsNoData}
                            isNoData={isNoData}
                            parentPage={isTabReturn ? "reportVoidAutoFailReturn" : "reportVoidAutoFailDiscount"}
                            permission={permission}
                            isCanEditStatus={isCanEditStatus}
                            isCanSearch={isCanSearch}
                          />
                        </div>
                      </>
                    )
                  }
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <PermissionModal permission={permission} backPage={"/grrs"} />
      </Container>
    </>
  );
}

export default AccountingReportVoidAutoFail;