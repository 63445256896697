import React from "react";
import "./FormRequestCancel.scss";
import { useState } from "react";
import { numberWithCommas } from "../../../../../helper/commonHelper";
function DetailCancel({ cancellationInitial }) {
  const [isDisplay, setIsDisplay] = useState(true);
  const { orders } = cancellationInitial;
  const filterValue = (list, type, unit) => {
    if (list !== undefined)
      return numberWithCommas(
        list
          .filter((c) => c.type === type)
          .filter((c) => c.unit === unit)
          .reduce((sum, c) => sum + Number(c.points), 0)
      );
    return 0;
  };
  return (
    <div className="form-request-cancel">
      <div className="header-request-cancel header-request-cancel-view">
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">รายละเอียดการยกเลิกรายการ</span>
      </div>

      {isDisplay && (
        <div className="body-request-cancel header-request-cancel-view">
          <div className="container">
            <table className="detail-cancle-table">
              <tbody>
                <tr>
                  <th className="th-left text-center no-wrap">ลำดับที่</th>
                  <th className="text-center">ชื่อสินค้า</th>
                  <th className="text-center">จำนวนชิ้น</th>
                  <th className="text-center">ราคาต่อชิ้น</th>
                  <th className="text-center">รวมเป็นเงิน</th>
                  <th className="text-center">
                    Invoice No. <br />
                    Invoice Date.
                  </th>
                  <th className="text-center th-right">D_Status.</th>
                </tr>

                {orders.order_detail_list.map((order, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      {index + 1}
                    </td>
                    <td>
                      <div>{order.item_name}</div>
                      <div>รหัสสินค้า {order.item_no}</div>
                    </td>
                    <td className="text-center">{order?.qty}</td>
                    <td className="text-center">
                      <span className="mr-2">฿</span>
                      <span>{numberWithCommas(order?.price_unit)}</span>
                    </td>
                    <td className="text-center">
                      <span className="mr-2">฿</span>
                      <span>{numberWithCommas(order?.total_price)}</span>
                    </td>
                    <td className="text-center">
                      {order?.invoice_details?.map((list) => (
                        <>
                          <div>{list?.invoice_no}</div>
                          <div>{list?.invoice_date}</div>
                        </>
                      ))}
                    </td>

                    <td className="text-center">{order.order_detail_status}</td>
                  </tr>
                ))}

                <tr className="tr-summary">
                  <td></td>
                  <td>รวมราคาสินค้า</td>
                  <td className="text-center">
                    {numberWithCommas(orders.total_qty)}
                  </td>
                  <td className="text-center">
                    <span className="mr-2">฿</span>
                    <span>{numberWithCommas(orders.total_price_unit)}</span>
                  </td>
                  <td className="text-center">
                    <span className="mr-2">฿</span>
                    <span>{numberWithCommas(orders.total_price)}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>ค่าจัดส่ง</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">฿</span>
                    <span>{numberWithCommas(orders.delivery_fee)}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td>ส่วนลด (คูปอง / โปรโมชั่น)
                  </td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">-฿</span>
                    <span>{numberWithCommas(orders.general_discount)}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td className="font-weight">ส่วนลด (Normal rate)</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>แลก ALL Point แทนเงินสด <span className="d-flex color-grey">(คำนวณเป็นแต้ม)</span></td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">-฿</span>
                    <span>{filterValue(orders.redeemedSummary, 'allmemberPoint', 'บาท')}</span>
                    <span className="d-flex justify-content-center color-grey">{filterValue(orders.redeemedSummary, 'allmemberPoint', 'แต้ม')} แต้ม</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>แลก M-Stamp / เหรียญ แทนเงินสด <span className="d-flex color-grey">(คำนวณเป็นแต้ม)</span></td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">-฿</span>
                    <span>{filterValue(orders.redeemedSummary, 'mstamp', 'บาท')}</span>
                    <span className="d-flex justify-content-center color-grey">{filterValue(orders.redeemedSummary, 'mstamp', 'แต้ม')} แต้ม</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td className="font-weight">ส่วนลดพิเศษ (Special rate)</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>ส่วนลดพิเศษ (ALL Point แลกโปรโมชั่น)<span className="d-flex color-grey">(คำนวณเป็นแต้ม)</span></td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">-฿</span>
                    <span>{filterValue(orders.specialRedeemedSummary, 'allmemberPoint', 'บาท')}</span>
                    <span className="d-flex justify-content-center color-grey">{filterValue(orders.specialRedeemedSummary, 'allmemberPoint', 'แต้ม')} แต้ม</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td>ส่วนลดพิเศษ (M-Stamp / เหรียญ แลกโปรโมชั่น) <span className="d-flex color-grey">(คำนวณเป็นแต้ม)</span></td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">-฿</span>
                    <span>{filterValue(orders.specialRedeemedSummary, 'mstamp', 'บาท')}</span>
                    <span className="d-flex justify-content-center color-grey">{filterValue(orders.specialRedeemedSummary, 'mstamp', 'แต้ม')} แต้ม</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr className="tr-summary red">
                  <td></td>
                  <td>ยอดสุทธิ</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">฿</span>
                    <span>{numberWithCommas(orders.invoice_value)}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>รับ ALL Point (แต้ม)</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span>{filterValue(orders.earnPoints, 'allmemberPoint', 'แต้ม')}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>รับ M-Stamp / เหรียญ  (บาท)</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">฿</span>
                    <span>{filterValue(orders.earnPoints, 'mstamp', 'บาท')}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td><strong>ยอดสะสม ณ ปัจจุบัน</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>ALL Point (แต้ม)</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span>{filterValue(orders.rewardPointsBalance, 'allmemberPoint', 'แต้ม')}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>M-Stamp / เหรียญ  (บาท)</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">฿</span>
                    <span>{filterValue(orders.rewardPointsBalance, 'mstamp', 'บาท')}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td><strong>ยอดที่ต้องเรียกคืน</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>ALL Point (แต้ม)</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span>{filterValue(orders.voidRewardPoints, 'allmemberPoint', 'แต้ม')}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>M-Stamp / เหรียญ  (บาท)</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2">฿</span>
                    <span>{filterValue(orders.voidRewardPoints, 'mstamp', 'บาท')}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td><strong>ยอดที่ขาดไป</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>ALL Point (แต้ม)</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="red">{filterValue(orders.diffVoidRewardPoints, 'allmemberPoint', 'แต้ม')}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>M-Stamp / เหรียญ  (บาท)</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <span className="mr-2 red" >฿</span>
                    <span className="red">{filterValue(orders.diffVoidRewardPoints, 'mstamp', 'บาท')}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default DetailCancel;
