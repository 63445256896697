import { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getCreditNoteDetails, putCreditnoteTotalRefund } from "../../../../service/ecn/e-creditnote.service";
import MenuContext from "../../../../context/MenuContext";

const EcreditNoteDetailTable = ({ getResponseData }) => {
    const { permission } = useContext(MenuContext);
    const [totalSelected, setTotalSelected] = useState({});
    const [calTotalRefund, setCalTotalRefund] = useState("");
    const [maxRefund, setMaxRefund] = useState("");
    const [data, setData] = useState([]);
    const [listData, setListData] = useState([]);
    const [modalSave, setModalSave] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);
    const [totalCalculateData, setTotalCalculateData] = useState({});

    let history = useHistory();
    const reset = () => {
        history.goBack();
    };
    const { ecnId } = useParams();
    const status_name = data.status;

    const textCol = "col-6 col-sm-6 col-md-3 col-xxl-3 conclude-text";
    const numCol = "col-6 col-sm-6 col-md-4 col-xxl-3 conclude-number";
    const subNumColText = "col-8 col-sm-8 col-md-6 col-xxl-5 text-end";
    const subNumColUnit = "col-4 col-sm-4 col-md-4 col-xxl-3 text-start";
    const whiteBorder = "col-12 col-sm-12 col-md-6 col-xxl-5 border-bottom border-white mt-3 mb-3";
    const blackBorder = "col-12 col-sm-12 col-md-6 col-xxl-5 border-bottom border-dark mt-3 mb-3";

    const saveButtonHandle = () => {
        putCreditnoteTotalRefund(ecnId, calTotalRefund).then(res => {
            getResponseData(res);
        })
    }

    const onInputTotalReturnRefund = () => {
        let returnNewRefund = calTotalRefund;
        const values = document.getElementById("returnTotalRefund").value;
        const value = parseFloat(values);
        if (value <= maxRefund) {
            returnNewRefund = value;
        } else if (value > maxRefund) {
            returnNewRefund = maxRefund;
        } else if (values === "") {
            returnNewRefund = "";
        }
        setCalTotalRefund(returnNewRefund);
    }

    const setTotalSelectedAndTotalRefund = (totalSelected, totalCalculate) => {
        const totalPrice = totalSelected.total_price.toFixed(2);
        const shareDiscount = totalSelected.share_discount.toFixed(2);
        const shareStamp = totalSelected.share_stamp.toFixed(2);
        const shareAmbPoint = totalSelected.share_amb_point.toFixed(2);
        const refund = totalSelected.refund.toFixed(2);
        setTotalSelected({
            total_price: totalPrice,
            share_discount: shareDiscount,
            share_stamp: shareStamp,
            share_amb_point: shareAmbPoint,
            refund: refund,
        });

        const totalRefundByCompute =
            totalCalculate.total_refund_by_compute.toFixed(2);
        const totalRefundMax = totalCalculate.total_refund_max.toFixed(2);
        const totalReturnAmbPoint =
            totalCalculate.total_return_amb_point.toFixed(2);
        const totalStamp = totalCalculate.total_return_stamp.toFixed(2);
        const totalReturnDeliveryFee =
            totalCalculate.total_return_delivery_fee.toFixed(2);
        const totalRefund = totalCalculate.total_refund.toFixed(2);
        setTotalCalculateData({
            total_refund_by_compute: totalRefundByCompute,
            total_refund_max: totalRefundMax,
            total_return_amb_point: totalReturnAmbPoint,
            total_stamp: totalStamp,
            total_return_delivery_fee: totalReturnDeliveryFee,
            total_refund: totalRefund,
        });
        setCalTotalRefund(totalRefund);
        setMaxRefund(totalCalculate.total_refund_max);
    };

    useEffect(() => {
        getCreditNoteDetails(ecnId).then(res => {
            setData(res);
            setListData(res.creditnote_detail_list);
            setTotalSelectedAndTotalRefund(res.total_selected, res.total_calculate);
        });
    }, []);

    return (
        <>
            <div className="table-responsive">
                <table className="table-primary">
                    <thead>
                        <tr>
                            <th>ลำดับ</th>
                            <th>เลขที่ใบส่งของ</th>
                            <th>รหัสสินค้า</th>
                            <th>ชื่อสินค้า</th>
                            <th>จำนวนที่สั่ง</th>
                            <th>จำนวนที่คืนแล้ว</th>
                            <th>ราคา/หน่วย</th>
                            <th>ราคารวม</th>
                            <th>Share Discount</th>
                            <th>Share Stamp</th>
                            <th>Share AMB point</th>
                            <th>คืนเงิน</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listData?.map((val, index) => {
                                return (
                                    <>
                                        <tr key={index} className={index % 2 !== 0 ? "light-blue" : "bg-white"}>
                                            <td>{val.order_detail_no !== null ? val.order_detail_no : "-"}</td>
                                            <td>{val.invoice_no.length !== 0 ? val.invoice_no : "-"}</td>
                                            <td>{val.item_no !== null ? val.item_no : "-"}</td>
                                            <td className="text-start">{val.item_name !== null ? val.item_name : "-"}</td>
                                            <td>{val.qty !== null ? val.qty : "-"}</td>
                                            <td>{val.return_qty !== null ? val.return_qty : "-"}</td>
                                            <td>{val.price_unit !== null ? val.price_unit : "-"}</td>
                                            <td>{val.total_price !== null ? val.total_price : "-"}</td>
                                            <td>{val.share_discount !== null ? val.share_discount : "-"}</td>
                                            <td>{val.share_stamp !== null ? val.share_stamp : "-"}</td>
                                            <td>{val.share_amb_point !== null ? val.share_amb_point : "-"}</td>
                                            <td>{val.refund !== null ? val.refund : "-"}</td>
                                        </tr>
                                    </>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="table-footer" colSpan="12"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className={status_name === "อยู่ระหว่างดำเนินการ" ? "container detail-pending-conclude" : "container detail-conclude"}>
                <div className="row">
                    <label className="col-12 col-sm-12 col-md-8 col-xxl-5 g-0 text-start conclude-header" hidden={status_name === "อยู่ระหว่างดำเนินการ" ? false : true}>Customer Service ดำเนินการ</label>
                </div>
                <div className="row">
                    <label className="col-12 col-sm-12 col-md-8 col-xxl-5 conclude-text conclude-body-header">ยอดรวม (เฉพาะรายการที่ทำคืน)</label>
                </div>
                <div className="row">
                    {
                        status_name === "อยู่ระหว่างดำเนินการ" ? (
                            <div className={whiteBorder}></div>
                        ) : <div className={blackBorder}></div>
                    }
                </div>
                <div className="row">
                    <div className={textCol}>
                        <label>ราคารวม</label>
                    </div>
                    <div className={numCol}>
                        <div className="row">
                            <label className={subNumColText}>{totalSelected.total_price}</label>
                            <span className={subNumColUnit}> บาท</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={textCol}>
                        <label>Share Discount</label>
                    </div>
                    <div className={numCol}>
                        <div className="row">
                            <label className={subNumColText}>{totalSelected.share_discount}</label>
                            <span className={subNumColUnit}> บาท</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={textCol}>
                        <label>Share Stamp</label>
                    </div>
                    <div className={numCol}>
                        <div className="row">
                            <label className={subNumColText}>{totalSelected.share_stamp}</label>
                            <span className={subNumColUnit}> บาท</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={textCol}>
                        <label>ยอดรวมคืน AMB Point</label>
                    </div>
                    <div className={numCol}>
                        <div className="row">
                            <label className={subNumColText}>{totalSelected.share_amb_point}</label>
                            <span className={subNumColUnit}> บาท</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={textCol}>
                        <label>ยอดรวมคืนเงินโดยระบบ</label>
                    </div>
                    <div className={numCol}>
                        <div className="row">
                            <label className={subNumColText}>{totalCalculateData.total_refund_by_compute}</label>
                            <span className={subNumColUnit}> บาท</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        status_name === "อยู่ระหว่างดำเนินการ" ? (
                            <div className={whiteBorder}></div>
                        ) : <div className={blackBorder}></div>
                    }
                </div>
                <div className="row align-items-center">
                    <div className="col-6 col-sm-6 col-md-3 col-xxl-3 conclude-text">
                        <label>ยอดรวมคืนเงิน</label>
                    </div>
                    {
                        status_name === "อยู่ระหว่างดำเนินการ" ?
                            (
                                <>
                                    <div className="col-6 col-sm-6 col-md-4 col-xxl-3 conclude-number">
                                        <div className="row align-items-center">
                                            <label className="col-8 col-sm-8 col-md-6 col-xxl-5">
                                                <input className="form-control" type="number" id="returnTotalRefund"
                                                    min="0" max={maxRefund} value={calTotalRefund}
                                                    onInput={() => onInputTotalReturnRefund()} disabled={!permission.some((res) => res === "update")}
                                                >
                                                </input>
                                            </label>
                                            <span className="col-4 col-sm-4 col-md-4 col-xxl-3 refund-unit"> บาท</span>
                                        </div>
                                    </div>
                                </>
                            ) :
                            <>
                                <div className="col-6 col-sm-6 col-md-4 col-xxl-3 conclude-number">
                                    <div className="row align-items-center">
                                        <label className="col-8 col-sm-8 col-md-6 col-xxl-5 refund-summary">{calTotalRefund}</label>
                                        <span className="col-4 col-sm-4 col-md-4 col-xxl-3 refund-unit"> บาท</span>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <div className="row justify-content-center conclude-button">
                    {
                        status_name === "อยู่ระหว่างดำเนินการ" ?
                            (
                                <>
                                    <button
                                        className="col-8 col-sm-4 col-md-4 col-lg-4 col-xxl-3 btn cancel-button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop"
                                        onClick={() => setModalCancel(true)}
                                    >
                                        ยกเลิก
                                    </button>
                                    <button
                                        className="col-8 col-sm-6 col-md-4 col-lg-4 col-xxl-3 btn save-button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop2"
                                        onClick={() => setModalSave(true)}
                                        disabled={!permission.some((res) => res === "update")}
                                    >
                                        บันทึกการเปลี่ยนแปลง
                                    </button>
                                </>
                            ) : (
                                <Link to={"/ecn/คำขอคืนสินค้า"} className="col-6 col-sm-4 col-md-4 col-lg-4 col-xxl-2 btn back-button"><span>กลับ</span></Link>
                            )
                    }
                </div>
            </div>
            <div className={modalSave ? "" : "disable"}>
                <div
                    className="modal fade"
                    id="staticBackdrop2"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="header">
                                <h4 className="title" id="staticBackdropLabel">
                                    ยืนยันข้อมูล
                                </h4>
                            </div>
                            <div className="body">
                                <h5>โปรดตรวจสอบข้อมูล ก่อนกดยืนยัน</h5>
                            </div>
                            <div className="footer">
                                <button
                                    type="submit"
                                    className="resetbutton"
                                    data-bs-dismiss="modal"
                                    onClick={saveButtonHandle}
                                >
                                    บันทึก
                                </button>
                                <button
                                    type="button"
                                    className="backbutton"
                                    data-bs-dismiss="modal"
                                >
                                    แก้ไข
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={modalCancel ? "" : "disable"}>
                <div
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="header">
                                <h4 className="title" id="staticBackdropLabel">
                                    ยกเลิกการแก้ไข
                                </h4>
                            </div>
                            <div className="body">
                                <h5>คุณต้องการยกเลิกการแก้ไขข้อมูลทั้งหมด หรือไม่</h5>
                            </div>
                            <div className="footer">
                                <button
                                    type="reset"
                                    className="resetbutton"
                                    data-bs-dismiss="modal"
                                    onClick={reset}
                                >
                                    ยืนยัน
                                </button>
                                <button
                                    type="button"
                                    className="backbutton"
                                    data-bs-dismiss="modal"
                                >
                                    ย้อนกลับ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EcreditNoteDetailTable;