import React from "react";
import { useState } from "react";
import "./Popup.scss";

function EditStatus({requestId, submitFunction}) {
    const [status, setStatus] = useState("0");

    const handleClose = () => {
        document.getElementById(`edit-status-modal-close-btn${requestId}`).click();
    }

    const handleSubmit = () => {
        document.getElementById(`grrs-edit-status-confirm${requestId}`).disabled = true;
        submitFunction();
        handleClose();
    }

    return (
        <>
            <button type="button" id={`edit-status-btn${requestId}`} className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#editStatus${requestId}`} hidden>
                ตกลง
            </button>
            <div className="editStatusModal">
                <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="editStatusLabel" aria-hidden="true"
                  id={`editStatus${requestId}`}
                  >
                    <div className="modal-dialog">
                    <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title" id="editStatusModalLabel">
                                    <img 
                                        id="edit-status"
                                        className="icon-edit-title" src="/assets/grrs/edit-icon.svg" 
                                        alt="edit"
                                    />
                                    <span className="modal-title fw-bold">แก้ไข Status</span>
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id={`edit-status-modal-close-btn${requestId}`} hidden></button>
                            </div>
                            <div className="modal-body">    
                                <div className="row select-change-status">
                                    <div className="col-3">
                                        <span>Status :</span>
                                    </div>
                                <div className="col-9">
                                    <select className="form-select"
                                      onChange={(e) => {
                                        setStatus(e.target.value);
                                      }}
                                    >
                                        <option value={0}>คืนไม่สำเร็จ</option>
                                        <option value={1}>คืนสำเร็จ</option>
                                    </select>
                                </div>
                            </div>

                                <div className="row justify-content-center btn-section">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn grrs-white-button edit-cancel-btn" onClick={handleClose}>ยกเลิก</button>
                                        <button type="button" className="btn grrs-primary-button edit-confirm-btn" id={`grrs-edit-status-confirm${requestId}`} onClick={handleSubmit} disabled={status === "0"}>ยืนยัน</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default EditStatus;