import { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
    postCalculateRefund,
    postReturnRequestCreditNote,
} from "../../../../service/ecn/e-creditnote.service";
import SearchOrder from "../common/SearchOrder";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import AlertComponent from "../../../common/components/Alert";
import MenuContext from "../../../../context/MenuContext";

const AddECreditNote = () => {
    const query = new URLSearchParams(useLocation().search);
    const taxNo = query.get("taxNo");
    const orderSource = query.get("orderSource");
    const [isAlertName, setIsAlertName] = useState("");
    const [responseCode, setResponseCode] = useState();
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [isMaxReturnQty, setIsMaxReturnQty] = useState([]);
    const [headerData, setHeaderData] = useState();
    const [isShowOrder, setIsShowOrder] = useState(false);
    const [isShowSaveButton, setIsShowSaveButton] = useState(false);
    const [isFromPreRequest, setIsFromPreRequest] = useState();
    const [totalSelectedData, setTotalSelectedData] = useState({});
    const [totalCalculateData, setTotalCalculateData] = useState({});
    const [isCheckAll, setIsCheckAll] = useState();
    const [returnQtyBeforeUpdate, setReturnQtyBeforeUpdate] = useState([]);
    const [maxReturnQtyBeforeUpdate, setMaxReturnQtyBeforeUpdate] = useState([]);
    const [totalRefund, setTotalRefund] = useState("");
    const [maxRefund, setMaxRefund] = useState("");
    const [modalSave, setModalSave] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isHandleAlertClick, setIsHandleAlertClick] = useState(false);
    const [isHandleAlertSaveClick, setIsHandleAlertSaveClick] = useState(false);
    const [isNoData, setIsNoData] = useState(false);
    const { permission } = useContext(MenuContext);

    let history = useHistory();
    const reset = () => {
        history.goBack();
    };

    const isItemsHaveFullReturnQty = async (firstData) => {
        const beforeChangeData = [...firstData];
        const array = [];
        for (let i = 0; i < beforeChangeData.length; i++) {
            if (beforeChangeData[i].return_qty === beforeChangeData[i].qty) {
                array.push(beforeChangeData[i].order_detail_no);
            }
        }
        setIsMaxReturnQty(array);
    };

    const keepMaxUserReturnQty = async (firstData) => {
        const beforeChangeData = [...firstData];
        const array = [];
        for (let i = 0; i < beforeChangeData.length; i++) {
            if (beforeChangeData[i].qty - beforeChangeData[i].return_qty <= 0) {
                array.push(
                    {
                        order_detail_no: beforeChangeData[i].order_detail_no,
                        qty: 0
                    });
            }
            else {
                array.push(
                    {
                        order_detail_no: beforeChangeData[i].order_detail_no,
                        qty: beforeChangeData[i].qty - beforeChangeData[i].return_qty
                    }
                );
            }
        }
        setMaxReturnQtyBeforeUpdate(array);
    };

    const setUserReturnQty = (rawData) => {
        let tempData = rawData.map((item) => {
            return {
                ...item,
                user_return_qty: 0,
                share_discount: null,
                share_stamp: null,
                share_amb_point: null,
                refund: null,
            };
        });
        setData(tempData);
        setInitialData(rawData);
        setFirstReturnQty(rawData);
    };

    const setFirstReturnQty = (rawData) => {
        const beforeChangeData = [...rawData];
        const array = [];
        for (let i = 0; i < beforeChangeData.length; i++) {
            array.push({
                order_detail_no: beforeChangeData[i].order_detail_no,
                return_qty: beforeChangeData[i].return_qty
            });
        }
        setReturnQtyBeforeUpdate(array);
    };

    const handleChangeData = (e, index) => {
        const updateData = [...data];
        updateData[index][e.target.name] =
            e.target.value <= updateData[index].qty
                ? e.target.value
                : updateData[index].qty;
        updateData[index].isChecked = e.target.value > 0 ? true : false;
        setData(updateData);
        setIsShowSaveButton(false);
    };

    const handleChangeValueData = (index, operator) => {
        const updateData = [...data];
        if (
            operator === "+" &&
            updateData[index].user_return_qty < (updateData[index].qty - returnQtyBeforeUpdate.filter(data => data.order_detail_no == updateData[index].order_detail_no).map(data => data.return_qty))
        ) {
            updateData[index].user_return_qty += 1;
            updateData[index].isChecked =
                updateData[index].user_return_qty > 0
                    ? true
                    : false;
            setData(updateData);
            handleCheckAllBox(updateData);
        } else if (
            operator === "-" &&
            updateData[index].user_return_qty > 0
        ) {
            updateData[index].user_return_qty -= 1;
            updateData[index].isChecked =
                updateData[index].user_return_qty > 0
                    ? true
                    : false;
            setData(updateData);
            handleCheckAllBox(updateData);
        }
        setIsShowSaveButton(false);
    };

    const handleCheckBox = (index) => {
        const updateData = [...data];
        if (
            updateData[index].user_return_qty > 0
        ) {
            updateData[index].user_return_qty = 0;
            updateData[index].isChecked =
                updateData[index].user_return_qty > 0
                    ? true
                    : false;
            setData(updateData);
            handleCheckAllBox(updateData);
        }
        setIsShowSaveButton(false);
    };

    const handleCheckAllBox = (data) => {
        const tempUserReturnQty = data.filter(items => items.user_return_qty[0] != undefined).map(items => items.order_detail_no);
        if (data.some((item) => item.isChecked === false) || data.some((item) => item.user_return_qty < item.qty - returnQtyBeforeUpdate.filter(data => data.order_detail_no == item.order_detail_no).map(data => data.return_qty))) {
            setIsCheckAll(false);
        } else if (data.every((item) => item.user_return_qty === item.qty - returnQtyBeforeUpdate.filter(data => data.order_detail_no == item.order_detail_no).map(data => data.return_qty))
            || (data.filter(item => item.order_detail_no != tempUserReturnQty[0]).some((item) => item.user_return_qty === item.qty - returnQtyBeforeUpdate.filter(data => data.order_detail_no == item.order_detail_no).map(data => data.return_qty))
                && data.filter(item => item.order_detail_no == tempUserReturnQty[0]).some((item) => item.user_return_qty[0] === item.qty - returnQtyBeforeUpdate.filter(data => data.order_detail_no == item.order_detail_no).map(data => data.return_qty)))) {
            setIsCheckAll(true);
        }
    };

    const handleChangeSelectedTable = (e) => {
        const { name, checked } = e.target;
        if (name === "allSelect") {
            let tempData = data.map((item) => {
                return isMaxReturnQty.includes(item.order_detail_no) === false
                    ? {
                        ...item,
                        isChecked: checked,
                        user_return_qty: checked === false ? 0 : maxReturnQtyBeforeUpdate.filter(data => data.order_detail_no == item.order_detail_no).map(data => data.qty)
                    }
                    : {
                        ...item,
                        isChecked: "",
                        user_return_qty: maxReturnQtyBeforeUpdate.filter(data => data.order_detail_no == item.order_detail_no).map(data => data.qty)
                    };
            });
            setData(tempData);
            setIsCheckAll(true);
            setIsShowSaveButton(false);
        } else {
            let tempData = data.map((item) =>
                item.id === name
                    ? {
                        ...item,
                        isChecked: checked,
                        user_return_qty: checked === false ? 0 : item.user_return_qty,
                    }
                    : item
            );
            if (data[name].user_return_qty === 0) {
                handleChangeValueData(name, "+")
            } else if (data[name].user_return_qty > 0) {
                handleCheckBox(name)
            }
            setData(tempData);
            setIsShowSaveButton(false);
        }
    };

    const checkDataRequest = () => {
        if (isFromPreRequest) {
            let tempCalData = data
                .filter(
                    (data) =>
                        isMaxReturnQty.includes(data.order_detail_no) === false
                )
                .map((item) => {
                    return {
                        order_detail_no: item.order_detail_no,
                        return_qty: item.qty - item.return_qty,
                    };
                });
            return tempCalData;
        } else {
            let tempCalData = data
                .filter(
                    (data) => data.user_return_qty > 0
                )
                .map((item) => {
                    return {
                        order_detail_no: item.order_detail_no,
                        return_qty:
                            item.user_return_qty -
                            (item.qty - returnQtyBeforeUpdate.filter(data => data.order_detail_no == item.order_detail_no).map(data => data.return_qty) - maxReturnQtyBeforeUpdate.filter(data => data.order_detail_no == item.order_detail_no).map(data => data.qty)),
                    };
                });
            return tempCalData;
        }
    };

    const handleCalculateRefund = () => {
        let isHaveUserReturnQty = handleCheckDataBeforeCalculate();
        if (isHaveUserReturnQty === true) {
            let calData = checkDataRequest();
            const actionType = "return";
            postCalculateRefund(
                headerData.order_no,
                headerData.ordersource,
                actionType,
                calData
            ).then((res) => {
                mapAfterCalculateData(res);
                setResponseCode(res.response_code);
                setIsAlertName("success-calculated");
                setAlertHandle();
                if (res.response_code !== undefined) {
                    setIsShowSaveButton(true);
                }
            });
        }
    };

    const handleCheckDataBeforeCalculate = () => {
        const isDataNotAssignReturnQty = data.some(
            (item) =>
                item.isChecked === true && item.user_return_qty === 0
        );
        const isDataFalseAll = data.every((item) => {
            item.isChecked = item.isChecked ? item.isChecked : false;
            return item.isChecked === false;
        });

        if (!isDataNotAssignReturnQty || isFromPreRequest) {
            if (isDataFalseAll && !isFromPreRequest) {
                setIsAlertName("warning-input-user_eturn_qty");
                setAlertHandle();
                return false;
            }
        } else {
            setIsAlertName("warning-input-user_eturn_qty");
            setAlertHandle();
            return false;
        }
        return true;
    };

    const mapIntitialData = () => {
        const tempArrayData = data.filter(items => items.user_return_qty === 0).map(items => items.order_detail_no);

        let mapData = data.map((item) => {
            return tempArrayData.includes(item.order_detail_no) === true ?
                {
                    ...item,
                    share_discount: null,
                    share_stamp: null,
                    share_amb_point: null,
                    refund: null,
                } :
                {
                    ...item
                }
        });

        return mapData;
    }

    const mapAfterCalculateData = (responseCalData) => {
        if (responseCalData.response_code === undefined) {
            setAlertHandle();
        }
        else {
            const tempData = mapIntitialData();
            const afterCalData = responseCalData.response_data.detail_list;
            let mergeData = tempData.map((oldData) => {
                let mergeNewData = afterCalData.find(
                    (newData) => newData.order_detail_no === oldData.order_detail_no
                );
                return {
                    ...oldData,
                    ...mergeNewData,
                    return_qty: parseInt(oldData.user_return_qty) + parseInt(returnQtyBeforeUpdate.filter(data => data.order_detail_no == oldData.order_detail_no).map(data => data.return_qty)),
                };
            });
            setData(mergeData);
            setDataAfterCal(
                responseCalData.response_data.total_selected,
                responseCalData.response_data.total_calculate
            );
        }
    };

    const setDataAfterCal = (totalSelected, totalCalculate) => {
        const totalPrice = totalSelected.total_price.toFixed(2);
        const shareDiscount = totalSelected.share_discount.toFixed(2);
        const shareStamp = totalSelected.share_stamp.toFixed(2);
        const shareAmbPoint = totalSelected.share_amb_point.toFixed(2);
        const refund = totalSelected.refund.toFixed(2);
        setTotalSelectedData({
            total_price: totalPrice,
            share_discount: shareDiscount,
            share_stamp: shareStamp,
            share_amb_point: shareAmbPoint,
            refund: refund,
        });

        const totalRefundByCompute =
            totalCalculate.total_refund_by_compute.toFixed(2);
        const totalRefundMax = totalCalculate.total_refund_max.toFixed(2);
        const totalReturnAmbPoint =
            totalCalculate.total_return_amb_point.toFixed(2);
        const totalStamp = totalCalculate.total_return_stamp.toFixed(2);
        const totalReturnDeliveryFee =
            totalCalculate.total_return_delivery_fee.toFixed(2);
        const totalRefund = totalCalculate.total_refund.toFixed(2);
        setTotalCalculateData({
            total_refund_by_compute: totalRefundByCompute,
            total_refund_max: totalRefundMax,
            total_return_amb_point: totalReturnAmbPoint,
            total_stamp: totalStamp,
            total_return_delivery_fee: totalReturnDeliveryFee,
            total_refund: totalRefund,
        });
        setTotalRefund(totalRefund);
        setMaxRefund(totalCalculate.total_refund_max);
    };

    const onInputTotalReturnRefund = () => {
        let returnNewRefund = totalRefund;
        const values = document.getElementById("returnTotalRefund").value;
        const value = parseFloat(values);
        if (value <= maxRefund) {
            returnNewRefund = value;
        } else if (value > maxRefund) {
            returnNewRefund = maxRefund;
        } else if (values === "") {
            returnNewRefund = "";
        }
        setTotalRefund(returnNewRefund);
    };

    const handleChangeOrderData = (responseData) => {
        if (responseData.response_code === 100) {
            const afterSearchData = responseData.response_data;
            const orderDetailList = afterSearchData.order_detail_list.sort((a, b) => {
                return a.order_detail_no - b.order_detail_no;
            });
            isItemsHaveFullReturnQty(orderDetailList);
            keepMaxUserReturnQty(orderDetailList);
            setUserReturnQty(orderDetailList);
            setHeaderData(afterSearchData.order_header);
            setIsShowOrder(true);
            setIsDataEmpty(false);
            setIsNoData(false);
        } else {
            setIsShowOrder(false);
            setIsDataEmpty(true);
            setIsNoData(true);
            setIsAlertName("error-nodata");
            setResponseCode(responseData.response_code);
            setAlertHandle();
        }
    };

    const saveButtonHandle = () => {
        let actionType = "cancel";
        postReturnRequestCreditNote(
            headerData.order_no,
            headerData.ordersource,
            actionType,
            totalRefund
        ).then((res) => {
            if (res === undefined) {
                setResponseCode(res);
                setAlertHandle();
            } else {
                if (res.response_code === 100) {
                    setIsAlertName("success-save");
                    setResponseCode(res.response_code);
                    setAlertHandle("success-save");
                } else {
                    setIsAlertName("error-save");
                    setResponseCode(res.response_code);
                    setAlertHandle();
                }
            }
        });
    };

    const redirectToEcn = () => {
        let path = `/ecn/คำขอคืนสินค้า`;
        history.push(path);
    }

    const setAlertHandle = (name) => {
        if (name === "success-save") {
            setTimeout(() => {
                setIsHandleAlertSaveClick(true);
                redirectToEcn();
            }, 5000);
            setIsHandleAlertSaveClick(false);
        } else {
            setTimeout(() => {
                setIsHandleAlertClick(true);
            }, 5000);
            setIsHandleAlertClick(false);
        }
    }

    const handleAlertClick = () => {
        setIsHandleAlertClick(true);
    }

    useEffect(async () => {
        if (taxNo !== null) {
            let datasearch1 = document.getElementById("search_order_source");
            datasearch1.value = orderSource;
            let datasearch = document.getElementById("search_order");
            datasearch.value = taxNo;
            setIsFromPreRequest(true);
            setIsCheckAll(true);
        }
    }, []);

    return (
        <>
            <div className="container">
                <AlertComponent
                    alertName={isAlertName}
                    responseCode={responseCode}
                    isHandleAlertClick={isHandleAlertClick}
                    handleAlertClick={handleAlertClick}
                    isHandleAlertSaveClick={isHandleAlertSaveClick}
                />
                <div className="ecn-addEcn">
                    <h1 className="addEcn-header">เพิ่มคำขอคืนสินค้า</h1>
                    <SearchOrder
                        setData={handleChangeOrderData}
                        searchValue={taxNo}
                        orderSource={orderSource}
                        setIsLoading={setIsLoading}
                        isNoData={isNoData}
                    />
                    <LoadingSpinner isLoading={isLoading} />
                    {isDataEmpty && !isLoading && (
                        <img
                            src={
                                process.env.PUBLIC_URL + "/assets/pictures/data-not-found.png"
                            }
                            className="rounded mx-auto d-block"
                            id="noOrderImg"
                            alt="..."
                        ></img>
                    )}
                    {isShowOrder && !isLoading && data && (
                        <>
                            <div className="row addEcn-content-header align-items-center g-0">
                                <h1 className="addEcn-content-text-header">ข้อมูลลูกค้า</h1>
                            </div>
                            <div className="container addEcn-content">
                                <div className="row justify-content-between">
                                    <div className="col-12 col-md-6 col-xxl-6">
                                        <label className="addEcn-content-title">
                                            เลขที่คำสั่งซื้อ
                                        </label>
                                        <div className="addEcn-content-form">
                                            <p className="addEcn-content-text">
                                                {headerData.order_no}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xxl-6">
                                        <label className="addEcn-content-title">
                                            ชื่อ-นามสกุล
                                        </label>
                                        <div className="addEcn-content-form">
                                            <p className="addEcn-content-text">
                                                {headerData.customer_firstname}{" "}
                                                {headerData.customer_lastname}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-between">
                                    <div className="col-12 col-md-6 col-xxl-6">
                                        <label className="addEcn-content-title">
                                            วันที่สั่งซื้อ
                                        </label>
                                        <div className="addEcn-content-form">
                                            <p className="addEcn-content-text">
                                                {headerData.order_date}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xxl-6">
                                        <label className="addEcn-content-title">เบอร์โทร</label>
                                        <div className="addEcn-content-form">
                                            <p className="addEcn-content-text">
                                                {headerData.customer_phone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="container addEcn-body">
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <h1 className="addEcn-table-title">
                                                Customer Service ดำเนินการ
                                            </h1>
                                        </div>
                                        <div className="col-12 addEcn-checkAll">
                                            <div className="form-check">
                                                <input
                                                    name="allSelect"
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={
                                                        isCheckAll === true &&
                                                        (isFromPreRequest ||
                                                            !data.filter((data) => isMaxReturnQty.includes(data.order_detail_no) === false).some((item) => item?.isChecked !== true) ||
                                                            data.filter((data) => isMaxReturnQty.includes(data.order_detail_no) === false).every((item) => item.user_return_qty === item.qty))
                                                    }
                                                    onChange={handleChangeSelectedTable}
                                                    disabled={!permission.some((res) => res === "create")}
                                                />
                                                <label className="form-check-label">
                                                    คืนทุกรายการ
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row addEcn-table">
                                        <div className="table-responsive">
                                            <table className="table-primary">
                                                <thead>
                                                    <tr>
                                                        <th>ลำดับ</th>
                                                        <th>เลือก</th>
                                                        <th>จำนวนที่จะคืน</th>
                                                        <th>รหัสสินค้า</th>
                                                        <th>ชื่อสินค้า</th>
                                                        <th>จำนวนที่สั่ง</th>
                                                        <th>จำนวนที่คืนแล้ว</th>
                                                        <th>ราคา/หน่วย</th>
                                                        <th>ราคารวม</th>
                                                        <th>Share Discount</th>
                                                        <th>Share Stamp</th>
                                                        <th>Share AMB point</th>
                                                        <th>คืนเงิน</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((val, index) => {
                                                        return (
                                                            <>
                                                                <tr
                                                                    key={index}
                                                                    className={
                                                                        index % 2 !== 0
                                                                            ? "light-blue"
                                                                            : "bg-white"
                                                                    }
                                                                >
                                                                    <td>{val.order_detail_no}</td>
                                                                    <td className="addEcn-returnQty-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            name={index}
                                                                            id={index}
                                                                            className="form-check-input"
                                                                            checked={
                                                                                val?.isChecked ||
                                                                                false ||
                                                                                (isFromPreRequest &&
                                                                                    isMaxReturnQty.includes(val.order_detail_no) === false)
                                                                            }
                                                                            onChange={handleChangeSelectedTable}
                                                                            disabled={(!permission.some((res) => res === "create") ||
                                                                                (isFromPreRequest ||
                                                                                    val.return_qty == val.qty) &&
                                                                                isMaxReturnQty.includes(val.order_detail_no) === true
                                                                            )}
                                                                        />
                                                                    </td>
                                                                    <td className="addEcn-returnQty">
                                                                        <div className="input-group mx-auto">
                                                                            <span className="input-group-btn input-group-minus">
                                                                                <button
                                                                                    className="btn btn-default btn-number addEcn-minus"
                                                                                    name="returnQty"
                                                                                    disabled={(!permission.some((res) => res === "create") ||
                                                                                        isFromPreRequest ||
                                                                                        (val.user_return_qty == val.qty &&
                                                                                            isMaxReturnQty.includes(val.order_detail_no) === true))
                                                                                    }
                                                                                    onClick={(e) =>
                                                                                        handleChangeValueData(index, "-")
                                                                                    }
                                                                                >
                                                                                    -
                                                                                </button>
                                                                            </span>
                                                                            <input
                                                                                type="number"
                                                                                name="returnQty"
                                                                                className="form-control input-number"
                                                                                min="0"
                                                                                max={val.qty}
                                                                                value={
                                                                                    isFromPreRequest
                                                                                        ? val.qty
                                                                                        : (
                                                                                            isMaxReturnQty.includes(val.order_detail_no) === true
                                                                                                ? "" : val.user_return_qty
                                                                                        )
                                                                                }
                                                                                disabled={(!permission.some((res) => res === "create") ||
                                                                                    isFromPreRequest ||
                                                                                    (val.return_qty == val.qty &&
                                                                                        isMaxReturnQty.includes(val.order_detail_no) === true))
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleChangeData(e, index)
                                                                                }
                                                                            />
                                                                            <span className="input-group-btn input-group-plus">
                                                                                <button
                                                                                    className="btn btn-default btn-number addEcn-plus"
                                                                                    name="returnQty"
                                                                                    disabled={(!permission.some((res) => res === "create") ||
                                                                                        isFromPreRequest ||
                                                                                        (val.user_return_qty == val.qty &&
                                                                                            isMaxReturnQty.includes(val.order_detail_no) === true))
                                                                                    }
                                                                                    onClick={(e) =>
                                                                                        handleChangeValueData(index, "+")
                                                                                    }
                                                                                >
                                                                                    +
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>{val.item_no}</td>
                                                                    <td className="text-start">{val.item_name}</td>
                                                                    <td>{val.qty}</td>
                                                                    <td>{val.return_qty}</td>
                                                                    <td>{val.price_unit}</td>
                                                                    <td>{val.total_price}</td>
                                                                    <td>
                                                                        {val.share_discount !== null
                                                                            ? val.share_discount
                                                                            : "-"}
                                                                    </td>
                                                                    <td>
                                                                        {val.share_stamp !== null
                                                                            ? val.share_stamp
                                                                            : "-"}
                                                                    </td>
                                                                    <td>
                                                                        {val.share_amb_point !== null
                                                                            ? val.share_amb_point
                                                                            : "-"}
                                                                    </td>
                                                                    <td>
                                                                        {val.refund !== null ? val.refund : "-"}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td className="table-footer" colSpan="13"></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-3">
                                                <button
                                                    className="col-6 col-sm-6 col-md-10 btn calculate-button btn-lg"
                                                    name={isFromPreRequest ? "cancel" : "return"}
                                                    onClick={() => handleCalculateRefund()}
                                                    disabled={!permission.some((res) => res === "create")}
                                                >
                                                    คำนวณการคืนเงิน
                                                </button>
                                            </div>
                                            <label className="col-12 col-sm-12 col-md-8 conclude-body-header">
                                                ยอดรวม (เฉพาะรายการที่ทำคืน)
                                            </label>
                                            <div className="col-12 col-md-6 border-bottom border-white mt-3 mb-3 mx-auto"></div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="offset-md-2 col-6 col-md-4 conclude-text">
                                                <label>ราคารวม</label>
                                            </div>
                                            <div className="col-6 col-md-4 conclude-number">
                                                <div className="row">
                                                    <label className="col-8 col-md-4 text-end">
                                                        {totalSelectedData.total_price}
                                                    </label>
                                                    <span className="col-4 col-md-4 text-start"> บาท</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="offset-md-2 col-6 col-md-4 conclude-text">
                                                <label>Share Discount</label>
                                            </div>
                                            <div className="col-6 col-md-4 conclude-number">
                                                <div className="row">
                                                    <label className="col-8 col-md-4 text-end">
                                                        {totalSelectedData.share_discount}
                                                    </label>
                                                    <span className="col-4 col-md-4 text-start"> บาท</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="offset-md-2 col-6 col-md-4 conclude-text">
                                                <label>Share Stamp</label>
                                            </div>
                                            <div className="col-6 col-md-4 conclude-number">
                                                <div className="row">
                                                    <label className="col-8 col-md-4 text-end">
                                                        {totalSelectedData.share_stamp}
                                                    </label>
                                                    <span className="col-4 col-md-4 text-start"> บาท</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="offset-md-2 col-6 col-md-4 conclude-text">
                                                <label>ยอดรวมคืน AMB Point</label>
                                            </div>
                                            <div className="col-6 col-md-4 conclude-number">
                                                <div className="row">
                                                    <label className="col-8 col-md-4 text-end">
                                                        {totalSelectedData.share_amb_point}
                                                    </label>
                                                    <span className="col-4 col-md-4 text-start"> บาท</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="offset-md-2 col-6 col-md-4 conclude-text">
                                                <label>ยอดรวมคืนเงินโดยระบบ</label>
                                            </div>
                                            <div className="col-6 col-md-4 conclude-number">
                                                <div className="row">
                                                    <label className="col-8 col-md-4 text-end">
                                                        {totalCalculateData.total_refund_by_compute}
                                                    </label>
                                                    <span className="col-4 col-md-4 text-start"> บาท</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 border-bottom border-white mt-3 mb-3 mx-auto"></div>
                                        </div>
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-4 col-md-2 col-xxl-3 offset-md-2 offset-xxl-3 conclude-text">
                                                <label>ยอดรวมคืนเงิน</label>
                                            </div>
                                            <div className="col-8 col-md-6 col-xxl-6 conclude-number">
                                                <div className="row align-items-center">
                                                    <label className="col-8 col-md-6 col-xxl-4">
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            id="returnTotalRefund"
                                                            min="0"
                                                            max={maxRefund}
                                                            value={totalRefund}
                                                            onInput={() => onInputTotalReturnRefund()}
                                                            disabled={!permission.some((res) => res === "create")}
                                                        ></input>
                                                    </label>
                                                    <span className="col-4 col-md-3 col-xxl-2 refund-unit text-center">
                                                        บาท
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center conclude-button">
                                            <button
                                                className={isShowSaveButton ? "col-5 col-md-3 btn cancel-button" : "col-5 col-md-3 btn cancel-button-disable"}
                                                data-bs-toggle="modal"
                                                data-bs-target="#staticBackdrop"
                                                onClick={() => setModalCancel(true)}
                                            >
                                                ยกเลิก
                                            </button>
                                            <button
                                                className={
                                                    isShowSaveButton
                                                        ? "col-5 col-md-3 btn save-button"
                                                        : "col-5 col-md-3 btn save-button-disable"
                                                }
                                                data-bs-toggle="modal"
                                                data-bs-target="#staticBackdrop2"
                                                onClick={() => setModalSave(true)}
                                            >
                                                บันทึก
                                            </button>
                                        </div>
                                        <div className={modalSave ? "" : "disable"}>
                                            <div
                                                className="modal fade"
                                                id="staticBackdrop2"
                                                data-bs-backdrop="static"
                                                data-bs-keyboard="false"
                                                tabIndex="-1"
                                                aria-labelledby="staticBackdropLabel"
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="header">
                                                            <h4 className="title" id="staticBackdropLabel">
                                                                ยืนยันข้อมูล
                                                            </h4>
                                                        </div>
                                                        <div className="body">
                                                            <h5>โปรดตรวจสอบข้อมูล ก่อนกดยืนยัน</h5>
                                                        </div>
                                                        <div className="footer">
                                                            <button
                                                                type="submit"
                                                                className="resetbutton"
                                                                data-bs-dismiss="modal"
                                                                onClick={saveButtonHandle}
                                                            >
                                                                บันทึก
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="backbutton"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                แก้ไข
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={modalCancel ? "" : "disable"}>
                                            <div
                                                className="modal fade"
                                                id="staticBackdrop"
                                                data-bs-backdrop="static"
                                                data-bs-keyboard="false"
                                                tabIndex="-1"
                                                aria-labelledby="staticBackdropLabel"
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="header">
                                                            <h4 className="title" id="staticBackdropLabel">
                                                                ยกเลิกการแก้ไข
                                                            </h4>
                                                        </div>
                                                        <div className="body">
                                                            <h5>
                                                                คุณต้องการยกเลิกการแก้ไขข้อมูลทั้งหมด หรือไม่
                                                            </h5>
                                                        </div>
                                                        <div className="footer">
                                                            <button
                                                                type="reset"
                                                                className="resetbutton"
                                                                data-bs-dismiss="modal"
                                                                onClick={reset}
                                                            >
                                                                ยืนยัน
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="backbutton"
                                                                data-bs-dismiss="modal"
                                                            >
                                                                ย้อนกลับ
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default AddECreditNote;
