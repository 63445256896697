import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Success.scss";

function SuccessModal({
    showSuccessModal,
    isCashMode,
}) {
    const history = useHistory();
    const urlPath = "/grrs/contact-center/สร้างคำขอแทนลูกค้า/order-list";

    const refreshPage = () => {
        window.location.reload(false)
        history.push(urlPath);
        // setDisplayResultModal(false);
    };

    function handleModalDisplay() {
        const modalElement = document.getElementById("succesPopup");
        if (modalElement) {
          const modal = new window.bootstrap.Modal(modalElement);
          modal.show();
        }
      }

    useEffect(() => {
        if (showSuccessModal === true) {
            handleModalDisplay();
        }
    }, [showSuccessModal]);

    return (
        <>
        <button type="button" id="succes-btn" className="btn btn-primary succes-modal-button" data-bs-toggle="modal" data-bs-target="#succesPopup" hidden>
            ตกลง
        </button>
        <div className="succesPopupModal">
            <div className="modal fade" id="succesPopup" tabIndex="-1" aria-labelledby="succesPopupLabel" aria-hidden="true" onClick={() => refreshPage()}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body d-flex flex-column align-items-center">
                            <div className="text-center">
                                <img className="icon-modal-success" src="/assets/icons/modal-icon-success.svg" alt="Success Icon" />
                            </div>
                            <div className="text-center text-confirm">
                            <span>{!isCashMode ? 'ยืนยันการโอนเงินคืนสำเร็จ' : 'ดำเนินการสำเร็จ'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    
    );
}
export default SuccessModal;