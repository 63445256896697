import axios from "axios";
import { checkAccessToken } from "../../helper/grrs/grrsHelper";

const GRRS_URL = process.env.REACT_APP_GRRS_API_URL;
const GRRS_AUTH = () =>
  `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
const X_API_KEY = process.env.REACT_APP_GRRS_X_API_KEY;

const postExportCancellationRequest = async (exportData) => {
  const body = exportData;
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/export`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getOrdersource = async () => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/ordersource`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data.response_data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const postSendSmsOtp = async (enterPhoneNo, ordersource) => {
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/history/sms/otp/send`,
      {
        phone_no: enterPhoneNo,
        ordersource: ordersource
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data.response_data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const postVerifyAndGetOrderHistory = async (req) => {
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/history/verification`,
      {
        otp_tx_id: req.otp_tx_id,
        ref_no: req.ref_no,
        otp_code: req.otp_code,
        phone_no: req.phone_no,
        ordersource: req.ordersource,
        order_status: "IN_PROGRESS",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getOrderDetails = async (orderNo, ordersource) => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/details?order_no=${orderNo}&ordersource=${ordersource}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getOrderCancellationInitial = async (orderNo, ordersource) => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/initial?order_no=${orderNo}&ordersource=${ordersource}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getCancellationRefundMaster = async (orderNo, ordersource) => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/refund/master?mainorder_no=${orderNo}&ordersource=${ordersource}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const postCancellationRequest = async (req) => {
  const data = {
    mainorder_no: req.mainorder_no,
    ordersource: req.ordersource,
    action_type: "cancel",
    i_account: null,
    all_member_id: req.all_member_id,
    operation_id: "CS01",
    execution: "create",
    refund_method: {
      channel_code: req.refund_method.channel_code,
      bank_account_type_code: req.refund_method.bank_account_type_code,
      promtpay_no: req.refund_method.promtpay_no,
      bank_code: req.refund_method.bank_code,
      account_no: req.refund_method.account_no,
      account_name: req.refund_method.account_name,
    },
    reason: {
      reason_code: req.reason.reason_code,
      reason_remark: req.reason.reason_remark,
      is_all_member_point_compensation:
        req.reason.is_all_member_point_compensation,
      cc_remark: req.reason.cc_remark,
    },
  };
  try {
    const response = await axios({
      method: "post",
      url: `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/submit/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: GRRS_AUTH(),
        "x-api-key": X_API_KEY,
      },
      data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const postGetOrderHistoryByMemberId = async (req) => {
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/history/bymember`,
      {
        member_id: req.memberId,
        is_order_history_completed: req.isOrderHistoryCompleted,
        phone_no: req.phoneNo ? req.phoneNo : null,
        ordersource: req.ordersource ? req.ordersource : null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getOrderCancellationRequestTimeline = async (customerRequestNo) => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/no/${customerRequestNo}/timeline`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getOrderCancellationInfoDetails = async (orderNo, ordersource) => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/details?order_no=${orderNo}&ordersource=${ordersource}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const getOrderCancellationReqDetails = async (customerRequestNo) => {
  try {
    const response = await axios.get(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/no/${customerRequestNo}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
    if (response.data.response_code === 100) return response.data;
    else return [];
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

const postCancellationRequestRetry = async (req) => {
  try {
    const response = await axios.post(
      `${GRRS_URL}/v1/grrs/customerservice/order/cancellation/request/retry/submit`,req,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: GRRS_AUTH(),
          "x-api-key": X_API_KEY,
        },
      }
    );
  return response.data;
  } catch (error) {
    console.log(error);
    checkAccessToken(error?.response?.status);
    return [];
  }
};

export {
  postSendSmsOtp,
  getOrdersource,
  postExportCancellationRequest,
  postVerifyAndGetOrderHistory,
  getOrderDetails,
  getOrderCancellationInitial,
  getCancellationRefundMaster,
  postCancellationRequest,
  postGetOrderHistoryByMemberId,
  getOrderCancellationRequestTimeline,
  getOrderCancellationInfoDetails,
  getOrderCancellationReqDetails,
  postCancellationRequestRetry,
};
