import React, { useEffect, useState, useContext } from "react";
import FormSearch from "../../components/Share/FormSearch";
import Table from "../../components/Share/Table";
import OtpModal from "../../components/ContactCenter/OtpModal";
import ResponsiveSideBar from "../../../common/components/ResponsiveSideBar";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./ContactCenter.scss";
import { postCancellationRequestList } from "../../../../service/grrs/master.service";
import { postExportCancellationRequest } from "../../../../service/grrs/contactCenter.service";
import { getCancellationReqRefundFilter } from "../../../../service/grrs/master.service";
import LoadingSpinner from "../../components/Share/LoadingSpinner";
import MenuContext from "../../../../context/MenuContext";
import PermissionModal from "../../../common/components/PermissionModal";

const initialState = {
  reason_code: null,
  refund_channel_code: null,
  request_refund_status_code: null,
  request_type_code: null,
  customer_request_no: null,
  order_date_to: null,
  order_no: null,
  phone_no: null,
  ordersource: null,
  order_date_from: null,
};

function ContactCenter() {
  const FileDownload = require("js-file-download");
  const [rawData, setRawData] = useState([]);
  const [isNoData, setIsNoData] = useState(false);
  const [exportData, setExportData] = useState({ initialState });
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { permission } = useContext(MenuContext);

  useEffect(() => {
    getOrderDetailFromApi();
    setIsLoading(true);
    let firstData = {
      reason_code: null,
      refund_channel_code: null,
      request_refund_status_code: null,
      request_type_code: null,
      customer_request_no: null,
      order_date_to: null,
      order_no: null,
      phone_no: null,
      ordersource: null,
      order_date_from: null,
    };
    localStorage.setItem("grrs-load-more-btn", JSON.stringify(true));
    localStorage.setItem("grrs-isOrderCancelCompleted", "0");
    postCancellationRequestList(firstData).then((res) => {
      if (res?.data === undefined) {
        setRawData([]);
        setIsNoData(true);
        setIsLoading(false);
      } else {
        setRawData(res.data);
        setIsLoading(false);
      }
    });
  }, []);

  const getOrderDetailFromApi = () => {
    getCancellationReqRefundFilter()
      .then((res) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching order details:', error); 
      });
  };

  const exportDataToExcel = () => {
    setIsButtonLoading(true);
    postExportCancellationRequest(exportData).then((res) => {
      const contentDisposition = res?.headers["content-disposition"];
      if (res !== null && contentDisposition !== undefined) {
        const filename = contentDisposition.split("filename=")[1].trim();
        FileDownload(res.data, filename);
      }
      setIsButtonLoading(false);
    });
  };

  let history = useHistory();
  const goRequestCancel = (row) => {
    history.push(
      `/grrs/contact-center/สร้างคำขอแทนลูกค้า/cancellation-view/${row.original.order_no}&${row.original.ordersource}&${row.original.customer_request_no}`
    );
  };

  return (
    <>
      <Container>
        <div className="layout">
          <div className="row vh-100">
            <Row>
              <ResponsiveSideBar />
              <Col md={9} className="content">
                <div className="grrs-container space-bottom">
                  <LoadingSpinner isLoading={isLoading} />
                  <div className="grrs-row-contact-center">
                    <h1 className="index-h1">Contact Center Data List</h1>
                  </div>
                  <div className="grrs-row mt-5">
                    <div className="col-md-12">
                      {permission.some((res) => res === "create") && <OtpModal />}
                      <button
                        className="export-to-excel grrs-white-button"
                        onClick={() => exportDataToExcel()}
                        hidden={!permission.some((res) => res === "search")}
                      >
                        <img
                          className="icon-excel"
                          src="/assets/icons/excel-app.svg"
                          alt="excel"
                        ></img>
                        <span className="font-size">Export To Excel</span>
                        <span className="spinner-border spinner-border-xl button-spinner" role="status" aria-hidden="true" hidden={!isButtonLoading}></span>
                      </button>
                      {permission.some((res) => res === "search") &&
                        <FormSearch
                          setSearchData={setRawData}
                          setExportData={setExportData}
                          setIsLoading={setIsLoading}
                        />
                      }
                    </div>
                  </div>
                  <div className="grrs-row mt-5">
                    {
                      !isLoading && permission?.length !== 0 && (
                        <>
                          <Table
                            data={permission.some((res) => res === "search") ? rawData : []}
                            isNoData={isNoData}
                            setIsNoData={setIsNoData}
                            redirectFunction={goRequestCancel}
                            isCanSearch={permission.some((res) => res === "search")}
                          />
                        </>
                      )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <PermissionModal permission={permission} backPage={"/grrs"} />
      </Container>
    </>
  );
}

export default ContactCenter;
