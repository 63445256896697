import React, { useState } from "react";
import "./FormRequestCancel.scss";
import "../../Share/FormRequestCancel/FormRequestCancel.scss";
function CheckRefund({
  isCanEdit = false,
  checkRefund,
  setCheckRefund,
  isHasApproveOption = true,
  isHasWaitingOption = true,
}) {
  const [isDisplay, setIsDisplay] = useState(true);
  const { is_approve, remark } = checkRefund;

  return (
    <div className="form-request-cancel">
      <div
        className={`header-request-cancel ${isCanEdit
          ? `header-request-cancel-edit ${isDisplay}`
          : "header-request-cancel-view"
          }`}
      >
        <span className="hide-icon">
          <img
            className=""
            src={
              isDisplay
                ? "/assets/grrs/hide-icon.svg"
                : "/assets/grrs/unhide-icon.svg"
            }
            alt="hide"
            onClick={() => setIsDisplay(!isDisplay)}
          />
        </span>
        <span className="fw-bold">ตรวจสอบการโอนเงินคืน</span>
      </div>
      {isDisplay && (
        <div
          className={`body-request-cancel ${isCanEdit ? "body-request-cancel-edit" : ""
            }`}
        >
          <div className="row">
            <div className="col-md-12">
              <div className={`header-checkbox `}>
                <span className={`fw-bold `}>ตรวจสอบการโอนเงินคืน</span>
              </div>
              <div
                className={`body-checkbox ${isCanEdit ? "body-checkbox-edit" : ""
                  }`}
              >
                {
                  isHasApproveOption === true && (
                    <div>
                      <input
                        className="form-check-input form-check-input-radio"
                        type="radio"
                        disabled={!isCanEdit}
                        checked={is_approve === 1}
                        onChange={(e) =>
                          setCheckRefund({ ...checkRefund, is_approve: 1 })
                        }
                      />
                      <label
                        className={`form-check-label mb-2 ${isCanEdit ? "form-check-label-edit" : ""
                          }`}
                      >
                        ยืนยันการโอนเงินคืน
                      </label>
                    </div>
                  )
                }
                {
                  isHasWaitingOption === true && (
                    <div>
                      <input
                        className="form-check-input form-check-input-radio"
                        type="radio"
                        disabled={!isCanEdit}
                        checked={is_approve === 0}
                        onChange={(e) =>
                          setCheckRefund({ ...checkRefund, is_approve: 0 })
                        }
                      />
                      <label
                        className={`form-check-label mb-2 ${isCanEdit ? "form-check-label-edit" : ""
                          }`}
                      >
                        รอการแก้ไขข้อมูลจากลูกค้า
                      </label>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label
                className={`form-label ${isCanEdit ? "form-label-edit" : ""}`}
              >
                รายละเอียดเพิ่มเติม
              </label>
              <textarea
                className="form-control"
                rows={2}
                disabled={!isCanEdit}
                onChange={(e) =>
                  setCheckRefund({
                    ...checkRefund,
                    remark: e.target.value,
                  })
                }
                value={remark}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckRefund;
