import { stubTrue } from "lodash";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../../scss/etax/_taxInvoiceForm.scss";
import { listProvince, listAmphure, listTumbon } from "../../../../service/etax/api-province.service";
import { postEtaxData, putEtaxData } from "../../../../service/etax/etax.service";
import useInput from "./useInput";

const TaxInvoiceForm = ({ data }) => {

    const [checkPerson, setCheckPerson] = useState("บุคคลธรรมดา");
    const [branchStatus, setBranchStatus] = useState("สำนักงานใหญ่");
    const [branchName, setBranchName] = useState("");
    const [moobanTowerFloor, setMoobanTowerFloor] = useState("");
    const [moo, setMoo] = useState("");
    const [soi, setSoi] = useState("");
    const [road, setRoad] = useState("");
    const [province, setProvince] = useState([]);
    const [amphure, setAmphure] = useState([]);
    const [tumbon, setTumbon] = useState([]);
    const [zipcode, setZipcode] = useState([]);

    const [provinceLabel, setProvinceLabel] = useState("")
    const [districtLabel, setDistrictLabel] = useState("")
    const [subdistrictLabel, setSubdistrictLabel] = useState("")

    const [successAlert, setSucessAlert] = useState(false);

    //--------------------Regular Expressions--------------------------------------//
    let person = /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/;
    let tax = /^0(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/g;
    let tel = /^0(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/g;

    function validNationalID(id) {
        if (id.length == 13) {
            let num;
            for (let i of id) {
                if (num == null) {
                    num = i
                } else if (num != i) {
                    return true
                }
            }
            return false
        }
    }

    //---------------------------บุคคลธรรมดา---------------------------------------//

    // personalId
    const {
        enterValue: enterPersonalId,
        setEnterValue: setEnterPersonalId,
        enterValueIsValid: enterPersonalIdIsValid,
        inputValueError: inputPersonalIdError,
        onValueChangeHandle: onPersonalIdChangeHandle,
        onValueBlurHandle: onPersonalIdBlurHandle,
        resetValue: resetPersonalId
    } = useInput(value => value.trim() !== "" && value.length === 13 && person.test(value) && validNationalID(value))

    //telPhoneNo
    const {
        enterValue: enterTelPhoneNo,
        setEnterValue: setEnterTelPhoneNo,
        enterValueIsValid: enterTelPhoneNoIsValid,
        inputValueError: inputTelPhoneNoError,
        onValueChangeHandle: onTelPhoneNoChangeHandle,
        onValueBlurHandle: onTelPhoneNoBlurHandle,
        resetValue: resetTelPhoneNo
    } = useInput(value => value.trim() !== "" && tel.test(value) && (value.length == 9 || value.length === 10))

    //firstname
    const {
        enterValue: enterFirstName,
        setEnterValue: setEnterFirstName,
        enterValueIsValid: enterFisrtNameIsValid,
        inputValueError: inputFirstNameError,
        onValueChangeHandle: onFirstNameChangeHandle,
        onValueBlurHandle: onFirstNameBlurHandle,
        resetValue: resetFirstName
    } = useInput(value => value.trim() !== "")

    //lastname
    const {
        enterValue: enterLastName,
        setEnterValue: setEnterLastName,
        enterValueIsValid: enterLastNameIsValid,
        inputValueError: inputLastNameError,
        onValueChangeHandle: onLastNameChangeHandle,
        onValueBlurHandle: onLastNameBlurHandle,
        resetValue: resetLastName
    } = useInput(value => value.trim() !== "")

    //addressNo
    const {
        enterValue: enterAddressNo,
        setEnterValue: setAddressNo,
        enterValueIsValid: enterAddressNoIsValid,
        inputValueError: inputAddressNoError,
        onValueChangeHandle: onAddressNoChangeHandle,
        onValueBlurHandle: onAddressNoBlurHandle,
        resetValue: resetAddressNo
    } = useInput(value => value.trim() !== "")

    //----------------------------------นิติบุคคล-----------------------------------------//

    //taxIdNo
    const {
        enterValue: enterTaxIdNo,
        setEnterValue: setEnterTaxIdNo,
        enterValueIsValid: enterTaxIdNoIsValid,
        inputValueError: inputTaxIdNoError,
        onValueChangeHandle: onTaxIdNoChangeHandle,
        onValueBlurHandle: onTaxIdNoBlurHandle,
        resetValue: resetTaxIdNo
    } = useInput(value => value.trim() !== "" && value.length === 13 && tax.test(value) && validNationalID(value))

    //phoneNo
    const {
        enterValue: enterPhoneNo,
        setEnterValue: setEnterPhoneNo,
        enterValueIsValid: enterPhoneNoIsValid,
        inputValueError: inputPhoneNoError,
        onValueChangeHandle: onPhoneNoChangeHandle,
        onValueBlurHandle: onPhoneNoBlurHandle,
        resetValue: resetPhoneNo
    } = useInput(value => value.trim() !== "" && tel.test(value) && (value.length == 9 || value.length == 10))

    //companyName
    const {
        enterValue: enterCompanyName,
        setEnterValue: setEnterCompanyName,
        enterValueIsValid: enterCompanyNameIsValid,
        inputValueError: inputCompanyNameError,
        onValueChangeHandle: onCompanyNameChangeHandle,
        onValueBlurHandle: onCompanyNameBlurHandle,
        resetValue: resetCompanyName
    } = useInput(value => value.trim() !== "")

    //---------------------------------------------------------------------------------//

    //province
    const {
        enterValue: enterProvince,
        setEnterValue: setEnterProvince,
        enterValueIsValid: enterProvinceIsValid,
        setEnterValueBlurIsValid: setEnterProvinceBlurIsValid,
        inputValueError: inputProvinceError,
        onValueBlurHandle: onProvinceBlurHandle,
        resetValue: resetProvince
    } = useInput(value => value.trim() !== "")

    //district
    const {
        enterValue: enterDistrict,
        setEnterValue: setEnterDistrict,
        enterValueIsValid: enterDistrictIsValid,
        setEnterValueBlurIsValid: setEnterDistrictBlurIsValid,
        inputValueError: inputDistrictError,
        onValueBlurHandle: onDistrictBlurHandle,
        resetValue: resetDistrict
    } = useInput(value => value.trim() !== "")

    //subdistirct
    const {
        enterValue: enterSubdistrict,
        setEnterValue: setEnterSubdistrict,
        enterValueIsValid: enterSubdistrictIsValid,
        setEnterValueBlurIsValid: setEnterSubdistrictBlurIsValid,
        inputValueError: inputSubdistrictError,
        onValueBlurHandle: onSubdistrictBlurHandle,
        resetValue: resetSubdistrict
    } = useInput(value => value.trim() !== "")

    //postalcode
    const {
        enterValue: enterPostalcode,
        setEnterValue: setEnterPostalcode,
        enterValueIsValid: enterPostalcodeIsValid,
        setEnterValueBlurIsValid: setEnterPostalcodeBlurIsValid,
        inputValueError: inputPostalcodeError,
        onValueBlurHandle: onPostalcodeBlurHandle,
        resetValue: resetPostalcode
    } = useInput(value => value.trim() !== "")


    const statushq = () => {
        setBranchStatus("สำนักงานใหญ่");
        setBranchName("")
    };
    const statusbranch = () => {
        setBranchStatus("สาขาย่อย");
    };

    useEffect(async () => {
        loadData();

        if (data.action === "FULL_REQUESTABLE") {
            setCheckPerson("บุคคลธรรมดา");
        }
        if (data.action === "CORRECTION") {
            if (data.address.first_name && data.address.last_name) {
                setCheckPerson("บุคคลธรรมดา")
                setEnterPersonalId(data?.national_id_card);
                setEnterTelPhoneNo(data?.mobile_number);
                setEnterFirstName(data?.address.first_name);
                setEnterLastName(data?.address.last_name);
            }
            if (data.address.company_name) {
                setCheckPerson("นิติบุคคล")
                setEnterTaxIdNo(data?.national_id_card);
                setEnterPhoneNo(data?.mobile_number);
                if (data?.address.branch_number == null) {
                    setBranchStatus("สำนักงานใหญ่");
                    setEnterCompanyName(data?.address.company_name);
                } else {
                    setBranchStatus("สาขาย่อย");
                    setEnterCompanyName(data?.address.company_name);
                    setBranchName(data?.address.branch_number == null ? "" : data.address.branch_number)
                }
            }
            setAddressNo(data?.address.house)
            setMoobanTowerFloor(data?.address.place == null ? "" : data.address.place);
            setMoo(data?.address.moo == null ? "" : data.address.moo);
            setSoi(data.address.soi == null ? "" : data.address.soi);
            setRoad(data.address.street == null ? "" : data.address.street);

            const filterProvinceName = province.filter(province => {
                return data.address.province == province.name
            })
            setEnterProvince(filterProvinceName[0].id);

            let amphureData = await listAmphure(filterProvinceName[0].id)
            setAmphure(amphureData)
            const filterDistrictName = amphureData.filter(district => {
                return data.address.district == district.name
            })
            setEnterDistrict(filterDistrictName[0].id);

            let tumbonData = await listTumbon(filterDistrictName[0].id)
            setTumbon(tumbonData)
            const filterSubdistrictName = tumbonData.filter(subdistrict => {
                return data.address.sub_district == subdistrict.name
            })
            setEnterSubdistrict(filterSubdistrictName[0].id);
            setZipcode(filterSubdistrictName[0].postalCodes);
            setEnterPostalcode(data?.address.postal_code);

        }
    }, [data]);

    const loadData = () => {
        listProvince()
            .then((res) => {
                setProvince(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onChangeProvince = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const label = e.nativeEvent.target[index].text;
        setProvinceLabel(label);

        setEnterProvince(e.target.value)
        setEnterProvinceBlurIsValid(true)

        if (e.target.value == "") {
            setTumbon([])
        }
        listAmphure(e.target.value)
            .then((res) => {
                setAmphure(res);
            })
            .catch((err) => {
                console.log(err);
            });
        resetDistrict();
        resetSubdistrict();
        resetPostalcode();
    };

    const onChangeAmphure = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const label = e.nativeEvent.target[index].text;
        setDistrictLabel(label);

        setEnterDistrict(e.target.value)
        setEnterDistrictBlurIsValid(true)

        listTumbon(e.target.value)
            .then((res) => {
                setTumbon(res)
            })
            .catch((err) => {
                console.log(err);
            });
        resetSubdistrict();
        resetPostalcode();
    };

    const onChangeTumbom = (e) => {
        const index = e.nativeEvent.target.selectedIndex;
        const label = e.nativeEvent.target[index].text;
        setSubdistrictLabel(label);

        setEnterSubdistrict(e.target.value)
        setEnterSubdistrictBlurIsValid(true)

        const filterTumbon = tumbon.filter(tumbon => {
            return e.target.value == tumbon.id
        })
        if (filterTumbon[0].postalCodes.length >= 2) {
            setZipcode(filterTumbon[0].postalCodes);
            setEnterPostalcode(filterTumbon[0].postalCodes[0]);
            setEnterPostalcodeBlurIsValid(true)
        } else {
            setZipcode(filterTumbon[0].postalCodes);
            setEnterPostalcode(filterTumbon[0].postalCodes[0]);
            setEnterPostalcodeBlurIsValid(true)
        }
    }

    const formIsValid = (enterPersonalIdIsValid && enterTelPhoneNoIsValid && enterFisrtNameIsValid && enterLastNameIsValid && enterAddressNoIsValid && enterProvinceIsValid && enterDistrictIsValid && enterSubdistrictIsValid && enterPostalcodeIsValid)
        || (enterTaxIdNoIsValid && enterPhoneNoIsValid && enterCompanyNameIsValid && enterAddressNoIsValid && enterProvinceIsValid && enterDistrictIsValid && enterSubdistrictIsValid && enterPostalcodeIsValid)

    function onSubmitHandle(e) {
        e.preventDefault()
        if (!enterPersonalIdIsValid && enterTelPhoneNoIsValid && enterFisrtNameIsValid && enterLastNameIsValid && enterAddressNoIsValid && enterProvinceIsValid && enterDistrictIsValid && enterSubdistrictIsValid && enterPostalcodeIsValid) {
            return;
        }
        if (!enterTaxIdNoIsValid && enterPhoneNoIsValid && enterCompanyNameIsValid && enterAddressNoIsValid && enterProvinceIsValid && enterDistrictIsValid && enterSubdistrictIsValid && enterPostalcodeIsValid) {
            return;
        }
        resetPersonalId()
        resetTelPhoneNo()
        resetFirstName()
        resetLastName()
        resetAddressNo()
        resetTaxIdNo()
        resetPhoneNo()
        resetCompanyName()
        setBranchName("")
        setMoobanTowerFloor("")
        setMoo("")
        setSoi("")
        setRoad("")
        resetProvince()
        resetDistrict()
        resetSubdistrict()
        resetPostalcode()
    }

    const errorPersonalIdClassName = inputPersonalIdError ? "form-control is-invalid" : "form-control"
    const errorTelPhoneNoClassName = inputTelPhoneNoError ? "form-control is-invalid" : "form-control"
    const errorFirstNameClassName = inputFirstNameError ? "form-control is-invalid" : "form-control"
    const errorLastNameClassName = inputLastNameError ? "form-control is-invalid" : "form-control"
    const errorAddressNoClassName = inputAddressNoError ? "form-control is-invalid" : "form-control"
    const errorTaxIdNoClassName = inputTaxIdNoError ? "form-control is-invalid" : "form-control"
    const errorPhoneNoClassName = inputPhoneNoError ? "form-control is-invalid" : "form-control"
    const errorCompanyNameClassName = inputCompanyNameError ? "form-control is-invalid" : "form-control"
    const errorProvinceClassName = inputProvinceError ? "form-select etax-select is-invalid" : "form-select etax-select"
    const errorDistrictClassName = inputDistrictError ? "form-select etax-select is-invalid" : "form-select etax-select"
    const errorSubdistrictClassName = inputSubdistrictError ? "form-select etax-select is-invalid" : "form-select etax-select"
    const errorPostalcodeClassName = inputPostalcodeError ? "form-select etax-select is-invalid" : "form-select etax-select"

    const personalToggle = (e) => {
        setCheckPerson(e.target.value)
        resetTaxIdNo()
        resetPhoneNo()
        resetCompanyName()
        setBranchName("")
    }

    const corporateToggle = (e) => {
        setCheckPerson(e.target.value)
        resetPersonalId()
        resetTelPhoneNo()
        resetFirstName()
        resetLastName()
    }


    const savePost = (data) => {
        let customerTypeNo;
        let nationalIdCard;
        let mobileNumber;
        if (checkPerson === "บุคคลธรรมดา") {
            customerTypeNo = 3
            nationalIdCard = enterPersonalId
            mobileNumber = enterTelPhoneNo
        } else if (checkPerson === "นิติบุคคล" && branchStatus === "สำนักงานใหญ่") {
            customerTypeNo = 1
            nationalIdCard = enterTaxIdNo
            mobileNumber = enterPhoneNo
        } else if (checkPerson === "นิติบุคคล" && branchStatus === "สาขาย่อย") {
            customerTypeNo = 2
            nationalIdCard = enterTaxIdNo
            mobileNumber = enterPhoneNo
        }
        let request = {
            "ordersource": data.ordersource,
            "external_orderno": data.external_orderno,
            "national_id_card": nationalIdCard,
            "mobile_number": mobileNumber,
            "customer_type_no": customerTypeNo,
            "address": {
                "first_name": enterFirstName,
                "last_name": enterLastName,
                "company_name": enterCompanyName,
                "branch_number": branchName,
                "house": enterAddressNo,
                "place": moobanTowerFloor,
                "moo": moo,
                "soi": soi,
                "street": road,
                "province": provinceLabel,
                "district": districtLabel,
                "sub_district": subdistrictLabel,
                "postal_code": enterPostalcode
            }
        }
        postEtaxData(request)

        let modal = document.getElementById("modal_alert");
        setSucessAlert(true);
        modal.click();
    }

    const savePut = (data) => {
        let customerTypeNo;
        let nationalIdCard;
        let mobileNumber;
        const filterProvince = province.filter(province => {
            return enterProvince == province.id
        })
        const filterDistrict = amphure.filter(district => {
            return enterDistrict == district.id
        })
        const filterSubdistrict = tumbon.filter(subDistrict => {
            return enterSubdistrict == subDistrict.id
        })
        if (checkPerson === "บุคคลธรรมดา") {
            customerTypeNo = 3
            nationalIdCard = enterPersonalId
            mobileNumber = enterTelPhoneNo
        } else if (checkPerson === "นิติบุคคล" && branchStatus === "สำนักงานใหญ่") {
            customerTypeNo = 1
            nationalIdCard = enterTaxIdNo
            mobileNumber = enterPhoneNo
        } else if (checkPerson === "นิติบุคคล" && branchStatus === "สาขาย่อย") {
            customerTypeNo = 2
            nationalIdCard = enterTaxIdNo
            mobileNumber = enterPhoneNo
        }
        let request = {
            "ordersource": data.ordersource,
            "external_orderno": data.external_orderno,
            "national_id_card": nationalIdCard,
            "mobile_number": mobileNumber,
            "customer_type_no": customerTypeNo,
            "address": {
                "first_name": enterFirstName,
                "last_name": enterLastName,
                "company_name": enterCompanyName,
                "branch_number": branchName,
                "house": enterAddressNo,
                "place": moobanTowerFloor,
                "moo": moo,
                "soi": soi,
                "street": road,
                "province": filterProvince[0].name,
                "district": filterDistrict[0].name,
                "sub_district": filterSubdistrict[0].name,
                "postal_code": enterPostalcode
            }
        }
        putEtaxData(request)

        let modal = document.getElementById("modal_alert");
        setSucessAlert(true);
        modal.click();

    }

    return (
        <>
            <Container>
                <div className="etax-form">
                    {(data.action === "FULL_REQUESTABLE") ? <h1>สร้างใบกำกับภาษีเต็มรูป</h1> : (data.action === "CORRECTION") ? <h1>แก้ไขใบกำกับภาษีเต็มรูป</h1> : ""}
                    <br />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="background-form">
                                <div className="col-md-11">
                                    <form onSubmit={(e) => onSubmitHandle(e)}>
                                        <br />
                                        <div className="form-check form-check-inline etax-margin-right">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="บุคคลธรรมดา"
                                                checked={checkPerson === "บุคคลธรรมดา"}
                                                onChange={personalToggle}
                                            />
                                            <label className="form-check-label etax-label" for="personal">
                                                บุคคลธรรมดา
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value="นิติบุคคล"
                                                checked={checkPerson === "นิติบุคคล"}
                                                onChange={corporateToggle}
                                            />
                                            <label className="form-check-label etax-label" for="corporate">
                                                นิติบุคคล
                                            </label>
                                        </div>
                                        {checkPerson === "บุคคลธรรมดา" ? (
                                            <div className="form">
                                                <div className="row etax-mobile">
                                                    <div className="col-md-5">
                                                        <label>รหัสบัตรประชาชน*</label>
                                                        <input
                                                            type="text"
                                                            className={errorPersonalIdClassName}
                                                            id="validationCustom01"
                                                            value={enterPersonalId}

                                                            onChange={(e) => onPersonalIdChangeHandle(e)}
                                                            onBlur={onPersonalIdBlurHandle}
                                                            maxLength={13}
                                                        />
                                                        {inputPersonalIdError && <div className="invalid-feedback error-etax">
                                                            กรุณากรอกเลขให้ครบ 13 หลัก
                                                        </div>}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label>โทรศัพท์มือถือ*</label>
                                                        <input
                                                            type="text"
                                                            className={errorTelPhoneNoClassName}
                                                            id="validationCustom2"
                                                            value={enterTelPhoneNo}
                                                            onChange={(e) => onTelPhoneNoChangeHandle(e)}
                                                            onBlur={onTelPhoneNoBlurHandle}
                                                            maxLength={10}
                                                        />
                                                        {inputTelPhoneNoError && <div className="invalid-feedback error-etax">
                                                            กรุณากรอกเบอร์โทรศัพท์มือถือ
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className="row etax-mobile">
                                                    <div className="col-md-5">
                                                        <label>ชื่อ*</label>
                                                        <input
                                                            type="text"
                                                            className={errorFirstNameClassName}
                                                            id="validationCustom03"
                                                            value={enterFirstName}
                                                            onChange={(e) => onFirstNameChangeHandle(e)}
                                                            onBlur={onFirstNameBlurHandle}
                                                            maxLength={50}
                                                        />
                                                        <div className="invalid-feedback error-etax">กรุณากรอกชื่อ</div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label>นามสกุล*</label>
                                                        <input
                                                            type="text"
                                                            className={errorLastNameClassName}
                                                            id="validationCustom04"
                                                            value={enterLastName}
                                                            onChange={(e) => onLastNameChangeHandle(e)}
                                                            onBlur={onLastNameBlurHandle}
                                                            maxLength={50}
                                                        />
                                                        <div className="invalid-feedback error-etax">กรุณากรอกนามสกุล</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="form">
                                                <div className="row etax-mobile">
                                                    <div className="col-md-5">
                                                        <label>
                                                            เลขประจำตัวผู้เสียภาษีอากร  (ขึ้นต้นด้วย 0 เท่านั้น)*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={errorTaxIdNoClassName}
                                                            id="validationCustom01"
                                                            aria-describedby="inputGroupPrepend"
                                                            value={enterTaxIdNo}
                                                            onChange={(e) => onTaxIdNoChangeHandle(e)}
                                                            onBlur={onTaxIdNoBlurHandle}
                                                            maxLength={13}
                                                        />
                                                        {inputTaxIdNoError && <div className="invalid-feedback error-etax">
                                                            กรุณากรอกเลขให้ครบ 13 หลัก
                                                        </div>}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label>โทรศัพท์*</label>
                                                        <input
                                                            type="text"
                                                            className={errorPhoneNoClassName}
                                                            id="validationCustom02"
                                                            value={enterPhoneNo}
                                                            onChange={(e) => onPhoneNoChangeHandle(e)}
                                                            onBlur={onPhoneNoBlurHandle}
                                                            maxLength={10}
                                                        />
                                                        {inputPhoneNoError && <div className="invalid-feedback error-etax">กรุณากรอกเบอร์โทรศัพท์</div>}
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="form-check form-check-inline etax-margin-right">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="statusBranch"
                                                        value={branchStatus}
                                                        onClick={statushq}
                                                        checked={branchStatus === "สำนักงานใหญ่"}
                                                    />
                                                    <label
                                                        className="form-check-label etax-label"
                                                    >
                                                        สำนักงานใหญ่
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="statusBranch"
                                                        value={branchStatus}
                                                        onClick={statusbranch}
                                                        checked={branchStatus === "สาขาย่อย"}
                                                    />
                                                    <label className="form-check-label etax-label">
                                                        สาขาย่อย
                                                    </label>
                                                </div>
                                                <br />
                                                <div className="row etax-mobile">
                                                    <div className="col-md-5">
                                                        <label>ชื่อบริษัท*</label>
                                                        <input
                                                            type="text"
                                                            className={errorCompanyNameClassName}
                                                            id="validationCustom03"
                                                            value={enterCompanyName}
                                                            onChange={(e) => onCompanyNameChangeHandle(e)}
                                                            onBlur={onCompanyNameBlurHandle}
                                                            maxLength={70}
                                                        />
                                                        {inputCompanyNameError && <div className="invalid-feedback error-etax">กรุณากรอกชื่อบริษัท</div>}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label>รหัสสาขา (5หลักเท่านั้น)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom04"
                                                            value={branchName}
                                                            onChange={(e) => setBranchName(e.target.value)}
                                                            disabled={branchStatus === "สำนักงานใหญ่"}
                                                            maxLength={5}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <br />
                                        <hr />
                                        <br />
                                        <div className="etax-address-form">
                                            <h4>ที่อยู่สำหรับการออกใบกำกับภาษี</h4>
                                        </div>
                                        <div className="form">
                                            <div className="row etax-mobile">
                                                <div className="col-md-5">
                                                    <label>บ้าน/อาคาร เลขที่*</label>
                                                    <input
                                                        type="text"
                                                        className={errorAddressNoClassName}
                                                        id="validationCustom05"
                                                        value={enterAddressNo}
                                                        onChange={(e) => onAddressNoChangeHandle(e)}
                                                        onBlur={onAddressNoBlurHandle}
                                                        maxLength={30}
                                                    />
                                                    {inputAddressNoError && <div className="invalid-feedback error-etax">กรุณากรอกบ้านเลขที่</div>}
                                                </div>
                                                <div className="col-md-5">
                                                    <label>หมู่บ้าน/อาคาร/ชั้น</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputBuildingName"
                                                        value={moobanTowerFloor}
                                                        onChange={(e) => setMoobanTowerFloor(e.target.value)}
                                                        maxLength={70}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <div className="row etax-mobile">
                                                <div className="col-md-5">
                                                    <label>หมู่</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputMoo"
                                                        value={moo}
                                                        onChange={(e) => setMoo(e.target.value)}
                                                        maxLength={12}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <label>ซอย</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputSoi"
                                                        value={soi}
                                                        onChange={(e) => setSoi(e.target.value)}
                                                        maxLength={45}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <div className="row etax-mobile">
                                                <div className="col-md-5">
                                                    <label>ถนน</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputRoad"
                                                        value={road}
                                                        onChange={(e) => setRoad(e.target.value)}
                                                        maxLength={45}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <label>จังหวัด*</label>
                                                    <select
                                                        className={errorProvinceClassName}
                                                        name="province"
                                                        onChange={(e) => onChangeProvince(e)}
                                                        onBlur={onProvinceBlurHandle}
                                                        value={enterProvince}
                                                    >
                                                        <option value=""></option>
                                                        {province?.map((province, index) => {
                                                            return (
                                                                <>
                                                                    <option key={index} value={province.id}>
                                                                        {province.name}
                                                                    </option>
                                                                </>
                                                            );
                                                        })}
                                                    </select>
                                                    {inputProvinceError && <div className="invalid-feedback error-etax">
                                                        กรุณาเลือกจังหวัด
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <div className="row etax-mobile">
                                                <div className="col-md-5">
                                                    <label>เขต/อำเภอ*</label>
                                                    <select
                                                        className={errorDistrictClassName}
                                                        name="district"
                                                        onChange={(e) => onChangeAmphure(e)}
                                                        onBlur={onDistrictBlurHandle}
                                                        value={enterDistrict}
                                                    >
                                                        <option value=""></option>
                                                        {amphure?.map((amphure, index) => {
                                                            return (
                                                                <>
                                                                    <option key={index} value={amphure.id}>
                                                                        {amphure.name}
                                                                    </option>
                                                                </>
                                                            );
                                                        })}
                                                    </select>
                                                    {inputDistrictError && <div className="invalid-feedback error-etax">
                                                        กรุณาเลือกเขต/อำเภอ
                                                    </div>}
                                                </div>
                                                <div className="col-md-5">
                                                    <label>แขวง/ตำบล*</label>
                                                    <select
                                                        className={errorSubdistrictClassName}
                                                        name="subdistrict"
                                                        onChange={(e) => onChangeTumbom(e)}
                                                        onBlur={onSubdistrictBlurHandle}
                                                        value={enterSubdistrict}
                                                    >
                                                        <option value=""></option>
                                                        {tumbon?.map((tumbon, index) => {
                                                            return (
                                                                <>
                                                                    <option key={index} value={tumbon.id}>
                                                                        {tumbon.name}
                                                                    </option>
                                                                </>
                                                            );
                                                        })}
                                                    </select>
                                                    {inputSubdistrictError && <div className="invalid-feedback error-etax">
                                                        กรุณาเลือกแขวง/ตำบล
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <div className="row etax-mobile">
                                                <div className="col-md-5">
                                                    <label>รหัสไปรษณีย์*</label>
                                                    {zipcode.length >= 2 ?
                                                        <select
                                                            className={errorPostalcodeClassName}
                                                            name="postalcode"
                                                            onChange={(e) => setEnterPostalcode(e.target.value)}
                                                            onBlur={onPostalcodeBlurHandle}
                                                            value={enterPostalcode}
                                                        >
                                                            <option value=""></option>
                                                            {zipcode?.map((zipcode, index) => {
                                                                return (
                                                                    <>
                                                                        <option key={index} value={zipcode}>
                                                                            {zipcode}
                                                                        </option>
                                                                    </>
                                                                );
                                                            })}
                                                        </select>

                                                        :
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="postalcode"
                                                            value={enterPostalcode}
                                                            disabled
                                                        />
                                                    }
                                                    {inputPostalcodeError && <div className="invalid-feedback error-etax">
                                                        กรุณาเลือกรหัสไปรษณีย์
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="etax-btn">
                                            <Link to="/etax">
                                                <button type="button" className="btn btn-danger etax-button">
                                                    ยกเลิก
                                                </button>
                                            </Link>
                                            {data.action === "FULL_REQUESTABLE" ?
                                                <button type="submit" className="btn btn-primary etax-button" disabled={!formIsValid} onClick={() => savePost(data)}>
                                                    บันทึก
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-primary etax-button" disabled={!formIsValid} onClick={() => savePut(data)}>
                                                    บันทึก
                                                </button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <button
                id="modal_button"
                className="disable"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
            ></button>
            <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="header">
                            <h4 className="title" id="staticBackdropLabel">
                                ไม่สามารถสร้าง/แก้ไขใบกำกับภาษีเต็มรูปได้
                            </h4>
                        </div>
                        <div className="footer">
                            <Link to="/etax">
                                <button
                                    type="button"
                                    className="backbutton"
                                    data-bs-dismiss="modal"
                                >
                                    ย้อนกลับ
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <button
                id="modal_alert"
                className="disable"
                data-bs-toggle="modal"
                data-bs-target="#sucessAlert"
            ></button>
            <div
                className="modal fade"
                id="sucessAlert"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="header">
                            <h4 className="title" id="staticBackdropLabel">
                                {data.action === "FULL_REQUESTABLE" ? "สร้างใบกำกับภาษีเรียบร้อยแล้ว" : "แก้ไขใบกำกับภาษีเรียบร้อยแล้ว"}
                            </h4>
                        </div>
                        <div className="footer">
                            <Link to="/etax">
                                <button
                                    type="button"
                                    className="backbutton"
                                    data-bs-dismiss="modal"
                                >
                                    ตกลง
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TaxInvoiceForm;
